import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"


// Belwails

import Users from "pages/Belwails/Users"
import Customers from "pages/Belwails/Customers"
import Viewuser from "pages/Belwails/Viewuser"
import Repoteduser from "pages/Belwails/Repoteduser"
import Userverification from "pages/Belwails/Userverification"
import Userprofilecat from "pages/Belwails/Userprofilecat"
// import Banners from "pages/Belwails/Banners"
import Termsandconditions from "pages/Belwails/TermsandConditions"
import Privacy from "pages/Belwails/PrivacyPolicy"
import Refering from "pages/Belwails/ReferingPolicy"
import Withdraw from "pages/Belwails/Withdraw"
import Customerdetails from "pages/Belwails/Customerdetails"
import Userdetails from "pages/Belwails/Userdetails"
import Cities from "pages/Belwails/Cities"
import Category from "pages/Belwails/Category"
import Products from "pages/Belwails/Products"

// reports

import Snookerreport from "pages/Belwails/reports/Snookerreport"
import Swimmingreport from "pages/Belwails/reports/Swimmingreport"
import Posreport from "pages/Belwails/reports/Posreport"
import Transactions from "pages/Belwails/reports/Transactions"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Partnermore from "pages/Belwails/Partnermore"
import Editrealestate from "pages/Belwails/Editrealestate"

import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"
import Realestatefeatures from "pages/Belwails/Realestatefeatures"
import Basicdetails from "pages/Belwails/Basicdetails"
import Faq from "pages/Belwails/Faq"
import Reviews from "pages/Belwails/Reviews"
import Banner from "pages/Belwails/Banner"
import Pos from "pages/Belwails/Pos"
import Posts from "pages/Belwails/Posts"
import Stories from "pages/Belwails/Stories"
import Reportedpost from "pages/Belwails/Reportedpost"
import Postdetails from "pages/Belwails/Postdetails"
import Marquee from "pages/Belwails/Marquee"
import Departments from "pages/Belwails/Departments"
import Addrole from "pages/Belwails/Addrole"
import Editrole from "pages/Belwails/Editrole"
import Coupons from "pages/Belwails/Coupons"
import Plans from "pages/Belwails/Plans"
import Orders from "pages/Belwails/Orders"
import Tankyou from "pages/Belwails/Tankyou"
import Vieworders from "pages/Belwails/Vieworders"
import Expencecategory from "pages/Belwails/Expencecategory"
import Expense from "pages/Belwails/Expense"
import Suppliers from "pages/Belwails/Suppliers"
import Stocks from "pages/Belwails/Stocks"
import Snookertables from "pages/Belwails/Snookertables"
import Snookerbookings from "pages/Belwails/Snookerbookings"
import Addsnookerbookings from "pages/Belwails/Addsnookerbookings"
import Snookerbookingview from "pages/Belwails/Snookerbookingview"
import Snookerpayments from "pages/Belwails/Snookerpayments"
import Timeslots from "pages/Belwails/Timeslots"

import Swimmingbookings from "pages/Belwails/Swimmingbookings"
import Addswimmingbookings from "pages/Belwails/Addswimmingbookings"
import Swimmingbookingview from "pages/Belwails/Swimmingbookingview"
import Swimmingpayments from "pages/Belwails/Swimmingpayments"
import Swimmingaccessories from "pages/Belwails/Swimmingaccessories"
import Accessorieslist from "pages/Belwails/Accessorieslist"
import Notifications from "pages/Belwails/Notifications"

import Supportrequest from "pages/Belwails/Supportrequest"
import Supportrequestcom from "pages/Belwails/Supportrequestcom"
import Income from "pages/Belwails/Income"
import Ledgerbalance from "pages/Belwails/Ledgerbalance"
import Taxes from "pages/Belwails/Taxes"
import Lockers from 'pages/Belwails/Lockers'
import Visitors from 'pages/Belwails/Visitors'
import Assets from 'pages/Belwails/Assets'
import Reportreasons from 'pages/Belwails/Reportreasons'
import SnookerBookingInvoice from 'pages/Belwails/SnookerBookingInvoice'
import Claim from 'pages/Companypolicy/Claim'
import Aboutus from 'pages/Companypolicy/Aboutus'
import Refund from 'pages/Companypolicy/Refund'
import Termscondition from 'pages/Companypolicy/Termscondition'
import Privacypolicy from 'pages/Companypolicy/Privacypolicy'
import Appterms from 'pages/Companypolicy/Appterms'
import Viewreport from 'pages/Belwails/reports/Viewreport'
import Overalreports from 'pages/Belwails/Overalreports'


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/employees", component: Users },
  { path: "/viewreport", component: Viewreport },
  { path: "/users_details", component: Userdetails },
  { path: "/customers", component: Customers },
  { path: "/viewuser", component: Viewuser },
  { path: "/repotedusers", component: Repoteduser },
  { path: "/userverification", component: Userverification },
  { path: "/userprofilecat", component: Userprofilecat },
  { path: "/customer_details", component: Customerdetails },
  // { path: "/loans", component: Banners },
  { path: "/termsandconditions", component: Termsandconditions },
  { path: "/privacy", component: Privacy },
  { path: "/refering", component: Refering },  
  { path: "/withdraw", component: Withdraw },  
  { path: "/cities", component: Cities },
  { path: "/realestate", component: Partnermore },  
  { path: "/editrealestate", component: Editrealestate },  
  { path: "/realestatefeatures", component: Realestatefeatures },  
  { path: "/category", component:  Category},  
  { path: "/products", component:  Products},  
  { path: "/basic_details", component:  Basicdetails},  
  { path: "/faq", component:  Faq},  
  { path: "/reviews", component:  Reviews},  
  { path: "/banners", component:  Banner},  
  { path: "/pos", component:  Pos},  
  { path: "/posts", component:  Posts},  
  { path: "/stories", component:  Stories},  
  { path: "/postsdetails", component: Postdetails},  
  { path: "/reportedpost", component: Reportedpost},
  { path: "/marquee", component:  Marquee},  
  { path: "/departments", component:  Departments},  
  { path: "/addrole", component:  Addrole},  
  { path: "/editrole", component:  Editrole},  
  { path: "/coupons", component:  Coupons},  
  { path: "/plans", component:  Plans},  
  { path: "/orders", component:  Orders},  
  { path: "/invoice", component:  Tankyou},  
  { path: "/vieworders", component:  Vieworders},  
  { path: "/expencecategory", component:  Expencecategory},  
  { path: "/expense", component:  Expense},  
  { path: "/suppliers", component:  Suppliers},  
  { path: "/stocks", component:  Stocks},  
  { path: "/snookertables", component:  Snookertables},  
  { path: "/snookerbookings", component:  Snookerbookings},    
  { path: "/addsnookerbookings", component:  Addsnookerbookings},    
  { path: "/snookerbookingview", component:  Snookerbookingview},    
  { path: "/snookerpayments", component:  Snookerpayments},  
  { path: "/swimmingbookings", component:  Swimmingbookings},    
  { path: "/addswimmingbookings", component:  Addswimmingbookings},    
  { path: "/swimmingbookingview", component:  Swimmingbookingview},    
  { path: "/swimmingpayments", component:  Swimmingpayments},  
  { path: "/swimmingaccessories", component:  Swimmingaccessories},  
  { path: "/accessorieslist", component:  Accessorieslist},  
  { path: "/timeslots", component:  Timeslots},  
  { path: "/notifications", component:  Notifications},  
  { path: "/supportrequest", component:  Supportrequest},  
  { path: "/completedrequests", component:  Supportrequestcom},  
  { path: "/income", component:  Income},  
  { path: "/ledgerbalance", component:  Ledgerbalance},
  { path: "/taxes", component:  Taxes},
  { path: "/lockers", component:  Lockers},
  { path: "/visitors", component:  Visitors},
  { path: "/assets", component:  Assets},
  { path: "/reportreasons", component:  Reportreasons},
  { path: "/bookinginvoice", component:  SnookerBookingInvoice},

  // Company Policy
  { path: "/claim", component:  Claim},
  { path: "/aboutus", component:  Aboutus},
  { path: "/refund", component:  Refund},
  { path: "/termscondition", component:  Termscondition},
  { path: "/privacypolicy", component:  Privacypolicy},


  // Reports
  { path: "/swimming_reports", component: Swimmingreport },
  { path: "/snooker_reports", component: Snookerreport },  
  { path: "/pos_reports", component: Posreport  },  
  { path: "/realestate_reports", component: Transactions },  
  { path: "/overal_reports", component: Overalreports },  


  // //profile
  { path: "/profile", component: UserProfile },
  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
  { path: "/privacy_policy", component:  Appterms},
]

export { publicRoutes, authProtectedRoutes }
