import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"

// import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"
import ReactApexChart from "react-apexcharts"
import Chart from 'react-apexcharts'
import toast, { Toaster } from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { addData } from "Servicescalls"
import ReactPaginate from "react-paginate"
import { imgUrl } from "Baseurls"
import spinner from "../../assets/images/letast/Spinner.gif"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Dashboard = props => {
  const history = useHistory()
  const [modal, setmodal] = useState(false)
  const [modalspin, setmodalspin] = useState(true)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))
  const [activeTab1, setactiveTab1] = useState("1")

  console.log(activeTab1)
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [graph, setgraph] = useState([])
  const [graph1, setgraph1] = useState([])
  const [graph04, setgraph04] = useState([])
  const [customers, setcustomers] = useState([])
  const [cafe, setcafe] = useState([])
  const [allbooking, setallbooking] = useState([])
  const [swimming, setswimming] = useState([])
  const [snooker, setsnooker] = useState([])
  const [swimGraph, setswimGraph] = useState([])
  const [snookerGraph, setsnookerGraph] = useState([])
  const [cafeGraph, setcafeGraph] = useState([])
  const [emergencys, setEmergencys] = useState([])
  const [allbookinggraph, setallbookinggraph] = useState([])
  const [reportslist, setreportslist] = useState([])
  const [totinc, settotinc] = useState([])
  const [plannames, setplannames] = useState('')
  const uniqueArray = Array.from(new Set(plannames));
  console.log(uniqueArray)



  const Authdetails = localStorage.getItem("authUser")
  const Auth = JSON.parse(Authdetails)
  const access = Auth?.userData?.permissions[0]
  const userIds = Auth?.userData?._id


  const getAlldata = async () => {
    const resonse = await addData("dashboard/socialmediadashboard")
    var _data = resonse
    setform(_data.data.customerCount)
    setform1(_data.data.socialMediaCount)
    setgraph(_data.data.cutomerGraph)
    setgraph1(_data.data.postsGraph)
    setgraph04(_data.data.visitorsGraph)
    setcustomers(_data.data.latestCoustomers)
    setmodalspin(false)
  }

  const getBookingdata = async () => {
    const resonse = await addData("dashboard/bookingdashboard")
    var _data = resonse
    setcafe(_data.data.cafe)
    setallbooking(_data.data.allBookings)
    setswimming(_data.data.swimming)
    setsnooker(_data.data.snooker)
    setswimGraph(_data.data.swimmingBarGraph)
    setsnookerGraph(_data.data.snookerBarGraph)
    setcafeGraph(_data.data.cafeBarGraph)
    setallbookinggraph(_data.data.graph)
    setmodalspin(false)
  }

  const getReportData = async () => {
    const resonse = await addData("finance/detailedreport")
    var _data = resonse
    setreportslist(_data.data.incomes)
    settotinc(_data.data.totalIncomes)
  }

  const getEmergencys = async () => {
    const resonse = await addData("plan/getall")
    var data = resonse.data.planResult
    setEmergencys(data)

    if (data.some((item) => item.status === false)) {
      const planWithTrueStatus = data.find((item) => item.status === false);

      if (planWithTrueStatus) {
        const planName = planWithTrueStatus.plan;
        console.log(planName)
        setplannames((prevPlanNames) => [...prevPlanNames, planName])
        setSubscribemodal(true);
      }
    }

    // const planNamesWithTrueStatus = data
    //   .filter((item) => item.status === true)
    //   .map((item) => item.plan);

    // setplannames((prevPlanNames) => [...prevPlanNames, ...planNamesWithTrueStatus]);

    // if (planNamesWithTrueStatus.length > 0) {
    //   setSubscribemodal(true);
    // }

    // const plansWithTrueStatus = data.map((item) => item.status);
    // if (plansWithTrueStatus.some((status) => status === true)) {
    //   setSubscribemodal(true);
    // }
  }

  const hiddenIds = ["Cafe"];
  const filteredData = emergencys.filter(
    (item) => !hiddenIds.includes(item.plan)
  );

  const handleChange = async (e, key) => {
    const updatedBanner = emergencys.map((item, index) => {
      if (index === key) {
        return {
          ...item,
          status: e.target.checked,
        };
      }
      return item;
    });

    setEmergencys(updatedBanner);
    const planName = filteredData[key].plan;

    setplannames((prevPlanNames) => {
      if (!Array.isArray(prevPlanNames)) {
        return [planName];
      }

      if (e.target.checked) {
        if (!prevPlanNames.includes(planName)) {
          return [...prevPlanNames, planName];
        }
      } else {
        return prevPlanNames.filter((name) => name !== planName);
      }

      return prevPlanNames;
    });
    const paramsdata = {
      plan: updatedBanner
    }

    try {
      const resonse = await addData("plan/updateplanstatus", paramsdata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getEmergencys()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  };

  const filterhandleChange = async (e) => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const bodydate = {
      dates: e.target.value
    }
    const resonse = await addData("finance/detailedreport", bodydate)
    var _data = resonse
    setreportslist(_data.data.incomes)
    settotinc(_data.data.totalIncomes)
  }


  // const editbookingstatus = async (e) => {
  //   e.preventDefault()
  //   const paramsdata = {
  //     plan: emergencys
  //   }
  //   try {
  //     const resonse = await addData("plan/updateplanstatus", paramsdata)
  //     var _data = resonse
  //     console.log(_data)
  //     toast.success(_data.data.message)
  //     getEmergencys()
  //   } catch (error) {
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       toast.error(error.response.data.message)
  //     } else {
  //       toast.error("An error occurred. Please try again.")
  //     }
  //   }
  // }
  useEffect(() => {
    getAlldata()
    getBookingdata()
    getEmergencys()
    getReportData()
  }, [])


  const reports = [
    access && access.all === true
      ? { title: "All Bookings", iconClass: "bx bx-book-content", description: allbooking.total, description1: allbooking.today }
      : null,
    (access && (access.Swimmingpool === true || access.all === true))
      ? { title: "Swimming pool", iconClass: "bx bx-swim", description: swimming.totalSwimmingBookings, description1: swimming.todaySwimmingBookings }
      : null,
    (access && (access.SnookerParlour === true || access.all === true))
      ? { title: "Snooker Parlour", iconClass: "bx bx-tennis-ball", description: snooker.totalSnookerBookings, description1: snooker.todaySnookerBookings }
      : null,
    (access && (access.pos === true || access.all === true))
      ? { title: "Cafe Orders", iconClass: "bx bx-store-alt", description: cafe.totalCafeOrderCount, description1: cafe.todayCafeOrderCount }
      : null
  ].filter(report => report !== null);

  const reports2 = [
    { title: "Total Users", iconClass: "bx bx-user-circle", description: form.customerTotalCount },
    { title: "Today Users", iconClass: "bx bx-user-check", description: form.customerTodaysCount },
    { title: "Total Posts", iconClass: "bx bx-images", description: form1.socialMediaTotalCount },
    { title: "Today Posts", iconClass: "bx bx-news", description: form1.socialMediaTodaysCount },
  ]

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true);
  //   }, 2000);
  // }, []);

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  const series = [
    {
      name: "Swimming Pool",
      data: allbookinggraph.swimmingGraph,
    },
    {
      name: "Snooker parlour",
      data: allbookinggraph.snookerGraph,
    },
    {
      name: "Cafe Orders",
      data: allbookinggraph.cafeGraph,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#007bff", "#3f844a", "#ff5d5d",],
    xaxis: {
      type: "datetime",
      categories: allbookinggraph.last12Days,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  }


  const getByfunction = (data) => {
    sessionStorage.setItem("customerid", data._id)
    history.push("/customer_details")
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = customers.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(customers.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }


  //meta title
  // document.title="Dashboard | Skote - React Admin & Dashboard Template";

  const series1 = [
    {
      name: "Bookings",
      data: swimGraph,
    },
  ]

  const options1 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
        barWidth: 5,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: ["#556ee6"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      labels: {
        offsetY: -18,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val
        },
      },
    },
    title: {
      text: `Swimming Pool Bookings, ${new Date().getFullYear()}`,
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  }

  // snooker parlour

  const series2 = [
    {
      name: "Bookings",
      data: snookerGraph,
    },
  ]

  const options2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: ["#1d6e2a"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      labels: {
        offsetY: -18,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val
        },
      },
    },
    title: {
      text: `Snooker Parlour Bookings, ${new Date().getFullYear()}`,
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  }

  // Cafe orders

  const series3 = [
    {
      name: "Orders",
      data: cafeGraph,
    },
  ]

  const options3 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: ["#db4040"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      labels: {
        offsetY: -18,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val
        },
      },
    },
    title: {
      text: `Cafe Orders, ${new Date().getFullYear()}`,
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  }

  // user graph

  const options4 = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      type: 'line',
      dropShadow: {
        enabled: true,
        top: 18,
        left: 2,
        blur: 5,
        opacity: 0.2
      },
      offsetX: -10
    },
    stroke: {
      curve: 'smooth',
      width: 4
    },
    grid: {
      borderColor: '#ebe9f1',
      padding: {
        top: -20,
        bottom: 5,
        left: 20
      }
    },
    legend: {
      show: false
    },
    colors: ['#fb445a'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        inverseColors: false,
        gradientToColors: ["#fb445a"],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 10000, 10000, 10000]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    xaxis: {
      labels: {
        offsetY: 5,
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        }
      },
      axisTicks: {
        show: false
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisBorder: {
        show: false
      },
      tickPlacement: 'on'
    },
    yaxis: {
      tickAmount: 8,
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        },
        formatter(val) {
          // .toFixed(1)
          return val > 999 ? `${(val / 1000)}k` : val
        }
      }
    },
    tooltip: {
      x: { show: false }
    }
  },
    series4 = [
      {
        name: 'Users',
        data: graph
      }
    ]

  // posts graph
  const options5 = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      type: 'line',
      dropShadow: {
        enabled: true,
        top: 18,
        left: 2,
        blur: 5,
        opacity: 0.2
      },
      offsetX: -10
    },
    stroke: {
      curve: 'smooth',
      width: 4
    },
    grid: {
      borderColor: '#ebe9f1',
      padding: {
        top: -20,
        bottom: 5,
        left: 20
      }
    },
    legend: {
      show: false
    },
    colors: ['#fb445a'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        inverseColors: false,
        gradientToColors: ["#fb445a"],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 10000, 10000, 10000]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    xaxis: {
      labels: {
        offsetY: 5,
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        }
      },
      axisTicks: {
        show: false
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisBorder: {
        show: false
      },
      tickPlacement: 'on'
    },
    yaxis: {
      tickAmount: 8,
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        },
        formatter(val) {
          // .toFixed(1)
          return val > 999 ? `${(val / 1000)}k` : val
        }
      }
    },
    tooltip: {
      x: { show: false }
    }
  },
    series5 = [
      {
        name: 'Posts',
        data: graph1
      }
    ]
  // Visiter graph
  const options04 = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      type: 'line',
      dropShadow: {
        enabled: true,
        top: 18,
        left: 2,
        blur: 5,
        opacity: 0.2
      },
      offsetX: -10
    },
    stroke: {
      curve: 'smooth',
      width: 4
    },
    grid: {
      borderColor: '#ebe9f1',
      padding: {
        top: -20,
        bottom: 5,
        left: 20
      }
    },
    legend: {
      show: false
    },
    colors: ['#fb445a'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        inverseColors: false,
        gradientToColors: ["#fb445a"],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 10000, 10000, 10000]
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    xaxis: {
      labels: {
        offsetY: 5,
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        }
      },
      axisTicks: {
        show: false
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisBorder: {
        show: false
      },
      tickPlacement: 'on'
    },
    yaxis: {
      tickAmount: 8,
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '0.857rem',
          fontFamily: 'Montserrat'
        },
        formatter(val) {
          // .toFixed(1)
          return val > 999 ? `${(val / 1000)}k` : val
        }
      }
    },
    tooltip: {
      x: { show: false }
    }
  },
    series04 = [
      {
        name: 'Visitors',
        data: graph04
      }
    ]

  const [formss, setformss] = useState([])

  const handlechangesss = (e) => {
    const myForms = { ...formss }
    myForms[e.target.name] = e.target.value;
    setformss(myForms)
  }

  const redirectuser = (data) => {
    sessionStorage.setItem('userid', data._id)
    history.push('/viewuser')
  }



  // Reports of administration

  useEffect(() => {
    getReportData1()
    getReportData12()
    getReportData13()
    getReportData14()
  }, [])

  const [swimmingrep, setswimmingrep] = useState([])
  const [snookerrep, setsnookerrep] = useState([])
  const [caferep, setcaferep] = useState([])
  const [overallrep, setoverallrep] = useState([])

  const getReportData1 = async () => {
    const resonse = await addData("dailyreport/swimmingreport")
    var _data = resonse
    setswimmingrep(_data.data.swimmingReport)
    setDates([]);
  }
  const getReportData12 = async () => {
    const resonse = await addData("dailyreport/snooker_report")
    var _data = resonse
    setsnookerrep(_data.data.snookerReports)
    setDates12([]);
  }
  const getReportData13 = async () => {
    const resonse = await addData("dailyreport/cafereport")
    var _data = resonse
    setcaferep(_data.data.cafeDailyReports)
    setDates13([]);
  }
  const getReportData14 = async () => {
    const resonse = await addData("dailyreport/overallreport")
    var _data = resonse
    setoverallrep(_data.data.overallReport)
    setDates14([]);
  }

  const [listPerPage1] = useState(10)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = swimmingrep.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(swimmingrep.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }
  const [listPerPage12] = useState(10)
  const [pageNumber12, setPageNumber12] = useState(0)

  const pagesVisited12 = pageNumber12 * listPerPage12
  const lists12 = snookerrep.slice(pagesVisited12, pagesVisited12 + listPerPage12)
  const pageCount12 = Math.ceil(snookerrep.length / listPerPage12)
  const changePage12 = ({ selected }) => {
    setPageNumber12(selected)
  }
  const [listPerPage13] = useState(10)
  const [pageNumber13, setPageNumber13] = useState(0)

  const pagesVisited13 = pageNumber13 * listPerPage13
  const lists13 = caferep.slice(pagesVisited13, pagesVisited13 + listPerPage13)
  const pageCount13 = Math.ceil(caferep.length / listPerPage13)
  const changePage13 = ({ selected }) => {
    setPageNumber13(selected)
  }
  const [listPerPage14] = useState(10)
  const [pageNumber14, setPageNumber14] = useState(0)

  const pagesVisited14 = pageNumber14 * listPerPage14
  const lists14 = overallrep.slice(pagesVisited14, pagesVisited14 + listPerPage14)
  const pageCount14 = Math.ceil(overallrep.length / listPerPage14)
  const changePage14 = ({ selected }) => {
    setPageNumber14(selected)
  }

  // Filter for reports
  const [dates, setDates] = useState([]);
  const [dates12, setDates12] = useState([]);
  const [dates13, setDates13] = useState([]);
  const [dates14, setDates14] = useState([]);

  const handleDateChange = async (NewDate) => {
    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
    const newDates = [date1, date2];
    setDates(newDates);
    const bodydate = {
      dates: newDates
    }
    const response = await addData("dailyreport/swimmingreport", bodydate);
    const _data = response.data;
    setswimmingrep(_data.swimmingReport);
  };

  const handleDateChange12 = async (NewDate12) => {
    const date1 = format(new Date(NewDate12[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate12[1]), "yyyy-MM-dd");
    const newDates12 = [date1, date2];
    setDates12(newDates12);
    const bodydate = {
      dates: newDates12
    }
    const response = await addData("dailyreport/snooker_report", bodydate);
    const _data = response.data;
    setsnookerrep(_data.snookerReports);
  };

  const handleDateChange13 = async (NewDate13) => {
    const date1 = format(new Date(NewDate13[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate13[1]), "yyyy-MM-dd");
    const newDates13 = [date1, date2];
    setDates13(newDates13);
    const bodydate = {
      dates: newDates13
    }
    const response = await addData("dailyreport/cafereport", bodydate);
    const _data = response.data;
    setcaferep(_data.cafeDailyReports);
  };
  const handleDateChange14 = async (NewDate13) => {
    const date1 = format(new Date(NewDate13[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate13[1]), "yyyy-MM-dd");
    const newDates13 = [date1, date2];
    setDates14(newDates13);
    const bodydate = {
      dates: newDates13
    }
    const response = await addData("dailyreport/overallreport", bodydate);
    const _data = response.data;
    setoverallrep(_data.overallReport);
  };

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
      getBookings(tab)
      setDates([]);
      setDates12([]);
      setDates13([]);
      setDates14([]);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {modalspin == true ? (
          <div className="text-center mt-5 pt-5">
            <img src={spinner} />
          </div>
        ) :
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Belwails")}
              breadcrumbItem={props.t("Dashboard")}
            />

            <Row>
              <Col md="3" />
              <Col md="6">

                <Row style={{ fontSize: "16px" }} className="mb-4">
                  {access?.dashview == true || access?.all == true ? (
                    <Col>
                      <div className="form-check">
                        <input onChange={(e) => { handlechangesss(e) }} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Bookings" defaultChecked />
                        <label className="form-check-label" htmlFor="exampleRadios1">
                          Bookings
                        </label>
                      </div>
                    </Col>
                  ) : ""}
                  {access?.dashview1 == true || access?.all == true ? (
                    <Col>
                      <div className="form-check">
                        <input onChange={(e) => { handlechangesss(e) }} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Socialmedia" />
                        <label className="form-check-label" htmlFor="exampleRadios2">
                          Social media
                        </label>
                      </div>
                    </Col>
                  ) : ""}
                  {access?.dashview2 == true || access?.all == true ? (
                    <Col>
                      <div className="form-check">
                        <input onChange={(e) => { handlechangesss(e) }} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="report" />
                        <label className="form-check-label" htmlFor="exampleRadios3">
                          Report
                        </label>
                      </div>
                    </Col>
                  ) : ""}
                </Row>
              </Col>
              <Col md="3" />
            </Row>

            {formss.exampleRadios == "Socialmedia" ? (
              <>
                <Row>
                  <Col xl="12">
                    <Row>

                      {reports2.map((report, key) => (
                        <Col md="3" key={"_col_" + key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <span>{report.title}</span>
                                  <h5 className="text-muted">{report.description}</h5>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                      className={
                                        report.iconClass + " font-size-24"
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <Card>
                      <h5 className="p-3">Users</h5>
                      <Chart className="p-2" options={options4} series={series4} type='line' height={270} />
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <h5 className="p-3">Posts</h5>
                      <Chart className="p-2" options={options5} series={series5} type='line' height={270} />
                    </Card>
                  </Col>
                  <Col md="12">
                    <Card>
                      <h5 className="p-3">Total Visitors</h5>
                      <Chart className="p-2" options={options04} series={series04} type='line' height={270} />
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    {/* <LatestTranaction /> */}
                    <div>
                      <Card>
                        <CardBody>
                          <h5 className="mt-3 mb-3">Latest Users</h5>
                          <div className="table-rep-plugin">
                            <Table hover bordered responsive>
                              <thead style={{ background: "#ff8787", color: "#fff" }}>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Name</th>
                                  <th>Profile pic</th>
                                  <th>Email</th>
                                  <th>Mobile No</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists.map((data, key) => (
                                  <tr key={key}>
                                    <th scope="row">
                                      {(pageNumber - 1) * 5 + key + 6}
                                    </th>
                                    <td>{data.date}</td>
                                    <td><u onClick={() => { redirectuser(data) }}>{data.name}</u></td>
                                    <td><img style={{ width: "50px" }} src={imgUrl + data.profilePic} /></td>
                                    <td>{data.email}</td>
                                    <td>{data.phone}</td>
                                    <td>{data.status == true ? "Active" : "Blocked"}</td>

                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Col sm="12">
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount}
                                  onPageChange={changePage}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists.length}
                                />
                              </div>
                            </Col>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </>
            ) : formss.exampleRadios == "report" ? (
              <>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="8">
                        <Nav pills className="navtab-bg nav-justified">
                          {access?.Swimmingpool == true || access?.all == true ? (
                            <NavItem className="border border-primary rounded m-1">
                              <NavLink
                                style={{ cursor: "pointer", padding: "10px" }}
                                className={classnames({
                                  active: activeTab1 === "1",
                                })}
                                onClick={() => {
                                  toggle1("1")
                                }}
                              >
                                Swimming Pool
                              </NavLink>
                            </NavItem>
                          ) : ""}
                          {access?.SnookerParlour == true || access?.all == true ? (
                            <NavItem className="border border-primary rounded m-1">
                              <NavLink
                                style={{ cursor: "pointer", padding: "10px" }}
                                className={classnames({
                                  active: activeTab1 === "2",
                                })}
                                onClick={() => {
                                  toggle1("2")
                                }}
                              >
                                Snooker Parlour
                              </NavLink>
                            </NavItem>
                          ) : ""}
                          {access?.pos == true || access?.all == true ? (
                            <NavItem className="border border-primary rounded m-1">
                              <NavLink
                                style={{ cursor: "pointer", padding: "10px" }}
                                className={classnames({
                                  active: activeTab1 === "3",
                                })}
                                onClick={() => {
                                  toggle1("3")
                                }}
                              >
                                Cafe
                              </NavLink>
                            </NavItem>
                          ) : ""}
                          {access?.all == true ? (
                            <NavItem className="border border-primary rounded m-1">
                              <NavLink
                                style={{ cursor: "pointer", padding: "10px" }}
                                className={classnames({
                                  active: activeTab1 === "4",
                                })}
                                onClick={() => {
                                  toggle1("4")
                                }}
                              >
                                Overall Report
                              </NavLink>
                            </NavItem>
                          ) : ""}
                        </Nav>
                      </Col>
                      {/* <Col className="col-3 offset-3 mt-2">
                        {activeTab1 === "2" ? (
                          <Flatpickr
                            placeholder="Select date12"
                            className="form-control"
                            name="date"
                            // value={dates12}
                            onChange={(e) => { handleDateChange12(e) }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                            }}
                          />
                        ) : activeTab1 === "3" ? (
                          <Flatpickr
                            placeholder="Select date 13"
                            className="form-control"
                            name="date12"
                            // value={dates13}
                            onChange={(e) => { handleDateChange13(e) }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                            }}
                          />
                        ) : (
                          <Flatpickr
                            placeholder="Select date"
                            className="form-control"
                            name="date"
                            // value={dates}
                            onChange={(e) => { handleDateChange(e) }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                            }}
                          />
                        )}
                      </Col> */}
                    </Row>

                    <Row>
                      <TabContent activeTab={activeTab1} className="p-3 text-muted">
                        {access?.Swimmingpool == true || access?.all == true ? (
                          <TabPane tabId="1">
                            <Row>
                              <Col md="12" style={{ float: "right" }}>
                                {/* 
                              <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={() => { getReportData1() }} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div> */}
                                <div style={{ float: "right" }}>
                                  <Flatpickr

                                    placeholder="Select date"
                                    className="form-control"
                                    name="date"
                                    // value={dates12}
                                    onChange={(e) => { handleDateChange(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <Table hover className="table table-bordered mb-4 mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SlNo</th>
                                    <th>Start Date</th>
                                    <th>Closing Date</th>
                                    <th>Opening Bal</th>
                                    <th>Closing Bal</th>
                                    <th>Invoice Range</th>
                                    <th>Total Sales</th>
                                    <th>Cash Sales</th>
                                    <th>Online Sales </th>
                                    <th>Total Expenses </th>
                                    <th>Today Cash in Hand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lists1.length == 0 ? (
                                    <tr className="text-center" >
                                      <th colSpan={11}>No data...</th>
                                    </tr>
                                  ) : (
                                    <>
                                      {lists1.map((data, key) => (
                                        <tr className="text-center" key={key}>
                                          <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                          <td>{data.startDate}</td>
                                          <td>{data.endDate}</td>
                                          <td>{data.openingBalance}</td>
                                          <td>{data.closingBalance}</td>
                                          <td>{data.invoiceStart} to {data.invoiceEnd}</td>
                                          <td>{data.totalSales}</td>
                                          <td>{data.cashTotal}</td>
                                          <td>{data.onlineTotal}</td>
                                          <td>{data.expensesTotal}</td>
                                          <td>{data.todaycashinhand}</td>

                                        </tr>
                                      ))}
                                    </>
                                  )}

                                </tbody>
                              </Table>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount1}
                                  onPageChange={changePage1}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists1.length}
                                />
                              </div>
                            </div>
                          </TabPane>
                        ) : ""}
                        {access?.SnookerParlour == true || access?.all == true ? (
                          <TabPane tabId="2">
                            <Row>
                              <Col md="12" style={{ float: "right" }}>
                                {/* <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={() => { getReportData12() }} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div> */}
                                <div style={{ float: "right" }}>
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="date12"
                                    // value={dates13}
                                    onChange={(e) => { handleDateChange12(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive">
                              <Table hover className="table table-bordered mb-4 mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SlNo</th>
                                    <th>Start Date</th>
                                    <th>Closing Date</th>
                                    <th>Opening Bal</th>
                                    <th>Closing Bal</th>
                                    <th>Invoice Range</th>
                                    <th>Total Sales</th>
                                    <th>Cash Sales</th>
                                    <th>Online Sales </th>
                                    <th>Total Expenses </th>
                                    <th>Today Cash in Hand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lists12.length == 0 ? (
                                    <tr className="text-center" >
                                      <th colSpan={11}>No data...</th>
                                    </tr>
                                  ) : (
                                    <>
                                      {lists12.map((data, key) => (
                                        <tr className="text-center" key={key}>
                                          <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                          <td>{data.startDate}</td>
                                          <td>{data.endDate}</td>
                                          <td>{data.openingBalance}</td>
                                          <td>{data.closingBalance}</td>
                                          <td>{data.invoiceStart} to {data.invoiceEnd}</td>
                                          <td>{data.totalSales}</td>
                                          <td>{data.cashTotal}</td>
                                          <td>{data.onlineTotal}</td>
                                          <td>{data.expensesTotal}</td>
                                          <td>{data.todaycashinhand}</td>

                                        </tr>
                                      ))}
                                    </>
                                  )}

                                </tbody>
                              </Table>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount12}
                                  onPageChange={changePage12}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists12.length}
                                />
                              </div>
                            </div>
                          </TabPane>
                        ) : ""}
                        {access?.pos == true || access?.all == true ? (
                          <TabPane tabId="3">
                            <Row>
                              <Col md="12" style={{ float: "right" }}>
                                {/* <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={() => { getReportData13() }} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div> */}
                                <div style={{ float: "right" }}>
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="date"
                                    // value={dates}
                                    onChange={(e) => { handleDateChange13(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive">
                              <Table hover className="table table-bordered mb-4 mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SlNo</th>
                                    <th>Start Date</th>
                                    <th>Closing Date</th>
                                    <th>Opening Bal</th>
                                    <th>Closing Bal</th>
                                    <th>Invoice Range</th>
                                    <th>Total Sales</th>
                                    <th>Cash Sales</th>
                                    <th>Online Sales </th>
                                    <th>Total Expenses </th>
                                    <th>Today Cash in Hand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lists13.length == 0 ? (
                                    <tr className="text-center" >
                                      <th colSpan={11}>No data...</th>
                                    </tr>
                                  ) : (
                                    <>
                                      {lists13.map((data, key) => (
                                        <tr className="text-center" key={key}>
                                          <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                          <td>{data.startDate}</td>
                                          <td>{data.endDate}</td>
                                          <td>{data.openingBalance}</td>
                                          <td>{data.closingBalance}</td>
                                          <td>{data.invoiceStart} to {data.invoiceEnd}</td>
                                          <td>{data.totalSales}</td>
                                          <td>{data.cashTotal}</td>
                                          <td>{data.onlineTotal}</td>
                                          <td>{data.expensesTotal}</td>
                                          <td>{data.todaycashinhand}</td>
                                        </tr>
                                      ))}
                                    </>
                                  )}

                                </tbody>
                              </Table>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount13}
                                  onPageChange={changePage13}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists13.length}
                                />
                              </div>
                            </div>
                          </TabPane>
                        ) : ""}
                        {access?.all == true ? (
                          <TabPane tabId="4">
                            <Row>
                              <Col md="12" style={{ float: "right" }}>
                                {/* <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={() => { getReportData14() }} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div> */}
                                <div style={{ float: "right" }}>
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="date"
                                    // value={dates}
                                    onChange={(e) => { handleDateChange14(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="table-responsive">
                              <Table hover className="table table-bordered mb-4 mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>SlNo</th>
                                    <th>Start Date</th>
                                    <th>Closing Date</th>
                                    <th>Opening Bal</th>
                                    <th>Closing Bal</th>
                                    {/* <th>Invoice Range</th> */}
                                    <th>Total Sales</th>
                                    <th>Cash Sales</th>
                                    <th>Online Sales </th>
                                    <th>Total Expenses </th>
                                    <th>Today Cash in Hand</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lists14.length == 0 ? (
                                    <tr className="text-center" >
                                      <th colSpan={10}>No data...</th>
                                    </tr>
                                  ) : (
                                    <>
                                      {lists14.map((data, key) => (
                                        <tr className="text-center" key={key}>
                                          <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                          <td>{data.startDate}</td>
                                          <td>{data.endDate}</td>
                                          <td>{data.openingBalance}</td>
                                          <td>{data.closingBalance}</td>
                                          {/* <td>{data.invoiceStart} to {data.invoiceEnd}</td> */}
                                          <td>{data.totalSales}</td>
                                          <td>{data.cashTotal}</td>
                                          <td>{data.onlineTotal}</td>
                                          <td>{data.expensesTotal}</td>
                                          <td>{data.todaycashinhand}</td>
                                        </tr>
                                      ))}
                                    </>
                                  )}

                                </tbody>
                              </Table>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount14}
                                  onPageChange={changePage14}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists14.length}
                                />
                              </div>
                            </div>
                          </TabPane>
                        ) : ""}
                      </TabContent>
                    </Row>

                    {/* <Table responsive bordered hover >
                      <thead>
                        <tr>
                          <th>Activity</th>
                          <th>Opening Balance </th>
                          <th>Total Cash  </th>
                          <th>Total Online  </th>
                          <th>Expenses </th>
                          <th>Closing Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Swimming Pool</th>
                          <td>₹ {reportslist?.swimmingPool?.openingBalance}</td>
                          <td>₹ {reportslist?.swimmingPool?.totalCash}</td>
                          <td>₹ {reportslist?.swimmingPool?.totalOnline}</td>
                          <td>₹ {reportslist?.swimmingPool?.split}</td>
                          <td>₹ {reportslist?.swimmingPool?.closingBalance}</td>
                        </tr>
                        <tr>
                          <th>Snooker Parlour</th>
                          <td>₹ {reportslist?.snooker?.openingBalance}</td>
                          <td>₹ {reportslist?.snooker?.totalCash}</td>
                          <td>₹ {reportslist?.snooker?.totalOnline}</td>
                          <td>₹ {reportslist?.snooker?.split}</td>
                          <td>₹ {reportslist?.snooker?.closingBalance}</td>
                        </tr>
                        <tr>
                          <th>Cafe</th>
                          <td>₹ {reportslist?.cafe?.openingBalance}</td>
                          <td>₹ {reportslist?.cafe?.totalCash}</td>
                          <td>₹ {reportslist?.cafe?.totalOnline}</td>
                          <td>₹ {reportslist?.cafe?.split}</td>
                          <td>₹ {reportslist?.cafe?.closingBalance}</td>
                        </tr>
                        <tr>
                          <th>Total</th>
                          <td>₹ {totinc.openingBalance}</td>
                          <td>₹ {totinc.totalCash}</td>
                          <td>₹ {totinc.totalOnline}</td>
                          <td>₹ {totinc.split}</td>
                          <td>₹ {totinc.closingBalance}</td>
                        </tr>
                      </tbody>
                    </Table> */}
                  </CardBody>
                </Card>
              </>
            ) : (
              <>
                {access?.dashview == true || access?.all == true ? (
                  <>
                    <Row>
                      <Col xl="12">
                        <Row>
                          {/* Reports Render */}
                          {reports.map((report, key) => (
                            <Col md="3" key={"_col_" + key}>
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div className="text-center">
                                        {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"> */}
                                        {/* <span className="avatar-title rounded-circle "> */}
                                        <i
                                          className={
                                            report.iconClass + " font-size-24 border border-danger text-danger mb-1 p-2 rounded-circle"
                                          }
                                        ></i>
                                        {/* </span> */}
                                        {/* </div> */}
                                        <h5>{report.title}</h5>
                                      </div>
                                      <Row className="mt-4">
                                        <Col>
                                          <div className="text-center">
                                            <span style={{ fontSize: "10px" }} className="text-muted fw-medium"> Total Bookings <br /> </span><b className="text-muted" style={{ fontSize: "18px" }}>{report.description}</b><br />
                                          </div>
                                        </Col>
                                        <Col>
                                          <div className="text-center">
                                            <span style={{ fontSize: "10px" }} className="text-muted fw-medium"> Today Bookings <br /> </span><b className="text-muted" style={{ fontSize: "18px" }}>{report.description1}</b><br />
                                          </div>
                                        </Col>
                                      </Row>


                                    </div>
                                    {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div> */}
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      {access?.Swimmingpool == true || access?.all == true ? (
                        <Col md="4">
                          <Card>
                            <ReactApexChart options={options1} series={series1} type="bar" height={350} />
                          </Card>
                        </Col>
                      ) : ""}
                      {access?.SnookerParlour == true || access?.all == true ? (
                        <Col md="4">
                          <Card>
                            <ReactApexChart options={options2} series={series2} type="bar" height={350} />
                          </Card>
                        </Col>
                      ) : ""}
                      {access?.pos == true || access?.all == true ? (
                        <Col md="4">
                          <Card>
                            <ReactApexChart options={options3} series={series3} type="bar" height={350} />
                          </Card>
                        </Col>
                      ) : ""}
                    </Row>
                    {access?.all == true ? (
                      <>
                        <Row>
                          <Card>
                            <CardBody>
                              <div className="clearfix">
                                <h4 className="card-title mb-4">All Bookings</h4>
                              </div>
                              <Row>
                                <Col lg="12">
                                  <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="area"
                                    height="250"
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Row>

                        <Card>
                          <CardBody>
                            <form
                            //  onSubmit={(e) => { editbookingstatus(e) }} 
                            >
                              <Row>
                                <Col md="2">
                                  <h5>Emergency :</h5>
                                </Col>
                                {filteredData.map((data, key) => (
                                  <Col key={key} md="3">
                                    <div className="form-check form-switch form-switch-md" >
                                      <input
                                        type="checkbox"
                                        defaultChecked={data.status}
                                        onChange={(e) => {
                                          handleChange(e, key)
                                        }}
                                        id={`customSwitchsizemd${key}`}
                                        className="form-check-input"
                                        value={data.status}
                                        name="status"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`customSwitchsizemd${key}`}
                                      >
                                        {data.plan}
                                      </label>
                                    </div>
                                  </Col>
                                ))}
                                {/* <Col md="1">
                      <Button style={{ width: "120px" }} className="btn-sm" outline color="primary" type="submit">Submit</Button>
                    </Col> */}

                              </Row>
                              <div>
                                <marquee style={{ fontWeight: 400 }} className="blink">You can turn off your plans to hear when an emergency situations.</marquee>
                              </div>
                            </form>
                          </CardBody>
                        </Card>
                      </>
                    ) : ""}
                  </>
                ) : (
                  <h2 className="text-center pt-5"><b>No Access</b></h2>
                )}

              </>
            )}
          </Container>
        }
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-3">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i style={{ fontSize: "40px" }} className="bx bx-info-circle"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Intimation !</h4>
                <p className="text-muted font-size-14 mb-4">
                  The plans are inactive in our application.
                  {/* {uniqueArray + ","} */}

                </p>

                {/* <div className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Toaster />
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
