import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Banners = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ reportReason: "" })
  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])
  const [Files1, setFiles1] = useState({ bannerImage: "" })
  const [Files, setFiles] = useState({ bannerImage: "" })

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])

  const changeHandler = (e) => {
    setFiles(e.target.files)
  }
  const changeHandler1 = (e) => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllbenners = async () => {
    const resonse = await addData("reportpost/allreportresons")
    var _data = resonse
    setbanner(_data?.data?.latestReports)
    setFilter(_data?.data?.latestReports)
  }

  useEffect(() => {
    getAllbenners()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Date",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Report Reason",
      selector: row => row.reportReason,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status == true ?"Active":"Inactive",
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]


  useEffect(() => {
    const result = banner.filter((data) => {
      const searchTerm = search.toLowerCase().trim();
      const searchWords = searchTerm.split(" ");
      return searchWords.some((word) =>
        data.reportReason.toLowerCase().includes(word)
      );
    });

    setFilter(result);
  }, [search]);


  // useEffect(() => {
  //   const result = banner.filter((data) => {
  //     return (
  //       (data.name && data.name.toLowerCase().includes(search.toLowerCase())) ||
  //       (data.status && data.status.toLowerCase().includes(search.toLowerCase()))
  //     );
  //   });
  //   setFilter(result);
  // }, [search]);

  // Add fuction
  const addbenners = async e => {
    e.preventDefault()
    const bodydata ={
        reportReason: form.reportReason
    }
 
    try {
      const resonse = await addData("reportpost/addreportreasons", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ reportReason: "" })
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editbenners = async (e) => {
    e.preventDefault()
    const bodydata ={
        reportReason: form1.reportReason,
        status: form1.status
    }
    try {
      const resonse = await updateData("reportpost/updatereportreason/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1({ reportReason: "" })
      setmodal_small(false)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deletebenners = async data => {
    try {
      const resonse = await deletedData(
        "reportpost/deletereportreason/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllbenners()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [imgview, setimgview] = useState(false)
  const [imgview1, setimgview1] = useState([])


  function openmodels(data) {
    setimgview(!imgview)
    setimgview1(data)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Report Reasons" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={4}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Reason</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      addbenners(e)
                    }}
                  >
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                      Reason <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter Reason"
                        required
                        name="reportReason"
                        value={form.reportReason}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    <div className="mt-4" style={{ float: "right" }}>
                      <Button color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <Row>
                            <Col md="6">
                            <CardTitle>Reasons List</CardTitle>
                            </Col>
                            <Col md="6">
                            <div style={{ float: "right" }}>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                            </Col>
                          </Row>
                        
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Reason
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editbenners(e)
              }}
            >
              <Row>

                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                    Reason <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Reason"
                      required
                      name="reportReason"
                      value={form1.reportReason}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
             
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="true">Active</option>
                      <option value="false">In Active</option>
                    </select>
                  </div>
                </Col>

              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={imgview}
          toggle={() => {
            setimgview()
          }}
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setimgview(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <img src={imgUrl + imgview1.bannerImage} />
            </Row>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Banners
