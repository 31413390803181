import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Modal,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import { saveAs } from "file-saver"
import DataTable from "react-data-table-component"
import logoLightPng from "../../assets/images/raglogo.png"
import userimg from "../../assets/images/brands/userimg.png"
import invoiceimg from "../../assets/images/brands/invoice.jpg"
import { useReactToPrint } from "react-to-print"

const OrderDetails = () => {
    const history = useHistory()

    const [products, setproducts] = useState([])
    const [invoice, setinvoice] = useState([])
    const [phone, setphone] = useState([])
    const [modal_small, setmodal_small] = useState(false)
    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    const getAllOrders = async () => {
        const bodydata = {
            id: sessionStorage.getItem("snookerbookid"),
        }
        const resonse = await addData("snookerbooking/getsnookerbookingbyid", bodydata)
        var _data = resonse
        // setinvoice(_data.data.orderResult)
        setproducts(_data.data.snookerBooking)
        // setphone(_data.data.customerMobile)
    }

    useEffect(() => {
        getAllOrders()
    }, [])


    const downloadImage = () => {
        sessionStorage.setItem("bookingsids", sessionStorage.getItem("snookerbookid"))
        sessionStorage.setItem("bookingtypes", "Snookerbooking")
        history.push("/bookinginvoice")
        // saveAs(invoiceimg) 
    }

    const handleChange1 = (e) => {
        let myUser = { ...products }
        myUser[e.target.name] = e.target.value
        setproducts(myUser)
        console.log(myUser)
        myUser.onlinePrice = parseFloat(myUser.gst) + parseFloat(myUser.subAmount) - parseFloat(e.target.value)
    }

    const editPrice = async (e) => {
        e.preventDefault()
        const bodydata = {
            moneyType: products.moneyType,
            onlinePrice: products.onlinePrice,
            cashPrice: products.cashPrice,
        }
        try {
            const resonse = await updateData("snookerbooking/editsnooker/" + products._id, bodydata)
            var _data = resonse
            toast.success(_data.data.message)
            setmodal_small(false)
            getAllOrders()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // Delete fuction

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Booking Details" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <div>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                    className="m-2"
                                    color="danger"
                                >
                                    <i className="bx bx-left-arrow-alt"></i> Back
                                </Button>
                            </div>
                        </div>

                        <Col md={8}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Row>
                                            <Col md="12">
                                                <div className="p-2">
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <h5>
                                                                        <b>Booking ID :</b>
                                                                        <span style={{ color: "#000" }}>
                                                                            {" "}
                                                                            {products.bookingNo}
                                                                        </span>
                                                                    </h5>
                                                                    <p>
                                                                        <b>
                                                                            <i className="bx bx-calendar"></i>{" "}
                                                                            {products.bookingDate}
                                                                        </b>
                                                                    </p>


                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className="mb-4"
                                                                    style={{ direction: "rtl" }}
                                                                >
                                                                    <Button onClick={() => { downloadImage() }} className="m-2" color="success">
                                                                        Print Invoice{" "}
                                                                        <i className="bx bx-printer"></i>
                                                                    </Button>
                                                                    <br />
                                                                    <a className="mt-2 text-dark">
                                                                        Payment Method : <b>{products.moneyType == "Card" ? "Online" : products.moneyType}</b>
                                                                    </a>
                                                                    <br />
                                                                    <a className="mt-2 text-dark">
                                                                        Payment Status : <b className="text-success">Success</b>
                                                                    </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div>
                                                        <Card>
                                                            <CardBody>
                                                                <Row>
                                                                    {/* <Col>
                                                                        <div>
                                                                            <img
                                                                                src={img3}
                                                                                style={{ width: "60px" }}
                                                                            />
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col md="3">
                                                                        <div>
                                                                            <h6 style={{ color: "#000" }}>{products.bookedTable}</h6>
                                                                            <a style={{ fontSize: "11px" }}>Snooker Parlour</a>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div>
                                                                            <h6 style={{ color: "#000" }}>From Time & To Time</h6>
                                                                            <a>{products.bookingTime}</a>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <div className="text-center">
                                                                            <h6 style={{ color: "#000" }}>Count</h6>
                                                                            <a >{products.adultsCount}</a>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <div>
                                                                            <h6 style={{ color: "#000" }}>Price</h6>
                                                                            <a> ₹  {products.subAmount}</a>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>

                                                    </div>

                                                    <div className="mt-5">
                                                        <Row>
                                                            <Col md="3">
                                                                {products.moneyType == "Split" ? (
                                                                    <div>
                                                                        <Button onClick={tog_small} size="sm" outline color="primary">Change Price</Button>
                                                                    </div>
                                                                ) : ""}
                                                            </Col>
                                                            <Col md="9">
                                                                <Row>
                                                                    <Col>
                                                                        <div style={{ direction: "rtl" }}>
                                                                            {products.moneyType == "Split" ?
                                                                                <>
                                                                                    <b>: Cash </b>
                                                                                    <br />
                                                                                    <b>: Online </b>
                                                                                    <br />
                                                                                </> :
                                                                                <>
                                                                                    <b>: Subtotal </b>
                                                                                    <br />
                                                                                </>
                                                                            }


                                                                            <b>: Coupon Discount </b>
                                                                            <br />
                                                                            <b>: Tax / VAT </b>
                                                                            <br />
                                                                            <br />
                                                                            <h5 className="mt-2">: Total </h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="text-end">
                                                                            {products.moneyType == "Split" ?
                                                                                <>
                                                                                    <b> ₹ {products.cashPrice == undefined ? 0 : products.cashPrice}</b>
                                                                                    <br />
                                                                                    <b> ₹ {products.onlinePrice == undefined ? 0 : products.onlinePrice}</b>
                                                                                    <br />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <b> ₹ {products.subAmount}</b>
                                                                                    <br />
                                                                                </>}

                                                                            <b> - ₹ {products.couponAmount}</b>
                                                                            <br />
                                                                            <b> ₹ {products.gst}</b>
                                                                            <br />
                                                                            <hr />
                                                                            <h5> ₹ {products.totalPrice}</h5>
                                                                        </div>


                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <div>
                                        <h5> Customer Information</h5>
                                        <div className="mb-4">
                                            <Row>
                                                <Col className="mt-4" md="4">
                                                    <img src={userimg} style={{ width: "90px" }} />
                                                </Col>
                                                <Col md="8" className="mt-4 pt-3">
                                                    <p>
                                                        <b>Name</b>: {products.customerName}
                                                    </p>
                                                    <p>
                                                        <b>Phone</b>: {products.customerPhone}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                    size="sm"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Change Amount
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                editPrice(e)
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Cash Amount <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Amount"
                                            required
                                            name="cashPrice"
                                            value={products.cashPrice}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Online Amount <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Amount"
                                            required
                                            disabled
                                            name="onlinePrice"
                                            value={products.onlinePrice}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default OrderDetails
