import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
} from "reactstrap"
import classnames from "classnames"
import { Link, useHistory } from "react-router-dom"

// import classNames from "classnames"

//import Charts
// import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
// import WelcomeComp from "./WelcomeComp"
// import MonthlyEarning from "./MonthlyEarning"
// import SocialSource from "./SocialSource"
// import ActivityComp from "./ActivityComp"
// import TopCities from "./TopCities"
// import LatestTranaction from "./LatestTranaction"
// import ReactApexChart from "react-apexcharts"
// import Chart from 'react-apexcharts'
import toast, { Toaster } from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { addData } from "Servicescalls"
import ReactPaginate from "react-paginate"
import { imgUrl } from "Baseurls"
import spinner from "../../assets/images/letast/Spinner.gif"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Dashboard = props => {
    const history = useHistory()
    const [modal, setmodal] = useState(false)
    const [modalspin, setmodalspin] = useState(true)
    const [subscribemodal, setSubscribemodal] = useState(false)

    const { chartsData } = useSelector(state => ({
        chartsData: state.Dashboard.chartsData,
    }))
    const [activeTab1, setactiveTab1] = useState("1")

    console.log(activeTab1)
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])
    const [graph, setgraph] = useState([])
    const [graph1, setgraph1] = useState([])
    const [graph04, setgraph04] = useState([])
    const [customers, setcustomers] = useState([])
    const [cafe, setcafe] = useState([])
    const [allbooking, setallbooking] = useState([])
    const [swimming, setswimming] = useState([])
    const [snooker, setsnooker] = useState([])
    const [swimGraph, setswimGraph] = useState([])
    const [snookerGraph, setsnookerGraph] = useState([])
    const [cafeGraph, setcafeGraph] = useState([])
    const [emergencys, setEmergencys] = useState([])
    const [allbookinggraph, setallbookinggraph] = useState([])
    const [reportslist, setreportslist] = useState([])
    const [totinc, settotinc] = useState([])
    const [plannames, setplannames] = useState('')
    const uniqueArray = Array.from(new Set(plannames));
    console.log(uniqueArray)

    const getAlldata = async () => {
        const resonse = await addData("dashboard/socialmediadashboard")
        var _data = resonse
        setform(_data.data.customerCount)
        setform1(_data.data.socialMediaCount)
        setgraph(_data.data.cutomerGraph)
        setgraph1(_data.data.postsGraph)
        setgraph04(_data.data.visitorsGraph)
        setcustomers(_data.data.latestCoustomers)
        setmodalspin(false)
    }

    const getBookingdata = async () => {
        const resonse = await addData("dashboard/bookingdashboard")
        var _data = resonse
        setcafe(_data.data.cafe)
        setallbooking(_data.data.allBookings)
        setswimming(_data.data.swimming)
        setsnooker(_data.data.snooker)
        setswimGraph(_data.data.swimmingBarGraph)
        setsnookerGraph(_data.data.snookerBarGraph)
        setcafeGraph(_data.data.cafeBarGraph)
        setallbookinggraph(_data.data.graph)
        setmodalspin(false)
    }

    const getReportData = async () => {
        const resonse = await addData("finance/detailedreport")
        var _data = resonse
        setreportslist(_data.data.incomes)
        settotinc(_data.data.totalIncomes)
    }

    const getEmergencys = async () => {
        const resonse = await addData("plan/getall")
        var data = resonse.data.planResult
        setEmergencys(data)

        if (data.some((item) => item.status === false)) {
            const planWithTrueStatus = data.find((item) => item.status === false);

            if (planWithTrueStatus) {
                const planName = planWithTrueStatus.plan;
                console.log(planName)
                setplannames((prevPlanNames) => [...prevPlanNames, planName])
                setSubscribemodal(true);
            }
        }

    }

    const hiddenIds = ["Cafe"];
    const filteredData = emergencys.filter(
        (item) => !hiddenIds.includes(item.plan)
    );

    useEffect(() => {
        getAlldata()
        getBookingdata()
        getEmergencys()
        getReportData()
    }, [])


    const [periodData, setPeriodData] = useState([])
    const [periodType, setPeriodType] = useState("yearly")

    useEffect(() => {
        setPeriodData(chartsData)
    }, [chartsData])

    const onChangeChartPeriod = pType => {
        setPeriodType(pType)
        dispatch(onGetChartsData(pType))
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(onGetChartsData("yearly"))
    }, [dispatch])


    const [formss, setformss] = useState([])

    const handlechangesss = (e) => {
        const myForms = { ...formss }
        myForms[e.target.name] = e.target.value;
        setformss(myForms)
    }

    const redirectuser = (data) => {
        sessionStorage.setItem('userid', data._id)
        history.push('/viewuser')
    }

    const Authdetails = localStorage.getItem("authUser")
    const Auth = JSON.parse(Authdetails)
    const access = Auth?.userData?.permissions[0]


    // Reports of administration

    useEffect(() => {
        getReportData1()
        getReportData12()
        getReportData13()
        getReportData14()
        getReportData15()
    }, [])

    const [swimmingrep, setswimmingrep] = useState([])
    const [swimmingrep1, setswimmingrep1] = useState([])
    const [snookerrep, setsnookerrep] = useState([])
    const [snookerrep1, setsnookerrep1] = useState([])
    const [caferep, setcaferep] = useState([])
    const [caferep1, setcaferep1] = useState([])
    const [accessoriesin, setAccessoriesin] = useState([])
    const [accessoriesin1, setAccessoriesin1] = useState([])
    const [overallrep, setoverallrep] = useState([])
    const [overallrep1, setoverallrep1] = useState([])

    const getReportData1 = async () => {
        const resonse = await addData("dailyreport/swimmingincomereport")
        var _data = resonse
        setswimmingrep(_data.data.swimmingReport)
        setswimmingrep1(_data.data.total)
        setDates([]);
    }
    const getReportData12 = async () => {
        const resonse = await addData("dailyreport/snookerincomereport")
        var _data = resonse
        setsnookerrep(_data.data.snookerReports)
        setsnookerrep1(_data.data.total)
        setDates12([]);
    }
    const getReportData13 = async () => {
        const resonse = await addData("dailyreport/cafeincomereport")
        var _data = resonse
        setcaferep(_data.data.cafeDailyReports)
        setcaferep1(_data.data.total)
        setDates13([]);
    }
    const getReportData14 = async () => {
        const resonse = await addData("dailyreport/swimmingaccessincomereport")
        var _data = resonse
        setAccessoriesin(_data.data.swimmingAccessIncome)
        setAccessoriesin1(_data.data.total)
        setDates14([]);
    }
    const getReportData15 = async () => {
        const resonse = await addData("dailyreport/overallincomereport")
        var _data = resonse
        setoverallrep(_data.data.overallReport)
        setoverallrep1(_data.data.total)
        setDates14([]);
    }

    const [listPerPage1] = useState(10)
    const [pageNumber1, setPageNumber1] = useState(0)

    const pagesVisited1 = pageNumber1 * listPerPage1
    const lists1 = swimmingrep.slice(pagesVisited1, pagesVisited1 + listPerPage1)
    const pageCount1 = Math.ceil(swimmingrep.length / listPerPage1)
    const changePage1 = ({ selected }) => {
        setPageNumber1(selected)
    }
    const [listPerPage12] = useState(10)
    const [pageNumber12, setPageNumber12] = useState(0)

    const pagesVisited12 = pageNumber12 * listPerPage12
    const lists12 = snookerrep.slice(pagesVisited12, pagesVisited12 + listPerPage12)
    const pageCount12 = Math.ceil(snookerrep.length / listPerPage12)
    const changePage12 = ({ selected }) => {
        setPageNumber12(selected)
    }
    const [listPerPage13] = useState(10)
    const [pageNumber13, setPageNumber13] = useState(0)

    const pagesVisited13 = pageNumber13 * listPerPage13
    const lists13 = caferep.slice(pagesVisited13, pagesVisited13 + listPerPage13)
    const pageCount13 = Math.ceil(caferep.length / listPerPage13)
    const changePage13 = ({ selected }) => {
        setPageNumber13(selected)
    }
    const [listPerPage14] = useState(10)
    const [pageNumber14, setPageNumber14] = useState(0)

    const pagesVisited14 = pageNumber14 * listPerPage14
    const lists14 = accessoriesin.slice(pagesVisited14, pagesVisited14 + listPerPage14)
    const pageCount14 = Math.ceil(accessoriesin.length / listPerPage14)
    const changePage14 = ({ selected }) => {
        setPageNumber14(selected)
    }
    const [listPerPage15] = useState(10)
    const [pageNumber15, setPageNumber15] = useState(0)

    const pagesVisited15 = pageNumber15 * listPerPage15
    const lists15 = overallrep.slice(pagesVisited15, pagesVisited15 + listPerPage15)
    const pageCount15 = Math.ceil(overallrep.length / listPerPage15)
    const changePage15 = ({ selected }) => {
        setPageNumber15(selected)
    }

    // Filter for reports
    const [dates, setDates] = useState([]);
    const [dates12, setDates12] = useState([]);
    const [dates13, setDates13] = useState([]);
    const [dates14, setDates14] = useState([]);
    const [dates15, setDates15] = useState([]);

    const handleDateChange = async (NewDate) => {
        if (NewDate.length === 0) {
           
          } else {
        const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
        // const newDates = [date1, date2];
        const bodydate = {
            dates: [date1, date2]
        }
        setDates(NewDate);
        const response = await addData("dailyreport/swimmingincomereport", bodydate);
        const _data = response.data;
        setswimmingrep(_data.swimmingReport);
        setswimmingrep1(_data.total)
    }
    };

    const handleDateChange12 = async (NewDate12) => {
        if (NewDate12.length === 0) {
           
        } else {
        const date1 = format(new Date(NewDate12[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate12[1]), "yyyy-MM-dd");
        // const newDates12 = [date1, date2];
        const bodydate = {
            dates: [date1, date2]
        }
        setDates12(NewDate12);
        const response = await addData("dailyreport/snookerincomereport", bodydate);
        const _data = response.data;
        setsnookerrep(_data.snookerReports);
        setsnookerrep1(_data.total);
    }
    };

    const handleDateChange13 = async (NewDate13) => {
        if (NewDate13.length === 0) {
           
        } else {
        const date1 = format(new Date(NewDate13[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate13[1]), "yyyy-MM-dd");
        // const newDates13 = [date1, date2];
        const bodydate = {
            dates: [date1, date2]
        }
        setDates13(NewDate13);
        const response = await addData("dailyreport/cafeincomereport", bodydate);
        const _data = response.data;
        setcaferep(_data.cafeDailyReports);
        setcaferep1(_data.total);
    }
    };
    const handleDateChange14 = async (NewDate13) => {
        if (NewDate13.length === 0) {
           
        } else {
        const date1 = format(new Date(NewDate13[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate13[1]), "yyyy-MM-dd");
        // const newDates13 = [date1, date2];
        const bodydate = {
            dates: [date1, date2]
        }
        setDates14(NewDate13);
        const response = await addData("dailyreport/swimmingaccessincomereport", bodydate);
        const _data = response.data;
        setAccessoriesin(_data.swimmingAccessIncome);
        setAccessoriesin1(_data.total);
    }
    };
    const handleDateChange15 = async (NewDate13) => {
        if (NewDate13.length === 0) {
           
        } else {
        const date1 = format(new Date(NewDate13[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate13[1]), "yyyy-MM-dd");
        // const newDates13 = [date1, date2];
        const bodydate = {
            dates: [date1, date2]
        }
        setDates14(NewDate13);
        const response = await addData("dailyreport/overallincomereport", bodydate);
        const _data = response.data;
        setoverallrep(_data.overallReport);
        setoverallrep1(_data.total);
    }
    };

    const toggle1 = (tab) => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab)
            getBookings(tab)
            setDates([]);
            setDates12([]);
            setDates13([]);
            setDates14([]);
            setDates15([]);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
            <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={props.t("Belwails")}
                            breadcrumbItem={props.t("Overall Report")}
                        />


                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="8">
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "10px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("1")
                                                    }}
                                                >
                                                    Swimming Pool
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "10px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("2")
                                                    }}
                                                >
                                                    Snooker Parlour
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "10px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "3",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("3")
                                                    }}
                                                >
                                                    Cafe
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "10px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "4",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("4")
                                                    }}
                                                >
                                                    Accessories
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "10px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "5",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("5")
                                                    }}
                                                >
                                                    Overall Report
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                   
                                </Row>

                                <Row>
                                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col md="12" style={{ float: "right" }}>
                                                    
                              <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={getReportData1} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div>
                                                    <div style={{ float: "right" }}>
                                                        <Flatpickr

                                                            placeholder="Select date"
                                                            className="form-control"
                                                            name="dates"
                                                            value={dates}
                                                            onChange={(e) => { handleDateChange(e) }}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="table-responsive">
                                                <Table hover className="table table-bordered mb-4 mt-2">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Item</th>
                                                            <th>Selling Price</th>
                                                            <th>Cost</th>
                                                            <th>Qty</th>
                                                            <th>Total Selling Price</th>
                                                            <th>Total Cost</th>
                                                            <th>Net Profit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists1.length == 0 ? (
                                                            <tr className="text-center" >
                                                                <th colSpan={11}>No data...</th>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {lists1.map((data, key) => (
                                                                    <tr className="text-center" key={key}>
                                                                        <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                                                    
                                                                        <td>{data.date}</td>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.items}</td>
                                                                        <td>{data.sellingPrice}</td>
                                                                        <td>{data.purchasePrice}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{data.totalSelling}</td>
                                                                        <td>{data.totalPurchase}</td>
                                                                        <td>{data.profit}</td>
                                                                       

                                                                    </tr>
                                                                ))}
                                                                <tr  className="text-center" >
                                                                    <td colSpan={6}>
                                                                        <b>Total</b>
                                                                    </td>
                                                                    <td>{swimmingrep1.quantity}</td>
                                                                    <td>{swimmingrep1.selling}</td>
                                                                    <td>{swimmingrep1.purchase}</td>
                                                                    <td>{swimmingrep1.profit}</td>
                                                                       
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount1}
                                                        onPageChange={changePage1}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists1.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col md="12" style={{ float: "right" }}>
                                                    <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={getReportData12} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div>
                                                    <div style={{ float: "right" }}>
                                                        <Flatpickr
                                                            placeholder="Select date"
                                                            className="form-control"
                                                            name="dates12"
                                                            value={dates12}
                                                            onChange={(e) => { handleDateChange12(e) }}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="table-responsive">
                                                <Table hover className="table table-bordered mb-4 mt-2">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Item</th>
                                                            <th>Selling Price</th>
                                                            <th>Cost</th>
                                                            <th>Qty</th>
                                                            <th>Total Selling Price</th>
                                                            <th>Total Cost</th>
                                                            <th>Net Profit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists12.length == 0 ? (
                                                            <tr className="text-center" >
                                                                <th colSpan={11}>No data...</th>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {lists12.map((data, key) => (
                                                                    <tr className="text-center" key={key}>
                                                                        <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                                                    
                                                                        <td>{data.date}</td>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.items}</td>
                                                                        <td>{data.sellingPrice}</td>
                                                                        <td>{data.purchasePrice}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{data.totalSelling}</td>
                                                                        <td>{data.totalPurchase}</td>
                                                                        <td>{data.profit}</td>
                                                                       

                                                                    </tr>
                                                                ))}
                                                                 <tr  className="text-center" >
                                                                    <td colSpan={6}>
                                                                        <b>Total</b>
                                                                    </td>
                                                                    <td>{snookerrep1.quantity}</td>
                                                                    <td>{snookerrep1.selling}</td>
                                                                    <td>{snookerrep1.purchase}</td>
                                                                    <td>{snookerrep1.profit}</td>
                                                                       
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount12}
                                                        onPageChange={changePage12}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists12.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col md="12" style={{ float: "right" }}>
                                                    <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={getReportData13} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div>
                                                    <div style={{ float: "right" }}>
                                                        <Flatpickr
                                                            placeholder="Select date"
                                                            className="form-control"
                                                            name="dates13"
                                                            value={dates13}
                                                            onChange={(e) => { handleDateChange13(e) }}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="table-responsive">
                                                <Table hover className="table table-bordered mb-4 mt-2">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Item</th>
                                                            <th>Selling Price</th>
                                                            <th>Cost</th>
                                                            <th>Qty</th>
                                                            <th>Total Selling Price</th>
                                                            <th>Total Cost</th>
                                                            <th>Net Profit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists13.length == 0 ? (
                                                            <tr className="text-center" >
                                                                <th colSpan={11}>No data...</th>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {lists13.map((data, key) => (
                                                                    <tr className="text-center" key={key}>
                                                                        <th>{(pageNumber1 - 1) * 10 + key + 11}</th>
                                                                    
                                                                        <td>{data.date}</td>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.items}</td>
                                                                        <td>{data.sellingPrice}</td>
                                                                        <td>{data.purchasePrice}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{data.totalSelling}</td>
                                                                        <td>{data.totalPurchase}</td>
                                                                        <td>{data.profit}</td>
                                                                       

                                                                    </tr>
                                                                ))}
                                                                 <tr  className="text-center" >
                                                                    <td colSpan={6}>
                                                                        <b>Total</b>
                                                                    </td>
                                                                    <td>{caferep1.quantity}</td>
                                                                    <td>{caferep1.selling}</td>
                                                                    <td>{caferep1.purchase}</td>
                                                                    <td>{caferep1.profit}</td>
                                                                       
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount13}
                                                        onPageChange={changePage13}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists13.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col md="12" style={{ float: "right" }}>
                                                    <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={getReportData14} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div>
                                                    <div style={{ float: "right" }}>
                                                        <Flatpickr
                                                            placeholder="Select date"
                                                            className="form-control"
                                                            name="dates14"
                                                            value={dates14}
                                                            onChange={(e) => { handleDateChange14(e) }}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="table-responsive">
                                                <Table hover className="table table-bordered mb-4 mt-2">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Item</th>
                                                            <th>Selling Price</th>
                                                            <th>Cost</th>
                                                            <th>Qty</th>
                                                            <th>Total Selling Price</th>
                                                            <th>Total Cost</th>
                                                            <th>Net Profit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists14.length == 0 ? (
                                                            <tr className="text-center" >
                                                                <th colSpan={11}>No data...</th>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {lists14.map((data, key) => (
                                                                    <tr className="text-center" key={key}>
                                                                        <th>{(pageNumber14 - 1) * 10 + key + 11}</th>
                                                                    
                                                                        <td>{data.date}</td>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.items}</td>
                                                                        <td>{data.sellingPrice}</td>
                                                                        <td>{data.purchasePrice}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{data.totalSelling}</td>
                                                                        <td>{data.totalPurchase}</td>
                                                                        <td>{data.profit}</td>
                                                                       

                                                                    </tr>
                                                                ))}
                                                                <tr  className="text-center" >
                                                                    <td colSpan={6}>
                                                                        <b>Total</b>
                                                                    </td>
                                                                    <td>{accessoriesin1.quantity}</td>
                                                                    <td>{accessoriesin1.selling}</td>
                                                                    <td>{accessoriesin1.purchase}</td>
                                                                    <td>{accessoriesin1.profit}</td>
                                                                       
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount14}
                                                        onPageChange={changePage14}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists14.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col md="12" style={{ float: "right" }}>
                                                    <div style={{ float: "right" }}>
                                <Button style={{ height: "33px" }} onClick={getReportData15} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                              </div>
                                                    <div style={{ float: "right" }}>
                                                        <Flatpickr
                                                            placeholder="Select date"
                                                            className="form-control"
                                                            name="dates15"
                                                            value={dates15}
                                                            onChange={(e) => { handleDateChange15(e) }}
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d M, Y",
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="table-responsive">
                                                <Table hover className="table table-bordered mb-4 mt-2">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th>SlNo</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Item</th>
                                                            <th>Selling Price</th>
                                                            <th>Cost</th>
                                                            <th>Qty</th>
                                                            <th>Total Selling Price</th>
                                                            <th>Total Cost</th>
                                                            <th>Net Profit</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists15.length == 0 ? (
                                                            <tr className="text-center" >
                                                                <th colSpan={11}>No data...</th>
                                                            </tr>
                                                        ) : (
                                                            <>
                                                                {lists15.map((data, key) => (
                                                                    <tr className="text-center" key={key}>
                                                                        <th>{(pageNumber15 - 1) * 10 + key + 11}</th>
                                                                    
                                                                        <td>{data.date}</td>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.items}</td>
                                                                        <td>{data.sellingPrice}</td>
                                                                        <td>{data.purchasePrice}</td>
                                                                        <td>{data.quantity}</td>
                                                                        <td>{data.totalSelling}</td>
                                                                        <td>{data.totalPurchase}</td>
                                                                        <td>{data.profit}</td>
                                                                       

                                                                    </tr>
                                                                ))}
                                                               <tr  className="text-center" >
                                                                    <td colSpan={6}>
                                                                        <b>Total</b>
                                                                    </td>
                                                                    <td>{overallrep1.quantity}</td>
                                                                    <td>{overallrep1.selling}</td>
                                                                    <td>{overallrep1.purchase}</td>
                                                                    <td>{overallrep1.profit}</td>
                                                                       
                                                                </tr>
                                                            </>
                                                        )}

                                                    </tbody>
                                                </Table>
                                                <div
                                                    className="d-flex mt-3 mb-1"
                                                    style={{ float: "right" }}
                                                >
                                                    <ReactPaginate
                                                        previousLabel={"Previous"}
                                                        nextLabel={"Next"}
                                                        pageCount={pageCount15}
                                                        onPageChange={changePage15}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"previousBttn"}
                                                        nextLinkClassName={"nextBttn"}
                                                        disabledClassName={"disabled"}
                                                        activeClassName={"active"}
                                                        total={lists15.length}
                                                    />
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </Row>
                            </CardBody>
                        </Card>

                    </Container>
            </div>



            <Toaster />
        </React.Fragment>
    )
}

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
