import React, { useState, useEffect, useRef } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import logoLightPng from "../../assets/images/raglogo.png"
import { useReactToPrint } from "react-to-print"
import '../../Printerstyle.css'

const Thankyou = () => {
  const history = useHistory()
  const componentRef = useRef()

  const [invoice, setinvoice] = useState([])
  const [products, setproducts] = useState([])
  const [productsacc, setproductsacc] = useState([])
  const [phone, setphone] = useState([])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const bookingidtype = sessionStorage.getItem("bookingtypes")

  const getAllOrders = async () => {
    const bodydata = {
      id: sessionStorage.getItem("bookingsids"),
    }
    let resonse;
    if (bookingidtype == "Snookerbooking") {
      resonse = await addData("snookerbooking/getsnookerbookingbyid", bodydata)
    } else {
      resonse = await addData("swimmingbooking/getswimmingbookingbyid", bodydata)
    }
    var _data = resonse
    setproducts(bookingidtype == "Snookerbooking" ? _data.data.snookerBooking : _data.data.swimmingBooking)
    setproductsacc(bookingidtype == "Snookerbooking" ? _data?.data?.snookerBooking : _data?.data?.swimmingBooking?.swimmingAccessArray)
    // setTimeout(() => {
    //   handlePrint()
    // }, 1000);
  }

  useEffect(() => {
    getAllOrders()
  }, [])


  // Delete fuction

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Booking Invoice" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Button
                      onClick={handlePrint}
                      className="m-2"
                      color="success"
                    >
                      <i className="bx bx-printer"></i> Proceed If thermal
                      printer is ready
                    </Button>
                    <Button
                      onClick={() => { history.goBack() }}
                      className="m-2" color="danger">
                      <i className="bx bx-left-arrow-alt"></i> Back
                    </Button>
                    <hr />
                  </div>
                  <Row >
                    <Col md="4"></Col>
                    <Col md="4">
                      <div className="p-2">
                        <div ref={componentRef}>
                          <div className="modal-body">
                            {/* <center>
                            <input type="button" className="btn btn-primary non-printable" 
                            // onclick="printDiv('printableArea')"
                             defaultValue="Proceed  If thermal printer is ready." />
                            <a href="https://grocerywala.co.in/admin/pos" className="btn btn-danger non-printable">Back</a>
                          </center> */}
                            {/* <hr className="non-printable" /> */}
                            <div id="printableArea">
                              <div className="initial-38-1">
                                {/* <div className="pt-3">
                                  <img src={logoLightPng} className="initial-38-2 mb-2" alt="" />
                                </div> */}
                                <div className="text-center">
                                  {/* <h2 className="initial-38-3">Grocerywala</h2> */}
                                  <h5 style={{fontSize:"12px"}} className="text-break initial-38-4">
                                    Name : {products.customerName}
                                  </h5>
                                  <h5 style={{fontSize:"12px"}} className="initial-38-4 initial-38-3">
                                    Phone : {products.customerPhone}
                                  </h5>
                                </div>
                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                <div className="row">
                                  <div className="col-6">
                                    <h5 style={{ fontSize: "12px", fontWeight: "800" }}>Booking ID 
                                      <span style={{marginBottom:"0px"}} className="font-light"> {products.bookingNo}</span>
                                    </h5>
                                  </div>
                                  <div className="col-6">
                                    <h5 style={{ fontSize: "12px", fontWeight: "800" }}>
                                      <span className="font-light">
                                        {products.bookingDate}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="col-12">
                                  </div>
                                </div>
                                <h5 style={{ margin: "0px" }} className="text-uppercase" />
                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                <table style={{ fontSize: "10px", marginBottom: "7px", border: "2px solid black" }} className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th  style={{padding:"5px"}} className="initial-38-6 border-top-0 border-bottom-0">SNo</th>
                                      <th  style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Description</th>
                                      <th  style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr >
                                      <td  style={{padding:"5px"}}>1</td>
                                      <td  style={{padding:"5px"}}>
                                        <div>
                                          {bookingidtype == "Snookerbooking" ? (
                                            <b>{products.bookedTable}</b>
                                          ) : (
                                            <b>{products.bookingSlot == "A" ? "Day" : "Night"} {products.adultsCount}Persons</b>
                                          )}
                                        </div>
                                      </td>
                                      <td  style={{padding:"5px"}}> ₹ {products.subAmount}</td>
                                    </tr>
                                    {bookingidtype == "Snookerbooking" ? (
                                      ""
                                    ) : (
                                      <>
                                        {productsacc.map((data, key) => (
                                          <tr key={key}>
                                            <td  style={{padding:"5px"}}>
                                              {key + 2}
                                            </td >
                                            <td  style={{padding:"5px"}}>
                                              <b>{data.accessoryName}</b>
                                            </td>
                                            <td  style={{padding:"5px"}}>
                                              ₹ {data.amount}
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    )}
                                  </tbody>
                                </table>

                                <div className="px-3">
                                  <dl style={{ margin: "0px" }} className="row text-right justify-content-center">
                                    <dt style={{ margin: "0px" }} className="col-6">Subtotal :</dt>
                                    <dd style={{ margin: "0px" }} className="col-6">
                                      {bookingidtype == "Snookerbooking" ?
                                        <>
                                          ₹ {products.subAmount}
                                        </>
                                        :
                                        <>
                                          ₹ {parseFloat(products.subAmount) + parseFloat(products.swimmingAccessPrice)}
                                        </>
                                      }

                                      {/* ₹ {invoice.subAmount} */}

                                    </dd>
                                    <dt style={{ margin: "0px" }} className="col-6">Discount :</dt>
                                    <dd style={{ margin: "0px" }} className="col-6">₹ {products.couponAmount}</dd>
                                    <dt style={{ margin: "0px" }} className="col-6">GST :</dt>
                                    <dd style={{ margin: "0px" }} className="col-6">
                                      ₹{products.gst}
                                      <hr style={{marginBottom:"5px", marginTop:"5px"}} />
                                    </dd>
                                    <dt className="col-6 font-20px">Total:</dt>
                                    <dd className="col-6 font-20px">₹ {products.totalPrice}</dd>
                                    <dt className="col-6 font-20px">Money Type:</dt>
                                    <dd className="col-6 font-20px"> {products.moneyType}</dd>
                                  </dl>
                                  <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                  <h5 style={{ fontWeight: "800" }} className="text-center">
                                    <span className="d-block">"""THANK YOU"""</span>
                                  </h5>
                                  <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                  <span className="d-block text-center">Copyright @ Belwails</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div style={{ width: "404px" }} className="invoicefont p-1">
                        <div className="text-center text-dark borderbottom">
                          <img src={logoLightPng} />
                          <h5 className="mt-4">
                            Name : {products.customerName}
                          </h5>
                          <h5 className="mb-3">Phone : {products.customerPhone}</h5>
                        </div>

                        <div className="borderbottom">
                          <Row>
                            <div className="col-7" >
                              <p>
                                <b>Booking ID :</b>
                                <span style={{ color: "#000" }}>
                                  {" "}
                                  {products.bookingNo}
                                </span>
                              </p>
                            </div>
                            <div className="col-5">
                              <p style={{ float: "right" }}>
                                <b>Date:</b>
                                <span style={{ color: "#000" }}>
                                  {" "}
                                  {products.bookingDate}
                                </span>
                              </p>
                            </div>
                          </Row>

                        </div>

                        <div>
                          <Table className="mt-2" bordered responsive>
                            <thead>
                              <tr className="text-center">
                                <th>S No</th>
                                <th>Description</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr >
                                <td>1</td>
                                <td>
                                  <div>
                                    {bookingidtype == "Snookerbooking" ? (
                                      <b>{products.bookedTable}</b>
                                    ) : (
                                      <b>{products.bookingSlot == "A" ? "Day" : "Night"} {products.adultsCount}Persons</b>
                                    )}
                                  </div>
                                </td>
                                <td> ₹ {products.subAmount}</td>
                              </tr>
                              {bookingidtype == "Snookerbooking" ? (
                                ""
                              ) : (
                                <>
                                  {productsacc.map((data, key) => (
                                    <tr key={key}>
                                      <td>
                                        {key + 2}
                                      </td>
                                      <td>
                                        <b>{data.accessoryName}</b>
                                      </td>
                                      <td>
                                        ₹ {data.amount}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>

                        <div className="mt-2 borderbottom">
                          <Row>
                            <Col md="3"></Col>
                            <Col md="9">
                              <Row>
                                <Col>
                                  <div style={{ direction: "rtl" }}>
                                    <b>: Subtotal </b>
                                    <br />

                                    <b>: Discount </b>
                                    <br />
                                    <b>: GST </b>
                                    <br />
                                    <br />
                                    <h5 className="mt-2">: Total </h5>
                                    <br />
                                    <b>: Paid By </b>
                                  </div>
                                </Col>
                                <Col>
                                  <div className="text-end">
                                   
                                    {bookingidtype == "Snookerbooking" ?
                                      <>
                                        <b> ₹ {products.subAmount}</b>
                                        <br />
                                      </>
                                      :
                                      <>
                                        <b> ₹ {parseFloat(products.subAmount) + parseFloat(products.swimmingAccessPrice)}</b>
                                        <br />
                                      </>
                                    }

                                    <b> ₹ {products.couponAmount}</b>
                                    <br />
                                    <b> ₹ {products.gst}</b>
                                    <br />
                                    <hr />
                                    <h5> ₹ {products.totalPrice}</h5>
                                    <hr />
                                    <b> {products.moneyType}</b>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>


                        <div className="text-center borderbottom">
                          <h5>"""Thank you and Visit Again"""</h5>
                        </div>

                      </div> */}
                        </div>
                      </div>
                    </Col>
                    <Col md="4"></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Thankyou
