import PropTypes from "prop-types";
import React, {useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import logo from "assets/images/raglogo.png";
import { appaddData } from "Servicescalls"


const Privacy = () => {

    const [form, setform] = useState([])

    console.log(form)
  
    const getTerms = async () => {
      const resonse = await appaddData("companypolicy/getcompanypolicies")
      var _data = resonse
      setform(_data?.data?.companyPolicies[0]?.privacyPolicy)
    }
  
    useEffect(() => {
      getTerms()
    }, [])


  return (
    <React.Fragment >
        <div className="bg-white">
    <Card style={{overflow:"hidden"}}>
    <div className="pt-4">
        <Row>
            <Col className="text-center" md="12">
                <img src={logo} />
            </Col>
        </Row>
        <Container>
          <Row className="mt-5">
            <Col md={12}>
                <div>
                    {/* <h5>Privacy Policy</h5> */}
                    <div className="pt-2"
                    dangerouslySetInnerHTML={{
                        __html: form,
                      }}
                    ></div>
                </div>
            
              <div className="mt-5 mb-4 text-center">
                <p>
                  © {new Date().getFullYear()} Belwails. Design & Develop by 
                  <a href="https://www.digitalraiz.com/"> Digitalraiz Creative Solutions Pvt Ltd.</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Card>
    </div>
    </React.Fragment>
  )
}

export default Privacy;

