import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import avatar from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import swim from "../../assets/images/crypto/blog/swim.jpg"

const Posts = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [post, setposts] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ title: "", description: "" })
  const [form1, setform1] = useState([])
  console.log(form1)
  const [form2, setform2] = useState([])
  const [Files1, setFiles1] = useState({ file: "" })
  const [Files, setFiles] = useState({ file: "" })

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])

  const changeHandler = (e) => {
    setFiles(e.target.files)
  }
  const changeHandler1 = (e) => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllPosts = async () => {
    const resonse = await addData("socialmedia/getallposts")
    var _data = resonse
    setposts(_data.data.userposts)
    setFilter(_data.data.userposts)
  }

  useEffect(() => {
    getAllPosts()
  }, [])

  const redirectuser=(data)=>{
    sessionStorage.setItem('userid', data.customerId)
    history.push('/viewuser')
  }
  const redirectpost=(data)=>{
    sessionStorage.setItem('postid', data._id)
    history.push('/postsdetails')
  }

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Title",
      selector: row => row.description,
      sortable: true,
    },
    {
      name: "Username",
      selector: row => <a className="text-primary" onClick={()=>{redirectuser(row)}} >{row.customerName}</a>,
      sortable: true,
    },
    {
      name: "Total views",
      selector: row => row.views,
      sortable: true,
    },
    {
      name: "Total Like",
      selector: row => row.likes,
      sortable: true,
    },
    {
      name: "Total comment",
      selector: row => row.comments,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.isBlocked == true?"Blocked":"Active",
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: row => row.date + row.time,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
        {/* <Link to="/"> */}
          <Button
            onClick={() => {
              redirectpost(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="fa fa-eye"></i>
          </Button>
          {/* </Link> */}
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = post.filter((data) => {
        return (
            (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
            (data.description?.toLowerCase?.().includes(trimmedSearch.toLowerCase()))
        );
    });
    setFilter(result);
}, [search]);


  // Add fuction
  const addPosts = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("title", form.title)
    dataArray.append("description", form.description)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("file", Files[i])
    }
    try {
      const resonse = await addData("post/add", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setmodal_small1(false)
      setFiles({ file: "" })
      setform({ title: "", description: "" })
      getAllPosts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editbenners = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("title", form1.title)
    dataArray.append("description", form1.description)
    dataArray.append("status", form1.status)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("file", Files1[i])
    }
    try {
      const resonse = await updateData("post/edit/" + form1._id, dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setFiles1({ file: "" })
      setform1({ title: "", description: "" })
      setmodal_small(false)
      getAllPosts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deletebenners = async data => {
    try {
      const resonse = await updateData(
        "socialmedia/deletepost/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllPosts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [imgview, setimgview] = useState(false)
  const [imgview1, setimgview1] = useState([])


  function openmodels(data) {
    setimgview(!imgview)
    setimgview1(data)
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Post" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Posts </CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div style={{ float: "right" }}>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Row className="mb-4">
              <Col md="4">
                <Input type="text" className="rounded-pill" placeholder="Search..." />
              </Col>
              <Col className="text-end" md="8">
                <Button
                  onClick={() => {
                    setmodal_small1(true)
                  }}
                  color="primary">
                  Add Posts <i className="bx bx-plus-circle" />
                </Button>
              </Col>
            </Row> */}

            {/* {post.map((row, key) => (
              <Col key={key} md={4}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="avatar-md">
                        <span className="">
                          <img src={avatar} className="rounded-circle" alt="" width="50px" />
                        </span>
                      </div>
                      <div className="flex-grow-1 overflow-hidden mt-2">
                        <h5 style={{ margin: "0px" }} className="text-truncate font-size-15">
                          <Link
                            className="text-dark"
                          >

                            Sharath
                          </Link>
                        </h5>
                        <p className="text-muted mb-4">It will be as simple...</p>
                      </div>
                      <div className="dropright">
                        <button className="btn dropdown-toggle dropsty" type="button" data-toggle="dropdown" aria-expanded="false">
                          <i style={{ fontSize: "18px" }} className="fas fa-ellipsis-h text-muted" />
                        </button>
                        <div style={{marginLeft:"-100px"}} className="dropdown-menu">
                          <a className="dropdown-item" type="button"
                            onClick={() => {
                              getpopup(row)
                            }}
                          >Edit</a>
                          <a className="dropdown-item text-danger" type="button"
                            onClick={() => {
                              manageDelete(row)
                            }}
                          >Delete</a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={swim} style={{ width: "100%", height: "250px" }} />
                    </div>
                    <Row className="mt-3">
                      <Col>
                        <div className="avatar-group">
                          <div>
                            <i style={{ fontSize: "15px" }} className="fas fa-heart text-danger me-1" />  50
                            <i style={{ fontSize: "15px" }} className="far fa-comment-dots me-1 ml-4" />  13
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="text-center">
                          <div>
                            <i style={{ fontSize: "15px" }} className="bx bx-calendar me-1" />  13 Oct, 2023
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>
            ))} */}

          </Row>
        </Container>

        <Modal
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              
              Add Posts
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={(e) => { addPosts(e) }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Title <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Name"
                  required
                  name="title"
                  value={form.title}
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  File <span className="text-danger">*</span>
                </Label>
                <Input
                  type="file"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Image"
                  required
                  name="file"
                  value={Files.file}
                  onChange={changeHandler}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Description <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Description"
                  required
                  name="description"
                  value={form.description}
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>


              <div className="mt-4" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Post
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editbenners(e)
              }}
            >
              <Row>

                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>

                <Col md="12">
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <Label for="basicpill-firstname-input1">Image</Label>
                      </Col>
                      <Col className="text-end">
                        <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a>
                      </Col>
                    </Row>

                    <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="file"
                      // value={form1.bannerImage}
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      name="description"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">In Active</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="sm"
          isOpen={imgview}
          toggle={() => {
            setimgview()
          }}
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setimgview(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <iframe src={imgUrl + imgview1.media} />
            </Row>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Posts
