import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import burger from "../../assets/images/brands/burger.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import Select from "react-select"

const Stocks = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [show, setshow] = useState(false)
    const [prodects, setProducts] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState({ categoryId: "", name: "", description: "", amount: "", tax: "" })
    const [form1, setform1] = useState([])
    console.log(form1)
    const [form2, setform2] = useState([])
    const [Files1, setFiles1] = useState({ image: "" })
    const [Files, setFiles] = useState({ image: "" })

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState([])

    const changeHandler = e => {
        setFiles(e.target.files)
    }
    const changeHandler1 = e => {
        setFiles1(e.target.files)
    }

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    const handleChange = (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    // const handleChange12 = (e,key) => {
    //     const newUnitPrices = {...form};
    //     newUnitPrices[key].unitPrice = e.target.value;
    //     setform(newUnitPrices);
    //     console.log(newUnitPrices)

    //     // let myUser = { ...form }
    //     // myUser[e.target.name] = e.target.value
    //     // setform(myUser)
    // }

    const handleChange12 = (e, key) => {
        const newForm = { ...form };
        const selectedData = selectedGroup2[key];
        newForm[selectedData._id] = { ...newForm[selectedData._id] };
        newForm[selectedData._id].unitPrice = e.target.value;
        setform(newForm);
    }

    // const handleChanges = (e, key) => {
    //     const updatedSelectedGroup2 = [...selectedGroup2];
    //     updatedSelectedGroup2[key].product = e.target.value;
    //     setselectedGroup2(updatedSelectedGroup2);
    // };

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllStoks = async () => {
        const resonse = await addData("stock/getall")
        var _data = resonse
        setProducts(_data.data.stockResult)
        setFilter(_data.data.stockResult)
    }

    const [productslist, setproductslist] = useState([])

    const getAllProducts = async () => {
        const resonse = await addData("subcategory/getactive")
        var _data = resonse
        setproductslist(_data.data.result)
    }

    const getAllCategories = async () => {
        const resonse = await addData("category/getactive")
        var _data = resonse
        setcategory(_data.data.result)
    }

    const [supply, setsupply] = useState([])
    const getAllCustomers = async () => {
        const resonse = await addData("supplier/getallactive")
        var _data = resonse
        setsupply(_data.data.supplierResult)
    }

    const [selectedGroup, setselectedGroup] = useState("")
    const [selectedGroup3, setselectedGroup3] = useState("")
    const [selectedGroup1, setselectedGroup1] = useState([])
    const [selectedGroup2, setselectedGroup2] = useState([])
    const [products, setproducts] = useState([])
    console.log(selectedGroup2)



    // const productListvar = [
    //     {
    //         id: 1,
    //         img: burger,
    //         name: "Crispy Chicken Burger",
    //         price: "450",
    //         data_attr: 1,
    //         total: 900,
    //     },
    // ];

    useEffect(() => {
        setselectedGroup2(selectedGroup1.map((data) => data.value));
    }, [selectedGroup1]);

    // const [productList, setproductList] = useState(selectedGroup2);
    // console.log(productList)

    function removeCartItem(_id) {
        var filtered = selectedGroup2.filter(function (item) {
            return item._id !== _id;
        });
        setselectedGroup2(filtered);
    }

    function countUP(_id, product) {
        setselectedGroup2(
            selectedGroup2.map(p =>
                p._id === _id ? { ...p, product: parseFloat(product) + 1 } : p
            )
        );
    }

    function countDown(_id, product) {
        setselectedGroup2(
            selectedGroup2.map(p =>
                p._id === _id ? { ...p, product: parseFloat(product) - 1 } : p
            )
        );
    }

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    function handleSelectGroup3(data) {
        setselectedGroup3(data)
    }

    function handleSelectGroup1(selectedGroup1) {
        setselectedGroup1(selectedGroup1)
        // setproducts(prevProducts => {
        //     const existingProductNames = prevProducts.map(value => value.name);
        //     if (existingProductNames.includes(selectedGroup1.name)) {
        //         // toast.error("Product is already added");
        //         return prevProducts;
        //     } else {
        //         return [...prevProducts, selectedGroup1];
        //     }
        // });
    }

    useEffect(() => {
        getAllStoks()
        getAllProducts()
        getAllCategories()
        getAllCustomers()
    }, [])

    const options = supply.map(data => ({
        value: data._id,
        label: data.supplier,
    }))

    const options1 = productslist.map((data) => ({
        value: data,
        label: data.name,
    }))

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Date",
            selector: row => row.purchaseDate,
            sortable: true,
        },
        {
            name: "Supplier",
            selector: row => row.supplier,
            sortable: true,
        },
        {
            name: "Product Name",
            selector: row => row.subCategory,
            sortable: true,
        },
        {
            name: "Image",
            selector: row => (
                <img
                    className="p-2"
                    src={imgUrl + row.image}
                    style={{ width: "100px", height: "50px" }}
                />
            ),
            sortable: true,
        },
        {
            name: "Quantity",
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: "Selling Price",
            selector: row => row.price,
            sortable: true,
        },
        {
            name: "Total Price",
            selector: row => row.toltalPrice,
            sortable: true,
        },
        // {
        //     name: "Status",
        //     selector: row => row.status == true ? "Active" : "Inactive",
        //     sortable: true,
        // },
        {
            name: "Action",
            selector: row => (
                <>
                    <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="success"
                        outline
                    >
                        <i className="bx bx-edit "></i>
                    </Button>
                    <Button
                        onClick={() => {
                            manageDelete(row)
                        }}
                        style={{ padding: "6px", margin: "3px" }}
                        color="danger"
                        outline
                    >
                        <i className="bx bx-trash"></i>
                    </Button>
                </>
            ),
        },
    ]

    const handleChanges = (e, key) => {
        const updatedSelectedGroup2 = [...selectedGroup2];
        updatedSelectedGroup2[key].product = e.target.value;
        setselectedGroup2(updatedSelectedGroup2);
    };
    const [sellingdata, setsellingdata] = useState([])

    const handleChanges123 = (e, key) => {
        const myData = [...sellingdata];
        myData[key] = {
            ...myData[key],
            [e.target.name]: e.target.value
        };
        setsellingdata(myData);
    };



    useEffect(() => {
        const trimmedSearch = search.trim();
        const result = prodects.filter((data) => {
            return (
                (data.supplier && data.supplier.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
                (data.subCategory && data.subCategory.toLowerCase().includes(trimmedSearch.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    const [totalpricedata, settotalpricedata] = useState('')

    const getpopup = data => {
        setselectedGroup3(data.supplierObject)
        settotalpricedata(data.toltalPrice)
        setform1(data)
        tog_small()
    }

    const handleChange121 = (e) => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
        const totalprices = parseFloat(form1.purchaseAmount) * parseFloat(e.target.value)
        settotalpricedata(totalprices)

    }

    const handleChanges1 = (e) => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
        const totalprices = parseFloat(form1.quantity) * parseFloat(myUser.purchaseAmount)
        settotalpricedata(totalprices)

        // const updatedSelectedGroup2 = [...form1];
        // updatedSelectedGroup2[key].product = e.target.value;
        // setform1(updatedSelectedGroup2);
    };

    function countUP1() {
        form1.quantity = parseFloat(form1.quantity) + 1
        const totalprices = parseFloat(form1.quantity) * parseFloat(form1.price)
        settotalpricedata(totalprices)

    }
    function countDown1() {
        form1.quantity = parseFloat(form1.quantity) - 1
        const totalprices = parseFloat(form1.quantity) * parseFloat(form1.price)
        settotalpricedata(totalprices)
    }


    // Add fuction
    const addcategory = async (e) => {
        e.preventDefault()
        const bodydata = {
            supplierObjId: selectedGroup.value,
            supplierObject: selectedGroup,
            purchaseDate: form.purchaseDate || new Date().toISOString().split('T')[0],
            data: selectedGroup2.map((data, key) => ({
                subCategoryId: data._id,
                quantity: data.product,
                tax: data.tax,
                price: sellingdata[key]?.amount,
                // unitPrice: (form[selectedGroup2[key]._id]?.unitPrice || 0),
                // toltalPrice: data.product * (form[selectedGroup2[key]._id]?.unitPrice || 0)

                purchaseAmount: parseFloat(form[data._id]?.unitPrice).toFixed(2) || 0,
                toltalPrice: (parseFloat(data.product * (form[data._id]?.unitPrice) || 0).toFixed(2))

            }))
        }
        try {
            const resonse = await addData("stock/add", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform([])
            getAllStoks()
            setselectedGroup1([])
            setselectedGroup2([])
            setshow(false)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // Edit fuction
    const editCategory = async (e) => {
        e.preventDefault()

        const bodydata = {
            supplierObjId: selectedGroup3.value,
            supplierObject: selectedGroup3,
            purchaseDate: form1.purchaseDate,
            subCategoryId: form1.subCategoryId,
            quantity: form1.quantity,
            tax: form1.tax,
            purchaseAmount: parseFloat(form1.purchaseAmount).toFixed(2),
            price: parseFloat(form1.price).toFixed(2),
            toltalPrice: parseFloat(totalpricedata).toFixed(2)

        }

        try {
            const resonse = await addData(
                "stock/edit/" + form1._id, bodydata
            )
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            // setFiles1({ image: "" })
            setmodal_small(false)
            getAllStoks()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Delete fuction
    const deleteCategory = async data => {
        try {
            const resonse = await deletedData("stock/delete/" + data._id, {})
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllStoks()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = data => {
        const confirmBox = window.confirm("Do you want to Delete?")
        if (confirmBox === true) {
            deleteCategory(data)
        }
    }

    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])

    function openmodels(data) {
        setimgview(!imgview)
        setimgview1(data)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Stock" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    {show == true ? (
                        <div>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Add Stock</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={e => {
                                            addcategory(e)
                                        }}
                                    >
                                        <Row>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Supplier Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        required
                                                        onChange={handleSelectGroup}
                                                        name="supplierObjId"
                                                        options={options}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Purchase date <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        placeholder="Enter Name"
                                                        required
                                                        name="purchaseDate"
                                                        // value={form.purchaseDate}
                                                        defaultValue={new Date().toISOString().split('T')[0]}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        max={new Date().toISOString().split('T')[0]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">
                                                        Select product <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        required
                                                        isMulti
                                                        onChange={handleSelectGroup1}
                                                        name="productid"
                                                        options={options1}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Product Desc</th>
                                                                <th>Selling Price</th>
                                                                <th>Quantity</th>
                                                                <th>Purchase price</th>
                                                                <th colSpan="2">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* {selectedGroup1?.map((data) => (
                                                                <> */}
                                                            {selectedGroup2.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <img
                                                                            src={imgUrl + data.image}
                                                                            alt="product-img"
                                                                            title="product-img"
                                                                            className="avatar-md"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <h5 className="font-size-14 text-truncate">
                                                                            {data.name}
                                                                        </h5>
                                                                    </td>
                                                                    {/* <td>₹ {data.amount}</td> */}
                                                                    <td>
                                                                        <div style={{ width: "100px" }}>
                                                                            <Input
                                                                                type="text"
                                                                                pattern="[0-9.]*"
                                                                                min={0}
                                                                                placeholder="Selling"
                                                                                required
                                                                                // value={sellingdata[key]?.amount || ''}
                                                                                name="amount"
                                                                                onChange={(e) => {
                                                                                    handleChanges123(e, key);
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ width: "100px" }}>
                                                                            <div className="input-group">

                                                                                {/* <div className="input-group-append">
                                                                                    <button type="button" className="btn btn-primary"
                                                                                        onClick={() => {
                                                                                            countDown(data._id, data.product);
                                                                                        }}>-</button>
                                                                                </div> */}
                                                                                <Input
                                                                                    type="number"
                                                                                    value={data.product}
                                                                                    min={0}
                                                                                    required
                                                                                    name="product"
                                                                                    onChange={(e) => {
                                                                                        handleChanges(e, key);
                                                                                    }}
                                                                                // onChange={(e) => { handleChanges(e) }}
                                                                                // readOnly
                                                                                />
                                                                                {/* <div className="input-group-prepend">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        onClick={() => {
                                                                                            countUP(data._id, data.product);
                                                                                        }}>+
                                                                                    </button>
                                                                                </div> */}

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <Input
                                                                            type="text"
                                                                            pattern="[0-9.]*"
                                                                            min={0}
                                                                            style={{ width: "100px" }}
                                                                            name="unitPrice"
                                                                            placeholder="Price"
                                                                            required
                                                                            onChange={(e) => { handleChange12(e, key) }}
                                                                        />
                                                                    </td>
                                                                    <td>₹ {data.product * (form[selectedGroup2[key]._id]?.unitPrice || 0)}</td>

                                                                    {/* <td>₹ {data.product * form.unitPrice}</td> */}
                                                                    {/* <td>
                                                                        <i
                                                                            to="#"
                                                                            onClick={() => removeCartItem(data._id)}
                                                                            className="action-icon text-danger"
                                                                        >
                                                                            {" "}
                                                                            <i className="mdi mdi-trash-can font-size-18" />
                                                                        </i>
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                            {/* </>
                                                            ))} */}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="mt-4" style={{ float: "right" }}>
                                            <Button
                                                className="m-2"
                                                onClick={() => {
                                                    setshow(!show)
                                                }}
                                                color="danger"
                                                type="submit"
                                            >
                                                Cancel <i className="fas fa-times-circle"></i>
                                            </Button>
                                            <Button className="m-2" color="success" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    ) : (
                        ""
                    )}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader className="bg-white">
                                    {/* <CardTitle>Products List</CardTitle> */}
                                </CardHeader>

                                <CardBody>
                                    <div>

                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Button
                                                                onClick={() => {
                                                                    setshow(!show)
                                                                }}
                                                                color="primary"
                                                            >
                                                                Add Stock <i className="bx bx-plus-circle" />
                                                            </Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div style={{ float: "right" }}>
                                                                <input
                                                                    value={search}
                                                                    onChange={e => {
                                                                        setSearch(e.target.value)
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Edit Stock
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={(e) => {
                                editCategory(e)
                            }}
                        >
                            <Row>
                                <Col md="4">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Supplier Name <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            required
                                            value={selectedGroup3}
                                            onChange={handleSelectGroup3}
                                            name="supplierObjId"
                                            options={options}
                                        />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Purchase date <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Date"
                                            required
                                            name="purchaseDate"
                                            value={form1.purchaseDate}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                            // min={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </Col>
                                {/* <Col md="4">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Select product <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            required
                                            isMulti
                                            onChange={handleSelectGroup1}
                                            name="productid"
                                            options={options1}
                                        />

                                    </div>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="table-responsive">
                                        <Table className="table align-middle mb-0 table-nowrap">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Product Desc</th>
                                                    <th>Selling price</th>
                                                    <th>Quantity</th>
                                                    <th>Purchase Price</th>
                                                    <th colSpan="2">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <img
                                                            src={imgUrl + form1.image}
                                                            alt="product-img"
                                                            title="product-img"
                                                            className="avatar-md"
                                                        />
                                                    </td>
                                                    <td>
                                                        <h5 className="font-size-14 text-truncate">

                                                            {form1.subCategory}

                                                        </h5>
                                                    </td>
                                                    {/* <td>₹ {form1.oldPrice}</td> */}
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            pattern="[0-9.]*"
                                                            style={{ width: "100px" }}
                                                            name="price"
                                                            min={0}
                                                            placeholder="Selling"
                                                            required
                                                            value={form1.price}
                                                            onChange={(e) => {
                                                                handleChanges1(e);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div style={{ width: "100px" }}>
                                                            <div className="input-group">

                                                                {/* <div className="input-group-append">
                                                                    <button type="button" className="btn btn-primary"
                                                                        onClick={() => {
                                                                            countDown1(form1._id, form1.quantity);
                                                                        }}>-</button>
                                                                </div> */}
                                                                <Input
                                                                    type="number"
                                                                    min={0}
                                                                    placeholder="Quantity"
                                                                    value={form1.quantity}
                                                                    required
                                                                    name="quantity"
                                                                    onChange={(e) => { handleChange121(e) }}
                                                                // onChange={(e) => { handleChanges(e) }}
                                                                // readOnly
                                                                />
                                                                {/* <div className="input-group-prepend">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={() => {
                                                                            countUP1(form1._id, form1.quantity);
                                                                        }}>+
                                                                    </button>
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div style={{ width: "100px" }}>
                                                            <Input
                                                                type="text"
                                                                pattern="[0-9.]*"
                                                                min={0}
                                                                required
                                                                placeholder="Purchase"
                                                                value={form1.purchaseAmount}
                                                                name="purchaseAmount"
                                                                onChange={(e) => {
                                                                    handleChanges1(e);
                                                                }}

                                                            />
                                                        </div>
                                                    </td>
                                                    <td>₹ {totalpricedata}</td>

                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>

                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    className="m-2"
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-2" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>

                        </Form>
                    </div>
                </Modal>
                <Modal
                    size="sm"
                    isOpen={imgview}
                    toggle={() => {
                        setimgview()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setimgview(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <img src={imgUrl + imgview1.image} />
                        </Row>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Stocks
