import React, { useState, useEffect, useRef } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import logoLightPng from "../../assets/images/raglogo.png"
import { useReactToPrint } from "react-to-print"
import '../../Printerstyle.css'

const Thankyou = () => {
  const history = useHistory()
  const componentRef = useRef()

  const [invoice, setinvoice] = useState([])
  const [products, setproducts] = useState([])
  // const [phone, setphone] = useState([])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getAllOrders = async () => {
    const bodydata = {
      id: sessionStorage.getItem("orderid"),
    }
    const resonse = await addData("order/getbyid", bodydata)
    var _data = resonse
    setinvoice(_data.data.orderResult)
    setproducts(_data.data.orderedProducts)
    // setTimeout(() => {
    //   handlePrint()
    // }, 1000);
    // setphone(_data.data.orderResult.)
  }

  useEffect(() => {
    getAllOrders()
  }, [])

  // Delete fuction

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Invoice" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Button
                      onClick={handlePrint}
                      className="m-2"
                      color="success"
                    >
                      <i className="bx bx-printer"></i> Proceed If thermal
                      printer is ready
                    </Button>
                    <Button
                      onClick={() => { history.goBack() }}
                      className="m-2" color="danger">
                      <i className="bx bx-left-arrow-alt"></i> Back
                    </Button>
                    <hr />
                  </div>
                  <Row>
                    <Col md="4"></Col>
                    <Col md="4">
                      <div ref={componentRef}>
                        <div className="modal-body">
                          {/* <center>
                            <input type="button" className="btn btn-primary non-printable" 
                            // onclick="printDiv('printableArea')"
                             defaultValue="Proceed  If thermal printer is ready." />
                            <a href="https://grocerywala.co.in/admin/pos" className="btn btn-danger non-printable">Back</a>
                          </center> */}
                          {/* <hr className="non-printable" /> */}
                          <div id="printableArea">
                            <div className="initial-38-1">
                              {/* <div className="pt-3">
                                <img src={logoLightPng} className="initial-38-2 mb-2" alt="" />
                              </div> */}
                              <div className="text-center">
                                {/* <h2 className="initial-38-3">Grocerywala</h2> */}
                                <h5 style={{fontSize:"12px"}} className="text-break initial-38-4">
                                Name : {invoice.customerName}
                                </h5>
                                <h5 style={{fontSize:"12px"}} className="initial-38-4 initial-38-3">
                                Phone : {invoice.customerPhone}
                                </h5>
                              </div>
                              <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                              <div className="row">
                                <div className="col-6">
                                  <h5 style={{fontSize:"12px", fontWeight:"800"}}>Order ID 
                                    <span style={{marginBottom:"0px"}} className="font-light"> {invoice.orderNo}</span>
                                  </h5>
                                </div>
                                <div className="col-6">
                                  <h5 style={{fontSize:"12px", fontWeight:"800"}}>
                                    <span className="font-light">
                                    {invoice.date} - {invoice.time}
                                    </span>
                                  </h5>
                                </div>
                                <div className="col-12">
                                </div>
                              </div>
                              <h5 style={{margin:"0px"}} className="text-uppercase" />
                              <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                              <table style={{fontSize:"10px", marginBottom:"7px", border:"2px solid black"}} className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style={{padding:"5px"}} className="initial-38-6 border-top-0 border-bottom-0">SNo</th>
                                    <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">DESC</th>
                                    <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {products.map((data, key) => (
                                      <tr key={key}>
                                        <td style={{padding:"5px"}}>{key + 1}</td>
                                        <td style={{padding:"5px"}}>
                                            <h5 style={{fontSize:"12px"}}><b> {data.quantity} {data.stockName}</b></h5>
                                            {/* <b>Qty :</b> <span><b></b></span>
                                            <br />
                                            <b>Unit Price :</b>
                                            <span> <b>₹ {data.amount}</b></span>
                                            <br /> */}
                                        </td>
                                        <td style={{padding:"5px"}}  className="w-28p"> <b>₹ {data.amount * data.quantity}</b></td>
                                      </tr>
                                    ))}

                                  {/* <tr>
                                    <td className>
                                      1
                                    </td>
                                    <td className>
                                      Vermicelli 5kg <br />
                                      <span>Unit Price : ₹320.00</span><br />
                                      <span>Qty : 1</span><br />
                                      <span>Discount : ₹70.00</span>
                                    </td>
                                    <td className="w-28p">
                                      ₹250.00
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                              
                              <div className="px-3">
                                <dl style={{margin:"0px"}} className="row text-right justify-content-center">
                                  <dt style={{margin:"0px"}} className="col-6">Subtotal :</dt>
                                  <dd style={{margin:"0px"}} className="col-6">₹ {invoice.subAmount}</dd>
                                  <dt style={{margin:"0px"}} className="col-6">Discount :</dt>
                                  <dd style={{margin:"0px"}} className="col-6">₹ {invoice.couponAmount}</dd>
                                  <dt style={{margin:"0px"}} className="col-6">GST :</dt>
                                  <dd style={{margin:"0px"}} className="col-6">
                                    ₹{invoice.gst}
                                    <hr style={{marginBottom:"5px", marginTop:"5px"}} />
                                  </dd>
                                  <dt className="col-6 font-20px">Total:</dt>
                                  <dd className="col-6 font-20px">₹ {invoice.totalAmount}</dd>
                                  <dt className="col-6 font-20px">Total:</dt>
                                  <dd className="col-6 font-20px">₹ {invoice.moneyType}</dd>
                                </dl>
                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                <h5 style={{fontWeight:"800"}} className="text-center">
                                  <span className="d-block">"""THANK YOU"""</span>
                                </h5>
                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                <span className="d-block text-center">Copyright @ Belwails</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div style={{ width: "400px", fontWeight:"bold" }}  className="invoicefont">
                          <div className="text-center text-dark borderbottom">
                            <img src={logoLightPng} />
                            <h5 className="mt-4">
                              Name : {invoice.customerName}
                            </h5>
                            <h5 className="mb-3">Phone : {invoice.customerPhone}</h5>
                          </div>
                         
                          <div className="borderbottom">
                            <Row>
                              <Col>
                                <p>
                                  <b>Order ID :</b>
                                  <span style={{ color: "#000" }}>
                                    {" "}
                                    <b>{invoice.orderNo}</b>
                                  </span>
                                </p>
                              </Col>
                              <Col>
                                <p>
                                  <b>
                                    {invoice.date} - {invoice.time}
                                  </b>
                                </p>
                              </Col>
                            </Row>

                          </div>
                         
                          <div>
                            <Table style={{fontWeight:"bold", color:"black" }}  className="mt-2" bordered responsive>
                              <thead>
                                <tr>
                                  <th><b>S No</b></th>
                                  <th><b>DESC</b></th>
                                  <th><b>Price</b></th>
                                </tr>
                              </thead>
                              <tbody>
                                {products.length == 0 ? (
                                  <tr className="text-center">
                                    <td colSpan="3">
                                      No data...
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {products.map((data, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>
                                          <div>
                                            <h5><b>{data.stockName}</b></h5>
                                            <b>Qty :</b> <span><b>{data.quantity}</b></span>
                                            <br />
                                            <b>Unit Price :</b>
                                            <span> <b>₹ {data.amount}</b></span>
                                            <br />
                                          </div>
                                        </td>
                                        <td> <b>₹ {data.amount * data.quantity}</b></td>
                                      </tr>
                                    ))}
                                  </>
                                )}

                              </tbody>
                            </Table>
                          </div>

                          <div className="mt-2 borderbottom">
                            <Row>
                              <Col md="3"></Col>
                              <Col md="9">
                                <Row>
                                  <Col>
                                    <div style={{ direction: "rtl" }}>
                                      <b>: Subtotal </b>
                                      <br />
                                      <b>: Discount </b>
                                      <br />
                                      <b>: GST </b>
                                      <br />
                                      <br />
                                      <h5 className="mt-2">: Total </h5>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="text-end">
                                      <b> ₹ {invoice.subAmount}</b>
                                      <br />
                                      <b> ₹ {invoice.couponAmount}</b>
                                      <br />
                                      <b> ₹ {invoice.gst}</b>
                                      <br />
                                      <hr />
                                      <h5> ₹ {invoice.totalAmount}</h5>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>

                          <div className="text-center borderbottom">
                            <h5>"""Thank you"""</h5>
                          </div>
                          
                        </div> */}

                      </div>
                    </Col>
                    <Col md="4"></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Thankyou
