import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Form,
    Label,
    Input
} from "reactstrap"
import axios from "axios"
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { URL } from "../../Apiurls"
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { addData } from "Servicescalls"
// import Rolelist from '../Acefolders/Rolelist'

const Roles = () => {
    var gets = localStorage.getItem("authUser");
    var data = JSON.parse(gets);
    var datas = data.token;
    console.log(datas)
    // const permissioins = data.user.permissions[0]
    // const roless = data.user.role

    const check = {
        dashview: false,
        dashview1: false,
        dashview2: false,
        Administration: false,
        users: false,
        posts: false,
        assets: false,
        cafe: false,
        pos: false,
        SnookerParlour : false,
        Swimmingpool: false,
        Inventory: false,
        Expenses: false,
        Ledgerbalance: false,
        LockerManagement: false,
        Support: false,
        CompanyPolicy: false,
        Settings: false,
        reports: false,
    }

    const [roles, setroles] = useState(check)
    console.log(roles)
    const [form, setform] = useState([])
    let history = useHistory();

    const handleChange1 = e => {
        const myUser = { ...roles }
        myUser[e.target.name] = (e.target.checked)
        setroles(myUser)
        console.log(myUser)
    }

    const handleChange = e => {
        const myUser = { ...form }
        myUser[e.target.name] = (e.target.value)
        setform(myUser)
    }

    // Add fuction
    const addRole = async e => {
        e.preventDefault()
        const bodydata = {
            role: form.role,
            permissions: [roles]
        }
        try {
            const resonse = await addData("role/add", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message, { autoClose: 2000, })
            history.push("/departments", { showToast: true })

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Breadcrumbs title="Belwails" breadcrumbItem="Add Roles" />
                    {/* {permissioins.roleAdd === true || roless === "admin" ? ( */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                {/* <CardHeader className="bg-white mt-2" >
                                        <CardTitle>Role & Permissions</CardTitle>
                                    </CardHeader> */}

                                <CardBody >
                                    <Form
                                        onSubmit={(e) => {
                                            addRole(e)
                                        }}
                                    >
                                        <Row>
                                            <Col md={4}>
                                                <Label>Role <span className="text-danger">*</span></Label>
                                                <Input pattern="^[a-zA-Z\s]*$" required onChange={(e) => { handleChange(e) }} name="role" type="text" placeholder="Enter Role Name" />

                                            </Col>
                                        </Row>
                                        
                                        <Row className=" mt-3">

                                            <Col md={2}>
                                                {" "}
                                                <p className="">Dashboard: </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>

                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.dashview}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.dashview}
                                                        name="dashview"
                                                        type='checkbox' id="dashview" />
                                                    <Label className='form-check-label' for="dashview">
                                                    Bookings Access 
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.dashview1}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.dashview1}
                                                        name="dashview1"
                                                        type='checkbox' id="dashview1" />
                                                    <Label className='form-check-label' for="dashview1">
                                                    Social Access 
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.dashview2}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.dashview2}
                                                        name="dashview2"
                                                        type='checkbox' id="dashview2" />
                                                    <Label className='form-check-label' for="dashview2">
                                                    Report Access 
                                                    </Label>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Administration : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Administration}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Administration}
                                                        name="Administration"
                                                        type='checkbox' id="Administration" />
                                                    <Label className='form-check-label' for="Administration">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                           
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Users : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.users}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.users}
                                                        name="users"
                                                        type='checkbox' id="users" />
                                                    <Label className='form-check-label' for="users">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Posts : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.posts}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.posts}
                                                        name="posts"

                                                        type='checkbox' id="posts" />
                                                    <Label onClick={e => { handleChange1(e) }} className='form-check-label' for="posts">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Assets : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.assets}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.assets}
                                                        name="assets"

                                                        type='checkbox' id="assets" />
                                                    <Label onClick={e => { handleChange1(e) }} className='form-check-label' for="assets">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Cafe : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.cafe}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.cafe}
                                                        name="cafe"

                                                        type='checkbox' id="cafe" />
                                                    <Label onClick={e => { handleChange1(e) }} className='form-check-label' for="cafe">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Pos : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.pos}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.pos}
                                                        name="pos"

                                                        type='checkbox' id="pos" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="pos">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Snooker Parlour : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.SnookerParlour}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.SnookerParlour}
                                                        name="SnookerParlour"

                                                        type='checkbox' id="SnookerParlour" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="SnookerParlour">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Swimming pool : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Swimmingpool}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Swimmingpool}
                                                        name="Swimmingpool"

                                                        type='checkbox' id="Swimmingpool" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Swimmingpool">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Inventory : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Inventory}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Inventory}
                                                        name="Inventory"

                                                        type='checkbox' id="Inventory" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Inventory">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                {" "}
                                                <p className="">Expenses : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Expenses}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Expenses}
                                                        name="Expenses"

                                                        type='checkbox' id="Expenses" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Expenses">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Ledger balance : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Ledgerbalance}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Ledgerbalance}
                                                        name="Ledgerbalance"

                                                        type='checkbox' id="Ledgerbalance" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Ledgerbalance">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                       
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Locker Management : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.LockerManagement}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.LockerManagement}
                                                        name="LockerManagement"

                                                        type='checkbox' id="LockerManagement" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="LockerManagement">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Support : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Support}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Support}
                                                        name="Support"

                                                        type='checkbox' id="Support" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Support">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Company Policy : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.CompanyPolicy}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.CompanyPolicy}
                                                        name="CompanyPolicy"

                                                        type='checkbox' id="CompanyPolicy" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="CompanyPolicy">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Settings : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.Settings}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.Settings}
                                                        name="Settings"

                                                        type='checkbox' id="Settings" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="Settings">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={2}>
                                                <p className="">Reports : </p>
                                            </Col>{" "}
                                            <Col md={1}></Col>
                                            <Col md={3}>
                                                <div className='form-check me-3 me-lg-5'>
                                                    <Input defaultChecked={roles.reports}
                                                        onClick={e => {
                                                            handleChange1(e)
                                                        }}
                                                        value={roles.reports}
                                                        name="reports"
                                                        type='checkbox' id="reports" />
                                                    <Label onClick={e => { handleChange1(e) }} 
                                                    className='form-check-label' for="reports">
                                                        Access
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>

                                    

                                        <div className="mt-3" style={{ float: "right" }}>
                                            <button
                                                type="button"
                                                style={{ width: "120px" }}
                                                className="btn btn-danger m-1"
                                                onClick={history.goBack}
                                            >
                                                Cancel <i className="fas fa-times-circle" aria-hidden="true"></i>
                                            </button>
                                            <button
                                                type="submit"
                                                style={{ width: "120px" }}
                                                className="btn btn-success m-1"
                                            >
                                                Submit <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            </button>
                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* ) : (
                        <Card>
                            <h5 className="text-center p-1">You don't have permission to access</h5>
                        </Card>
                    )} */}
                </Container>
            </div>
            <Toaster />
        </React.Fragment>
    )
}

export default Roles