import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Category = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [customer, setcustomers] = useState([])
  const [form, setform] = useState({ expenseType: "", category: "", })
  const [form1, setform1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllCustomers = async () => {
    const resonse = await addData("expense/getallcategories")
    var _data = resonse
    setcustomers(_data.data.category)
    setFilter(_data.data.category)
  }

  useEffect(() => {
    getAllCustomers()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Expense Type",
      selector: row => row.expenseType,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => row.category,
      sortable: true,
    },

    {
      name: "Status",
      selector: row => row.status == "true" ? "Active" : "Inactive",
      sortable: true,
    },

    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = customer.filter(data => {
      return data.expenseType && data.expenseType.toLowerCase().match(trimmedSearch.toLowerCase())||
      data.category && data.category.toLowerCase().match(trimmedSearch.toLowerCase())
    })
    setFilter(result)
  }, [search])

  // Add fuction
  const addCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      expenseType: form.expenseType,
      category: form.category,
    }
    try {
      const resonse = await addData("expense/addcategory", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ expenseType: "", category: "", })
      getAllCustomers()
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      expenseType: form1.expenseType,
      category: form1.category,
      status: form1.status,
    }
    try {
      const resonse = await updateData("expense/editcategory/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1({ expenseType: "", category: "", status: "" })
      setmodal_small(false)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  //   Delete fuction

  const deletebenners = async (data) => {
    try {
      const resonse = await deletedData(
        "expense/deletecategory/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [forms, setforms] = useState([])
  console.log(forms)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Category" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}



          <Row>
            <Col md="4">
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Category</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      addCustomer(e)
                    }}
                  >

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Expense Type <span className="text-danger">*</span>
                      </Label>
                      <select
                        required
                        name="expenseType"
                        value={form.expenseType}
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select" >
                        <option value="">Select</option>
                        <option value="Swimming">Swimming</option>
                        <option value="Snooker">Snooker</option>
                        <option value="Cafe">Cafe</option>
                        <option value="Miscellaneous">Miscellaneous</option>
                      </select>

                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Category Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Category Name"
                        required
                        name="category"
                        value={form.category}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>

                    {/* <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                        Description <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Description"
                          required
                          name="description"
                          value={form.description}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div> */}

                    <div className="mt-2" style={{ float: "right" }}>

                      <Button color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                {/* <CardHeader className="bg-white">
                  <CardTitle>Customers List</CardTitle>
                </CardHeader> */}

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div style={{ float: "right" }}>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Category
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCustomer(e)
              }}
            >

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Expense Type <span className="text-danger">*</span>
                </Label>
                <select
                  required
                  name="expenseType"
                  value={form1.expenseType}
                  onChange={(e) => {
                    handleChange1(e)
                  }}
                  className="form-select" >
                  <option value="">Select</option>
                  <option value="Swimming">Swimming</option>
                  <option value="Snooker">Snooker</option>
                  <option value="Cafe">Cafe</option>
                  <option value="Miscellaneous">Miscellaneous</option>
                </select>

              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Category Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Category Name"
                  required
                  name="category"
                  value={form1.category}
                  onChange={(e) => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input3">
                  Status <span className="text-danger">*</span>
                </Label>
                <select
                  name="status"
                  value={form1.status}
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="true">Active</option>
                  <option value="false">In Active</option>
                </select>
              </div>

              <div className="mt-2" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Category
