import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Products = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [prodects, setProducts] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ categoryId: "", name: "", description: "", amount: "", tax: "" })
  const [form1, setform1] = useState([])
  console.log(form1)
  const [form2, setform2] = useState([])
  const [Files1, setFiles1] = useState({ image: "" })
  const [Files, setFiles] = useState({ image: "" })

  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("file format not supported.Pls choose Image")
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllProducts = async () => {
    const resonse = await addData("subcategory/getall")
    var _data = resonse
    setProducts(_data.data.subCategoryResult)
    setFilter(_data.data.subCategoryResult)
  }

  const getAllCategories = async () => {
    const resonse = await addData("category/getactive")
    var _data = resonse
    setcategory(_data.data.result)
  }

  useEffect(() => {
    getAllProducts()
    getAllCategories()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Category Name",
      selector: row => row.categoryName,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: row => row.foodType,
      sortable: true,
    },
    {
      name: "Image",
      selector: row => (
        <img
          className="p-2"
          src={imgUrl + row.image}
          style={{ width: "100px", height: "50px" }}
        />
      ),
      sortable: true,
    },
    // {
    //   name: "Price",
    //   selector: row => "₹"+row.amount,
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  // useEffect(() => {
  //   const result = prodects.filter(data => {
  //     return data.name && data.name.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilter(result)
  // }, [search])

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = prodects.filter((data) => {
      return (
        (data.name && data.name.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.categoryName && data.categoryName.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addcategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("categoryId", form.categoryId)
    dataArray.append("name", form.name)
    dataArray.append("foodType", form.foodType)
    dataArray.append("description", form.description)
    dataArray.append("amount", form.amount || '')
    dataArray.append("tax", form.tax)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    try {
      const resonse = await addData("subcategory/add", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setFiles({ image: "" })
      setform({ categoryId: "", name: "", description: "", amount: "", tax: "" })
      getAllProducts()
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("categoryId", form1.categoryId)
    dataArray.append("name", form1.name)
    dataArray.append("foodType", form1.foodType)
    dataArray.append("description", form1.description)
    dataArray.append("tax", form1.tax)
    dataArray.append("amount", form1.amount || '')
    dataArray.append("status", form1.status)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }
    try {
      const resonse = await updateData(
        "subcategory/edit/" + form1._id,
        dataArray
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setFiles1({ image: "" })
      setmodal_small(false)
      getAllProducts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deleteCategory = async data => {
    try {
      const resonse = await deletedData("subcategory/delete/" + data._id, {})
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllProducts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you want to Delete?")
    if (confirmBox === true) {
      deleteCategory(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [imgview, setimgview] = useState(false)
  const [imgview1, setimgview1] = useState([])


  function openmodels(data) {
    setimgview(!imgview)
    setimgview1(data)
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Products" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          {show == true ? (
            <div>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Product</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      addcategory(e)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Category Name <span className="text-danger">*</span>
                          </Label>
                          <select
                            required
                            name="categoryId"
                            value={form.categoryId}
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {category.map((data, key) => (
                              <option key={key} value={data._id}>
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Name"
                            required
                            name="name"
                            value={form.name}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <select
                            required
                            name="foodType"
                            value={form.foodType}
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Veg">Vegetarian</option>
                            <option value="Non-veg">Non-Vegetarian</option>

                          </select>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Image"
                            required
                            name="image"
                            value={Files.image}
                            onChange={changeHandler}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Amount <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Amount"
                            required
                            name="amount"
                            value={form.amount}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col> */}
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Tax(%) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Tax"
                            required
                            name="tax"
                            value={form.tax}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            name="description"
                            value={form.description}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4" style={{ float: "right" }}>
                      <Button
                        className="m-2"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger"
                        type="submit"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-2" color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Products List</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>

                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                onClick={() => {
                                  setshow(!show)
                                }}
                                color="primary"
                              >
                                Add Product <i className="bx bx-plus-circle" />
                              </Button>
                            </div>
                            <div className="col-md-6">
                              <div style={{ float: "right" }}>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                />
                              </div>
                            </div>
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Product
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCategory(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Category Name <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="categoryId"
                      value={form1.categoryId}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {category.map((data, key) => (
                        <option key={key} value={data._id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={form1.name}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Type <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="foodType"
                      value={form1.foodType}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      <option value="Veg">Vegetarian</option>
                      <option value="Non-veg">Non-Vegetarian</option>

                    </select>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <Label for="basicpill-firstname-input1">
                          Image
                        </Label>
                      </Col>
                      <Col className="text-end">
                        <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a>
                      </Col>
                    </Row>

                    <Input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Image"
                      name="image"
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Amount <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Amount"
                      required
                      name="amount"
                      value={form1.amount}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col> */}
                {/* <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Tax(%) <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Tax"
                      required
                      name="tax"
                      value={form1.tax}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col> */}
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      name="description"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">In Active</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          size="sm"
          isOpen={imgview}
          toggle={() => {
            setimgview()
          }}
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setimgview(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <img src={imgUrl + imgview1.image} />
            </Row>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Products
