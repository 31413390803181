import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Snookertable = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [imgview, setimgview] = useState(false)
  const [imgview1, setimgview1] = useState([])
  const [show, setshow] = useState(false)
  const [show2, setshow2] = useState(false)
  const [prodects, setProducts] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ description: "", name: "" })
  const [form1, setform1] = useState([])
  console.log(form1)
  const [form2, setform2] = useState([])
  const [Files1, setFiles1] = useState({ image: "" })
  const [Files, setFiles] = useState({ image: "" })

  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  function openmodels(data) {
    setimgview(!imgview)
    setimgview1(data)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const [inputList, setInputList] = useState([{ label: "" }])
  const [inputList2, setInputList2] = useState([{ label: "" }])
  const [inputList3, setInputList3] = useState([{ fromTime: "", toTime: "", amount: "", gst: "" }])
  const [inputList4, setInputList4] = useState([{ fromTime: "", toTime: "", amount: "", gst: "" }])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList2]
    list[index][name] = value
    setInputList2(list)
  }
  const handleInputChange3 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList3]
    list[index][name] = value
    setInputList3(list)
  }
  const handleInputChange4 = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList4]
    list[index][name] = value
    setInputList4(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }
  const handleRemoveClick2 = index => {
    const list = [...inputList2]
    list.splice(index, 1)
    setInputList2(list)
  }
  const handleRemoveClick3 = index => {
    const list = [...inputList3]
    list.splice(index, 1)
    setInputList3(list)
  }
  const handleRemoveClick4 = index => {
    const list = [...inputList4]
    list.splice(index, 1)
    setInputList4(list)
  }
  const handleAddClick = () => {
    setInputList([...inputList, { label: "" }])
  }
  const handleAddClick2 = () => {
    setInputList2([...inputList2, { label: "" }])
  }
  const handleAddClick3 = () => {
    setInputList3([...inputList3, { fromTime: "", toTime: "", amount: "", gst: "" }])
  }
  const handleAddClick4 = () => {
    setInputList4([...inputList4, { fromTime: "", toTime: "", amount: "", gst: "" }])
  }

  // get all function

  const getAllProducts = async () => {
    const resonse = await addData("snooker/getall")
    var _data = resonse
    setProducts(_data.data.snookerResult)
    setFilter(_data.data.snookerResult)
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    // {
    //   name: "Type",
    //   selector: row => row.type,
    //   sortable: true,
    // },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: row => (
        <img
          className="p-2"
          src={imgUrl + row.image}
          style={{ width: "100px", height: "50px" }}
        />
      ),
      sortable: true,
    },
    // {
    //   name: "Description",
    //   selector: row => row.description >30 ?row.description.substring(0, 30)+'...':row.description,
    //   sortable: true,
    // },
    {
      name: "Status",
      selector: row => row.status == true ? "Active" : "Inactive",
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = prodects.filter((data) => {
      return (
        (data.name && data.name.toLowerCase().includes(trimmedSearch.toLowerCase()))
        // ||(data.status && data.status.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addcategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("description", form.description)
    // dataArray.append("price", form.price)
    // dataArray.append("gst", form.gst)
    // dataArray.append("timeSlot", form.timeSlot)
    dataArray.append("instruction", JSON.stringify(inputList))
    dataArray.append("timming", JSON.stringify(inputList3))
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    try {
      const resonse = await addData("snooker/add", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ description: "", name: "" })
      setInputList({ label: "" })
      setInputList3({ fromTime: "", toTime: "", amount: "", gst: "" })
      setFiles({ image: "" })

      getAllProducts()
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("description", form1.description)
    // dataArray.append("price", form1.price)
    // dataArray.append("gst", form1.gst)
    // dataArray.append("timeSlot", form1.timeSlot)
    dataArray.append("instruction", JSON.stringify(inputList2))
    dataArray.append("timming", JSON.stringify(inputList4))
    dataArray.append("status", form1.status)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }
    try {
      const resonse = await addData("snooker/editbyid/" + form1._id, dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setFiles1({ image: "" })
      getAllProducts()
      setshow2(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deleteCategory = async data => {
    try {
      const resonse = await deletedData("snooker/delete/" + data._id, {})
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllProducts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you want to Delete?")
    if (confirmBox === true) {
      deleteCategory(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    setInputList2(data.instruction)
    setInputList4(data.timming)
    setshow2(!show2)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Tables" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          {show == true ? (
            <div>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Add Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      addcategory(e)
                    }}
                  >
                    <Row>
                      {/* <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <select
                            required
                            name="type"
                            value={form.type}
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="swimming_pool">Swimming Pool</option>
                            <option value="snooker_parlor">
                              Snooker Parlor
                            </option>
                            <option value="court">Court</option>
                          </select>
                        </div>
                      </Col> */}
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Name"
                            required
                            name="name"
                            value={form.name}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Image"
                            required
                            name="image"
                            value={Files.image}
                            onChange={changeHandler}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            name="description"
                            value={form.description}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <Label>Instruction</Label>
                      <Row>
                        {inputList.map((x, i) => {
                          return (
                            <div key={i} className="box row">
                              <Col md="5" sm="12" className="mb-1">
                                <Input
                                  type="text"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="label"
                                  value={x.label}
                                  onChange={e => handleInputChange(e, i)}
                                  required
                                />
                              </Col>
                              <Col sm="3">
                                <div className="btn-box">
                                  {inputList.length !== 1 && (
                                    <button
                                      className="mr10 btn btn-outline-danger btn-sm m-1"
                                      type="button"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      Remove <i className="bx bx-x-circle"></i>
                                    </button>
                                  )}
                                  {inputList.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-outline-info m-1"
                                      onClick={handleAddClick}
                                    >
                                      Add <i className="bx bx-plus-circle"></i>
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          )
                        })}
                      </Row>
                    </div>
                    <div className="mt-4">
                      <Label style={{ fontSize: "20px" }} className="mb-3">Time Slots</Label>
                      <Row>
                        {inputList3.map((x, i) => {
                          return (
                            <div key={i} className="box row">
                              <Col md="2" sm="12" className="mb-1">
                                <Label>From Time</Label> <span className="text-danger">*</span>
                                <Input
                                  type="time"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="fromTime"
                                  value={x.fromTime}
                                  onChange={e => handleInputChange3(e, i)}
                                  required
                                />
                              </Col>
                              <Col md="2" sm="12" className="mb-1">
                                <Label>To Time</Label> <span className="text-danger">*</span>
                                <Input
                                  type="time"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="toTime"
                                  value={x.toTime}
                                  onChange={e => handleInputChange3(e, i)}
                                  required
                                />
                              </Col>
                              <Col md="2" sm="12" className="mb-1">
                                <Label>Price</Label> <span className="text-danger">*</span>
                                <Input
                                  type="text"
                                  pattern="[0-9.]*"
                                  id="nameMulti"
                                  placeholder="Enter Price"
                                  name="amount"
                                  value={x.amount}
                                  onChange={e => handleInputChange3(e, i)}
                                  required
                                />
                              </Col>
                              {/* <Col md="2" sm="12" className="mb-1">
                                <Label> GST(%)</Label> <span className="text-danger">*</span>
                                <Input
                                  type="number"
                                  id="nameMulti"
                                  placeholder="Enter GST(%)"
                                  name="gst"
                                  value={x.gst}
                                  onChange={e => handleInputChange3(e, i)}
                                  required
                                />
                              </Col> */}

                              <Col sm="3">
                                <div style={{ marginTop: "30px" }} className="btn-box ">
                                  {inputList3.length !== 1 && (
                                    <button
                                      className="mr10 btn btn-outline-danger btn-sm m-1"
                                      type="button"
                                      onClick={() => handleRemoveClick3(i)}
                                    >
                                      Remove <i className="bx bx-x-circle"></i>
                                    </button>
                                  )}
                                  {inputList3.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-outline-info m-1"
                                      onClick={handleAddClick3}
                                    >
                                      Add <i className="bx bx-plus-circle"></i>
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          )
                        })}
                      </Row>
                    </div>

                    <div className="mt-4" style={{ float: "right" }}>
                      <Button
                        className="m-2"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger"
                        type="submit"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-2" color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          ) : (
            ""
          )}
          {show2 == true ? (
            <div>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Edit Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      editCategory(e)
                    }}
                  >
                    <Row>
                      {/* <Col md="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <select
                            required
                            name="type"
                            value={form1.type}
                            onChange={e => {
                              handleChange1(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="swimming_pool">Swimming Pool</option>
                            <option value="snooker_parlor">
                              Snooker Parlor
                            </option>
                            <option value="court">Court</option>
                          </select>
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Name"
                            required
                            name="name"
                            value={form1.name}
                            onChange={e => {
                              handleChange1(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Row>
                            <Col>  <Label for="basicpill-firstname-input1">Image</Label>
                            </Col>
                            <Col className="text-end">
                              <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a>
                            </Col>
                          </Row>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Image"
                            name="image"
                            onChange={changeHandler1}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Description <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            name="description"
                            value={form1.description}
                            onChange={e => {
                              handleChange1(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Status <span className="text-danger">*</span>
                          </Label>
                          <select
                            name="status"
                            value={form1.status}
                            onChange={e => {
                              handleChange1(e)
                            }}
                            className="form-select"
                          >
                            <option value="true">Active</option>
                            <option value="false">In Active</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4">
                      <Label>Instructions</Label>
                      <Row>
                        {inputList2.map((x, i) => {
                          return (
                            <div key={i} className="box row">
                              <Col md="5" sm="12" className="mb-1">
                                <Input
                                  type="text"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="label"
                                  value={x.label}
                                  onChange={e => handleInputChange2(e, i)}
                                  required
                                />
                              </Col>
                              <Col sm="3">
                                <div className="btn-box">
                                  {inputList2.length !== 1 && (
                                    <button
                                      className="mr10 btn btn-outline-danger btn-sm m-1"
                                      type="button"
                                      onClick={() => handleRemoveClick2(i)}
                                    >
                                      Remove <i className="bx bx-x-circle"></i>
                                    </button>
                                  )}
                                  {inputList2.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-outline-info m-1"
                                      onClick={handleAddClick2}
                                    >
                                      Add <i className="bx bx-plus-circle"></i>
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          )
                        })}
                      </Row>
                    </div>

                    <div className="mt-4">
                      <Label style={{ fontSize: "20px" }} className="mb-3">Time Slots</Label>
                      <Row>
                        {inputList4.map((x, i) => {
                          return (
                            <div key={i} className="box row">
                              <Col md="2" sm="12" className="mb-1">
                                <Label>From Time</Label>
                                <Input
                                  type="time"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="fromTime"
                                  value={x.fromTime}
                                  onChange={e => handleInputChange4(e, i)}
                                  required
                                />
                              </Col>
                              <Col md="2" sm="12" className="mb-1">
                                <Label>To Time</Label>
                                <Input
                                  type="time"
                                  id="nameMulti"
                                  placeholder="Enter Name"
                                  name="toTime"
                                  value={x.toTime}
                                  onChange={e => handleInputChange4(e, i)}
                                  required
                                />
                              </Col>
                              <Col md="2" sm="12" className="mb-1">
                                <Label>Price</Label>
                                <Input
                                  type="text"
                                  pattern="[0-9.]*"
                                  id="nameMulti"
                                  placeholder="Enter Price"
                                  name="amount"
                                  value={x.amount}
                                  onChange={e => handleInputChange4(e, i)}
                                  required
                                />
                              </Col>
                              {/* <Col md="2" sm="12" className="mb-1">
                                <Label> GST(%)</Label>
                                <Input
                                  type="number"
                                  id="nameMulti"
                                  placeholder="Enter GST(%)"
                                  name="gst"
                                  value={x.gst}
                                  onChange={e => handleInputChange4(e, i)}
                                  required
                                />
                              </Col> */}

                              <Col sm="3">
                                <div style={{ marginTop: "30px" }} className="btn-box ">
                                  {inputList4.length !== 1 && (
                                    <button
                                      className="mr10 btn btn-outline-danger btn-sm m-1"
                                      type="button"
                                      onClick={() => handleRemoveClick4(i)}
                                    >
                                      Remove <i className="bx bx-x-circle"></i>
                                    </button>
                                  )}
                                  {inputList4.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-outline-info m-1"
                                      onClick={handleAddClick4}
                                    >
                                      Add <i className="bx bx-plus-circle"></i>
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          )
                        })}
                      </Row>
                    </div>

                    <div className="mt-4" style={{ float: "right" }}>
                      <Button
                        className="m-2"
                        onClick={() => {
                          setshow2(!show2)
                        }}
                        color="danger"
                        type="submit"
                      >
                        Cancel <i className="fas fa-times-circle"></i>
                      </Button>
                      <Button className="m-2" color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Products List</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>

                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                onClick={() => {
                                  setshow(!show)
                                }}
                                color="primary"
                              >
                                Add Table <i className="bx bx-plus-circle" />
                              </Button>
                            </div>
                            <div className="col-md-6">
                              <div style={{ float: "right" }}>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                />
                              </div>
                            </div>
                          </div>

                        }
                      // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />

        <Modal
          size="sm"
          isOpen={imgview}
          toggle={() => {
            setimgview()
          }}
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setimgview(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <img src={imgUrl + imgview1.image} />
            </Row>
          </div>
        </Modal>

      </div>
    </React.Fragment>
  )
}

export default Snookertable
