import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import Select from "react-select"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Coupons = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)
  const [coupon, setCoupon] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  const viewcoupon = () => {
    setshow(!show)
  }
  const viewcoupon1 = () => {
    setshow1(!show1)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const [dds, setdds] = useState([])

  const handleChangedates = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds(tomorrow.toISOString().split("T")[0])
  }

  const [dds1, setdds1] = useState([])
  const handleChangedates1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds1(tomorrow.toISOString().split("T")[0])
  }

  const [customer, setcustomers] = useState([])

  // get all function

  const getAllCoupon = async () => {
    const resonse = await addData("coupon/getall")
    var _data = resonse
    setCoupon(_data.data.couponResult)
    setFilter(_data.data.couponResult)
  }

  const getAllCustomers = async () => {
    const resonse = await addData("customer/getall")
    var _data = resonse
    setcustomers(_data.data.result)
  }

  useEffect(() => {
    getAllCoupon()
    getAllCustomers()
  }, [])


  const options = customer.map(data => ({
    value: data._id,
    label: data.name,
    phone: data.phone,
  }))

  const [selectedGroup, setselectedGroup] = useState([])
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  function selectAllOptions() {
    setselectedGroup(options);
  }

  function clearSelection() {
    setselectedGroup([]);
  }
  const [selectedGroup1, setselectedGroup1] = useState([])
  function handleSelectGroup1(selectedGroup1) {
    setselectedGroup1(selectedGroup1)
  }
  function selectAllOptions1() {
    setselectedGroup1(options);
  }

  function clearSelection1() {
    setselectedGroup1([]);
  }


  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Coupon Type",
      selector: row => row.couponType,
      sortable: true,
    },
    {
      name: "Coupon Title",
      selector: row => row.title,
      sortable: true,
    },
    {
      name: "Coupon Code",
      selector: row => row.couponCode,
      sortable: true,
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
    },
    {
      name: "Value",
      selector: row => row.type == 'Percentage' ? row.value + "%" : "₹ " + row.value,
      sortable: true,
    },
    {
      name: "Expired",
      selector: row => row.toDate,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status == true ? "Active" : "Inactive",
      sortable: true,
    },

    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]


  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = coupon.filter((data) => {
      return (
        (data.couponType && data.couponType.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.title && data.title.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.couponCode && data.couponCode.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.type && data.type.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addCustomer = async (e) => {
    e.preventDefault()
    const bodydata = {
      couponType: form.couponType,
      title: form.title,
      couponCode: form.couponCode,
      limit: form.limit,
      type: form.type,
      value: form.value,
      minPurchase: form.minPurchase,
      maxDiscount: form.maxDiscount,
      fromDate: form.fromDate,
      toDate: form.toDate,
      cutomers: selectedGroup,
    }
    try {
      const resonse = await addData("coupon/add", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform([])
      setselectedGroup([])
      setshow(false)
      getAllCoupon()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      couponType: form1.couponType,
      title: form1.title,
      couponCode: form1.couponCode,
      limit: form1.limit,
      type: form1.type,
      value: form1.value,
      minPurchase: form1.minPurchase,
      maxDiscount: form1.maxDiscount,
      fromDate: form1.fromDate,
      toDate: form1.toDate,
      status: form1.status,
      cutomers: selectedGroup1,
    }
    try {
      const resonse = await addData("coupon/editbyid/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1([])
      setselectedGroup1([])
      setshow1(false)
      getAllCoupon()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  //   Delete fuction
  const deletebenners = async data => {
    try {
      const resonse = await deletedData("coupon/deletebyid/" + data._id, {})
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllCoupon()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = (data) => {
    setform1(data)
    handleSelectGroup1(data?.cutomers)
    setshow1(true)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Coupons" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            {show == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Add Coupon</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        addCustomer(e)
                      }}
                    >
                      <Row>
                        {" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="couponType"
                              value={form.couponType}
                              onChange={e => {
                                handleChange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="First_Order">First Order</option>
                              <option value="Customer_Wise">Customer Wise</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Title"
                              required
                              name="title"
                              value={form.title}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Coupon Code <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input3"
                              placeholder="Enter Coupon Code"
                              required
                              name="couponCode"
                              value={form.couponCode}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form.couponType == "FirstOrder" ? (
                          ""
                        ) : (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Limit For Same User{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Ex : 10"
                                required
                                name="limit"
                                value={form.limit}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                        )}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Code Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="type"
                              value={form.type}
                              onChange={e => {
                                handleChange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="Price">Price</option>
                              <option value="Percentage">Percentage</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Value  {form.type == "Percentage"?"(%)":"(₹)"} <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Value "
                              required
                              name="value"
                              value={form.value}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Minimum Purchase{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Minimum Purchase"
                              required
                              name="minPurchase"
                              value={form.minPurchase}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Maximum Discount{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Maximum Discount"
                              required
                              name="maxDiscount"
                              value={form.maxDiscount}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              required
                              name="fromDate"
                              min={new Date().toISOString().split("T")[0]}
                              value={form.fromDate}
                              onChange={e => {
                                handleChangedates(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Expire Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter  Version"
                              required
                              name="toDate"
                              min={dds}
                              value={form.toDate}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form.couponType == "Customer_Wise" ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Row>
                                <Col>
                                  <Label for="basicpill-firstname-input1">
                                    Customers <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                {selectedGroup.length == 0 ? (
                                  <Col >
                                    <Label type="button" style={{ float: "right" }} onClick={selectAllOptions} >Select all</Label>
                                  </Col>
                                ) : (
                                  <Col >
                                    <Label type="button" style={{ float: "right" }} onClick={clearSelection} >Clear all</Label>
                                  </Col>
                                )}

                              </Row>
                              <Select
                                onChange={handleSelectGroup}
                                name="cutomers"
                                options={options}
                                value={selectedGroup}
                                isMulti
                                required
                              />

                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      <div style={{ float: "right" }}>
                        <Button onClick={() => { viewcoupon() }} className="m-1" color="danger" type="button">
                          Cancel <i className="fas fa-times-circle"></i>
                        </Button>
                        <Button className="m-1" color="success" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : ""}

            {show1 == true ? (
              <Col md={12}>
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Edit Coupon</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        editCustomer(e)
                      }}
                    >
                      <Row>
                        {" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="couponType"
                              value={form1.couponType}
                              onChange={e => {
                                handleChange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="First_Order">First Order</option>
                              <option value="Customer_Wise">Customer Wise</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Title <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Title"
                              required
                              name="title"
                              value={form1.title}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Coupon Code <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input3"
                              placeholder="Enter Coupon Code"
                              required
                              name="couponCode"
                              value={form1.couponCode}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        {form1.couponType == "FirstOrder" ? (
                          ""
                        ) : (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Limit For Same User{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Ex : 10"
                                required
                                name="limit"
                                value={form1.limit}
                                onChange={e => {
                                  handleChange1(e)
                                }}
                              />
                            </div>
                          </Col>
                        )}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Coupon Code Type
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="type"
                              value={form1.type}
                              onChange={e => {
                                handleChange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="Price">Price</option>
                              <option value="Percentage">Percentage</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Value {form1.type == "Percentage"?"(%)":"(₹)"}<span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Value "
                              required
                              name="value"
                              value={form1.value}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Minimum Purchase{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Minimum Purchase"
                              required
                              name="minPurchase"
                              value={form1.minPurchase}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Maximum Discount{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter Maximum Discount"
                              required
                              name="maxDiscount"
                              value={form1.maxDiscount}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>{" "}
                        <Col md={3}>
                          {" "}
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              required
                              name="fromDate"
                              // min={new Date().toISOString().split("T")[0]}
                              value={form1.fromDate}
                              onChange={e => {
                                handleChangedates1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Expire Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-firstname-input2"
                              placeholder="Enter  Version"
                              required
                              name="toDate"
                              min={dds1}
                              value={form1.toDate}
                              onChange={e => {
                                handleChange1(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input2">
                              Status <span className="text-danger">*</span>
                            </Label>
                            <select 
                            onChange={e => {
                              handleChange1(e)
                            }}
                            name="status" value={form1.status} className="form-select">
                              <option value="">Select</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </Col>
                        {form1.couponType == "Customer_Wise" ? (
                          <Col md={3}>
                            <div className="mb-3">
                            <Row>
                                <Col>
                                  <Label for="basicpill-firstname-input1">
                                    Customers <span className="text-danger">*</span>
                                  </Label>
                                </Col>
                                {selectedGroup1.length == 0 ? (
                                  <Col >
                                    <Label type="button" style={{ float: "right" }} onClick={selectAllOptions1} >Select all</Label>
                                  </Col>
                                ) : (
                                  <Col >
                                    <Label type="button" style={{ float: "right" }} onClick={clearSelection1} >Clear all</Label>
                                  </Col>
                                )}

                              </Row>
                              <Select
                                onChange={handleSelectGroup1}
                                name="cutomers"
                                options={options}
                                value={selectedGroup1}
                                isMulti
                                required
                              />

                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>

                      <div style={{ float: "right" }}>
                        <Button onClick={() => { viewcoupon1() }} className="m-1" color="danger" type="button">
                          Cancel <i className="fas fa-times-circle"></i>
                        </Button>
                        <Button className="m-1" color="success" type="submit">
                          Submit <i className="fas fa-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            ) : ""}

            <Col md={12}>
              <Card>
                <CardBody>
                  <div>

                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <Button color="primary" onClick={() => { viewcoupon() }} >Add Coupon <i className="bx bx-plus-circle" /></Button>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div style={{ float: "right" }}>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control "
                                  placeholder="Search..."
                                />
                              </div>
                            </div>
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>



        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Coupons
