import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Expense = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [show, setshow] = useState(false)
  const [prodects, setProducts] = useState([])
  const [expcount, setexpcount] = useState([])
  const [types, settypes] = useState([])
  console.log(types)
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ name: "", categoryId: "", description: "", amount: "", date: "" })
  const [form1, setform1] = useState([])
  const [form12, setform12] = useState([])

  const [form0, setform0] = useState([])
  const [form2, setform2] = useState([])
  const [form21, setform21] = useState([])
  const [Files1, setFiles1] = useState({ attachment: "" })
  const [Files, setFiles] = useState({ attachment: "" })

  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  const changeHandler = e => {
    setFiles(e.target.files)
  }
  const changeHandler1 = e => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }
  function tog_small1() {
    setmodal_small1(!modal_small1)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange12 = async (e) => {
    let myUser = { ...form12 }
    myUser[e.target.name] = e.target.value
    setform12(myUser)
    const bodyData = {
      expenseType: e.target.value
    }
    const resonse = await addData("expense/getcategoriesbytype", bodyData)
    var _data = resonse
    setform21(_data.data.category)
    const bodyData1 = {
      dates: datastate.length == 0 ? [new Date().toISOString().split('T')[0]] : datastate,
      expenseType: e.target.value,
      expenseCategoryId: form12.expenseCategoryId,
      name: form12.name,
    }
    const resonse1 = await addData("expense/getallexpenses", bodyData1)
    var _data = resonse1
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)

  }

  const handleChange13 = async (e) => {
    let myUser = { ...form12 }
    myUser[e.target.name] = e.target.value
    setform12(myUser)
    const bodyData1 = {
      dates: datastate.length == 0 ? [new Date().toISOString().split('T')[0]] : datastate,
      expenseType: form12.expenseType,
      expenseCategoryId: e.target.value,
      name: form12.name,
    }
    const resonse1 = await addData("expense/getallexpenses", bodyData1)
    var _data = resonse1
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)
  }
  const handleChange14 = async (e) => {
    console.log(e.target.value)
    let myUser = { ...form12 }
    myUser[e.target.name] = e.target.value
    setform12(myUser)
    const bodyData1 = {
      dates: datastate.length == 0 ? [new Date().toISOString().split('T')[0]] : datastate,
      expenseType: form12.expenseType,
      expenseCategoryId: form12.expenseCategoryId,
      name: e.target.value
    }
    const resonse1 = await addData("expense/getallexpenses", bodyData1)
    var _data = resonse1
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)
  }

  const handleChange0 = async (e) => {
    let myUser = { ...form0 }
    myUser[e.target.name] = e.target.value
    setform0(myUser)
    const bodyData = {
      expenseType: e.target.value
    }
    const resonse = await addData("expense/getcategoriesbytype", bodyData)
    var _data = resonse
    setform2(_data.data.category)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function
  const getAllProducts = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("expense/getallexpenses", bodyData)
    var _data = resonse
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)
  }

  const getAllNames = async () => {
    const resonse = await addData("expense/getexpensesname")
    var _data = resonse
    settypes(_data.data.expensNames)
  }

  const reports2 = [
    { title: "Today Amount", iconClass: "bx bx-customize", description: expcount.todaysAmount },
    { title: "Month Amount", iconClass: "bx bx-calendar-event", description: expcount.monthAmount },
    { title: "Total Amount", iconClass: "bx bx-id-card", description: expcount.totalAmount },
  ]


  const handleChangedata = async (e) => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const bodydate = {
      dates: e.target.value,
      expenseType: form12.expenseType,
      expenseCategoryId: form12.expenseCategoryId
    }
    const resonse = await addData("expense/getallexpenses", bodydate)
    var _data = resonse
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)
  }

  const [datastate, setDatastate] = useState([])
  console.log(datastate)

  let dates = [];

  const handleDateChange = async (NewDate) => {
    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
    dates.push(date1);
    dates.push(date2);
    setDatastate(dates)
    const bodydate = {
      dates: dates,
      expenseType: form12.expenseType,
      expenseCategoryId: form12.expenseCategoryId,
      name: form12.name,
    }
    const resonse = await addData("expense/getallexpenses", bodydate)
    var _data = resonse
    dates = [];
    setexpcount(_data.data.cards)
    setProducts(_data.data.expenseResult)
    setFilter(_data.data.expenseResult)

  };



  useEffect(() => {
    getAllProducts()
    getAllNames()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Date",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Business Unit",
      selector: row => row.expenseType,
      sortable: true,
    },
    {
      name: "Category",
      selector: row => row.category,
      sortable: true,
    },
    {
      name: "Sub-Category",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: row => (
        row.attachment == undefined ? (
          'No Image'
        ) :
          <img
            className="p-2"
            src={imgUrl + row.attachment}
            style={{ width: "70px", height: "50px" }}
          />
      ),
      sortable: true,
    },
    {
      name: "Amount",
      selector: row => row.amount,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status == "true" ? "Active" : "Inactive",
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = prodects.filter((data) => {
      return (
        (data.expenseType && data.expenseType.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.category && data.category.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.name && data.name.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addcategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("categoryId", form.categoryId)
    dataArray.append("name", form.name)
    dataArray.append("description", form.description)
    dataArray.append("amount", form.amount)
    dataArray.append("date", form.date || new Date().toISOString().split('T')[0])
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("attachment", Files[i])
    }
    try {
      const resonse = await addData("expense/addexpenses", dataArray)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ name: "", expenseId: "", description: "", amount: "", date: "" })
      setFiles({ attachment: "" })
      getAllProducts()
      setmodal_small(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCategory = async e => {
    e.preventDefault()
    const dataArray = new FormData()
    dataArray.append("categoryId", form1.expenseCategoryId)
    dataArray.append("name", form1.name)
    dataArray.append("description", form1.description)
    dataArray.append("amount", form1.amount)
    dataArray.append("date", form1.date)
    dataArray.append("status", form1.status)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("attachment", Files1[i])
    }
    try {
      const resonse = await updateData(
        "expense/editexpenses/" + form1._id,
        dataArray
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setFiles1({ attachment: "" })
      setmodal_small1(false)
      getAllProducts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deleteCategory = async data => {
    try {
      const resonse = await deletedData("expense/delete_expenses/" + data._id, {})
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllProducts()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you want to Delete?")
    if (confirmBox === true) {
      deleteCategory(data)
    }
  }

  const getpopup = async (data) => {
    const bodyData = {
      expenseType: data.expenseType
    }
    const resonse = await addData("expense/getcategoriesbytype", bodyData)
    var _data = resonse
    setform2(_data.data.category)

    setform1(data)
    setform0(data)
    tog_small1()

  }

  const [imgview, setimgview] = useState(false)
  const [imgview1, setimgview1] = useState([])


  function openmodels(data) {
    setimgview(!imgview)
    setimgview1(data)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Expenses" />
          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={12}>
              <Row>
                <Col xl="12">
                  <Row>
                    {reports2.map((report, key) => (
                      <Col md="3" key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <span>{report.title}</span>
                                <h5 className="text-muted">{report.description}</h5>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={report.iconClass + " font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <div>

                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div>
                            <div className="row">
                              <div className="col-lg-3">

                                <Button
                                  onClick={() => {
                                    setmodal_small(true)
                                  }}
                                  color="primary"
                                >
                                  Add Expense <i className="bx bx-plus-circle" />
                                </Button>
                              </div>
                              <div className="col-lg-6" />
                              <div className="col-lg-3">
                                <div >
                                  <input
                                    value={search}
                                    onChange={e => {
                                      setSearch(e.target.value)
                                    }}
                                    type="text"
                                    className="form-control mb-3 ml-3"
                                    placeholder="Search..."
                                  />
                                </div>
                                {/* <button style={{ float: "right" }}  className="btn btn-success">Filter</button> */}
                              </div>

                            </div>
                            <div className="row">
                              <Col >
                                <select
                                  required
                                  name="expenseType"
                                  value={form12.expenseType}
                                  onChange={e => {
                                    handleChange12(e)
                                  }}
                                  className="form-select" >
                                  <option value="">Select Business Unit</option>
                                  <option value="Swimming">Swimming</option>
                                  <option value="Snooker">Snooker</option>
                                  <option value="Cafe">Cafe</option>
                                  <option value="Miscellaneous">Miscellaneous</option>
                                </select>
                              </Col>
                              <Col >
                                <select
                                  required
                                  name="expenseCategoryId"
                                  value={form12.expenseCategoryId}
                                  onChange={e => {
                                    handleChange13(e)
                                  }}
                                  className="form-select"
                                >
                                  <option value="">Select Category</option>
                                  {form21.map((data, key) => (
                                    <option key={key} value={data._id}>
                                      {data.category}
                                    </option>
                                  ))}
                                </select>
                              </Col>

                              <Col >
                                <select
                                  required
                                  name="name"
                                  value={form12.name}
                                  onChange={e => {
                                    handleChange14(e)
                                  }}
                                  className="form-select"
                                >
                                  <option value="">Select Sub Category</option>
                                  {types.map((data, key) => (
                                    <option key={key} value={data}>
                                      {data}
                                    </option>
                                  ))}
                                </select>
                              </Col>

                              <div className={form.dates == 'custom' ? "col-lg" : "col-lg offset-lg"}>
                                <select onChange={(e) => { handleChangedata(e) }} name="dates" className="form-select">
                                  <option value="">Select</option>
                                  <option value="today">Today</option>
                                  <option value="yesterday">Yesterday</option>
                                  <option value="weekly">Week</option>
                                  <option value="monthly">Month</option>
                                  <option value="custom">Custom date</option>
                                </select>
                              </div>
                              {form.dates == 'custom' ? (
                                <div className="col">
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="date"
                                    onChange={(e) => {
                                      handleDateChange(e);
                                    }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              ) : ''}
                             

                            </div>
                          </div>
                        }
                      // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Expense
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                addcategory(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                    Business Unit <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="expenseType"
                      value={form0.expenseType}
                      onChange={e => {
                        handleChange0(e)
                      }}
                      className="form-select" >
                      <option value="">Select</option>
                      <option value="Swimming">Swimming</option>
                      <option value="Snooker">Snooker</option>
                      <option value="Cafe">Cafe</option>
                      <option value="Miscellaneous">Miscellaneous</option>
                    </select>

                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Category Name <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="categoryId"
                      value={form.categoryId}
                      onChange={e => {
                        handleChange(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {form2.map((data, key) => (
                        <option key={key} value={data._id}>
                          {data.category}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Sub-Category <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Sub-Category"
                      required
                      name="name"
                      value={form.name}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      File
                    </Label>
                    <Input
                      type="file"
                      className="form-control"
                      // id="basicpill-firstname-input1"
                      placeholder="Enter Image"
                      name="file"
                      value={Files.image}
                      onChange={changeHandler}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Amount <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Amount"
                      required
                      name="amount"
                      value={form.amount}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Expense date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Tax"
                      required
                      name="date"
                      defaultValue={new Date().toISOString().split('T')[0]}
                      min={new Date().toISOString().split('T')[0]}
                      // value={form.date}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      name="description"
                      value={form.description}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <div className="mt-4" style={{ float: "right" }}>
                <Button
                  className="m-2"
                  onClick={() => {
                    setshow(!show)
                  }}
                  color="danger"
                  type="submit"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-2" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Expense
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCategory(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                    Business Unit <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="expenseType"
                      value={form0.expenseType}
                      onChange={e => {
                        handleChange0(e)
                      }}
                      className="form-select" >
                      <option value="">Select</option>
                      <option value="Swimming">Swimming</option>
                      <option value="Snooker">Snooker</option>
                      <option value="Cafe">Cafe</option>
                      <option value="Miscellaneous">Miscellaneous</option>
                    </select>

                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Category Name <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="expenseCategoryId"
                      value={form1.expenseCategoryId}
                      onChange={(e) => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {form2.map((data, key) => (
                        <option key={key} value={data._id}>
                          {data.category}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Sub-Category <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Sub-Category"
                      required
                      name="name"
                      value={form1.name}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <Label for="basicpill-firstname-input1">
                          File
                        </Label>
                      </Col>
                      <Col className="text-end">
                        <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a>
                      </Col>
                    </Row>
                    <Input
                      type="file"
                      className="form-control"
                      // id="basicpill-firstname-input1"
                      placeholder="Enter Image"
                      name="file"
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Amount <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Amount"
                      required
                      name="amount"
                      value={form1.amount}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Expense date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Tax"
                      required
                      name="date"
                      value={form1.date}
                      onChange={(e) => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      name="description"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="true">Active</option>
                      <option value="false">In Active</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>


        <Modal
          size="sm"
          isOpen={imgview}
          toggle={() => {
            setimgview()
          }}
        >
          <div style={{ borderBottom: "0px" }} className="modal-header">
            <button
              onClick={() => {
                setimgview(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              {imgview1.attachment == undefined ? "No Image" :
                <img src={imgUrl + imgview1.attachment} />
              }
            </Row>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Expense
