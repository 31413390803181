import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"
import { CSVLink } from "react-csv"

const Snookerbookings = () => {
  const history = useHistory()
  const [prodects, setProducts] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])
  const [form, setform] = useState([])
  const [excelprodects, setexcelprodects] = useState([])



  // get all function

  const getAllProducts = async () => {
    const resonse = await addData("swimmingbooking/getallswimmingbookings")
    var _data = resonse
    setProducts(_data.data.swimmingBooking)
    setFilter(_data.data.swimmingBooking)
    setexcelprodects(_data.data.excel)
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Booked On",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Booking Date",
      selector: row => row.bookingDate,
      sortable: true,
    },
    {
      name: "Booking Id",
      selector: row => row.bookingNo,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: row => row.bookingSlot == "A" ? "Slot A" : "Slot B",
      sortable: true,
    },
    {
      name: "Customers",
      selector: row => row.customerName,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: row => row.customerPhone,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: row => row.moneyType,
      sortable: true,
    },
    {
      name: "Price",
      selector: row => "₹ " + row.subAmount,
      sortable: true,
    },
    {
      name: "Tax",
      selector: row => "₹ " + row.gst,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: row => "₹ " + row.totalPrice,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="warning"
            outline

          >
            <i className="bx bx-show"></i>
          </Button>

        </>
      ),
    },
  ]

  useEffect(() => {
    const result = prodects.filter((data) => {
      return (
        (data.bookingNo && data.bookingNo.toLowerCase().includes(search.toLowerCase()))
        || (data.customerName && data.customerName.toLowerCase().includes(search.toLowerCase()))
        || (data.customerPhone && data.customerPhone.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  const getpopup = (data) => {
    sessionStorage.setItem('swimbookid', data._id)
    history.push('/swimmingbookingview')
  }
  
  const handleChange = async (e) => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const bodydate = {
      dates: e.target.value
    }
    const resonse = await addData("swimmingbooking/filterswimmingbookings", bodydate)
    var _data = resonse
    setProducts(_data.data.swimmingBooking)
    setFilter(_data.data.swimmingBooking)
    setexcelprodects(_data.data.excel)
  }

  let dates = [];

  const handleDateChange = async (NewDate) => {
    const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
    const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
    dates.push(date1);
    dates.push(date2);
    console.log(dates)
    const bodydate = {
      dates: dates
    }
    const resonse = await addData("swimmingbooking/filterswimmingbookings", bodydate)
    var _data = resonse
    dates = [];
    setProducts(_data.data.swimmingBooking)
    setFilter(_data.data.swimmingBooking)
    setexcelprodects(_data.data.excel)

  };

  const csvReport = {
    filename: "Swimming Payments",
    data: excelprodects,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Payments" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}


          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Products List</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    {/* <Button
                      onClick={() => {
                        setshow(!show)
                      }}
                      color="primary"
                    >
                      Add Table <i className="bx bx-plus-circle" />
                    </Button> */}
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row" >
                            {/* <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            /> */}
                            <div className={form.dates == 'custom' ? 'col-4' : 'col-4'}>

                              <select onChange={(e) => { handleChange(e) }} name="dates" className="form-select">
                                <option value="">Select</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="weekly">Week</option>
                                <option value="monthly">Month</option>
                                <option value="custom">Custom date</option>
                              </select></div>
                            <div className="col-4">
                              {form.dates == 'custom' ? (
                                <Flatpickr
                                  placeholder="Select date"
                                  className="form-control"
                                  name="date"
                                  onChange={(e) => {
                                    handleDateChange(e);
                                  }}
                                  options={{
                                    mode: "range",
                                    dateFormat: "d M, Y",
                                  }}
                                />
                              ) : ''}
                            </div>
                            <div style={{ float: "right" }} className="col-4">
                              <CSVLink {...csvReport}>
                                <Button
                                  style={{ float: "right" }}
                                  className="text-white bg-success"
                                >
                                  <i className="bx bx-cloud-download" /> Excel
                                </Button>
                              </CSVLink>
                            </div>

                          </div>
                        }
                      // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />


      </div>
    </React.Fragment>
  )
}

export default Snookerbookings
