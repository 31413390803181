import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import logoLightPng from "../../assets/images/raglogo.png";
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Ledgerbalance = () => {

  const [incomess, setincomes] = useState([])

  // get all function

  const getAllProducts = async () => {
    const resonse = await addData("finance/ledger")
    var _data = resonse
    setincomes(_data.data)
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  // Filter for reports
  const [dates, setDates] = useState([]);

  const handleDateChange = async (newDates) => {
    if (newDates.length === 0) {
      // If no dates are selected, handle accordingly (e.g., show all data)
      // Add your logic here
    } else {
      const date1 = format(new Date(newDates[0]), 'yyyy-MM-dd');
      const date2 = format(new Date(newDates[1]), 'yyyy-MM-dd');
      const bodydate = {
        dates: [date1, date2],
      };
      setDates(newDates);
      const response = await addData('finance/ledger', bodydate);
      const _data = response.data; // Assuming the data is in response.data
      setincomes(_data);
    }
  };

  const clearDates = async() => {
    setDates([]); 
    const resonse = await addData("finance/ledger")
    var _data = resonse
    setincomes(_data.data)
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Belwails" breadcrumbItem="Ledger Balance" />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <div className="text-center mb-4">
                      <img src={logoLightPng} />
                    </div>
                    <Row>
                      <Col md="12" style={{ float: "right" }}>
                        <div style={{ float: "right" }}>
                          <Button style={{ height: "33px" }} onClick={clearDates} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                        </div>

                        <div style={{ float: "right" }}>
                          <Flatpickr
                            placeholder="Select date"
                            className="form-control"
                            name="date"
                            value={dates}
                            onChange={(e) => { handleDateChange(e) }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              showClearButton: true,
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive mt-2">
                      <Table bordered responsive>
                        {/* <thead>
                            <tr className="text-center">
                                <th colSpan="2"><h4>Expenses</h4></th>
                                <th colSpan="2"><h4>Incomes</h4></th>
                            </tr>
                        </thead> */}
                        <tbody>
                          <tr>
                            <th>Particulars</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                          </tr>
                          <tr>
                            <th>Swimming Pool</th>
                            <td >₹ {incomess?.expenses?.swimmingTotalExpenses}</td>
                            <td >₹ {incomess?.incomes?.swimmingTotalIncome}</td>
                            <td>₹ {incomess?.balance?.swimmingBalance}</td>
                          </tr>
                          <tr>
                            <th>Snooker Parlour</th>
                            <td >₹ {incomess?.expenses?.snookerTotalExpenses}</td>
                            <td >₹ {incomess?.incomes?.snookerTotalIncome}</td>
                            <td>₹ {incomess?.balance?.snookerBalance}</td>
                          </tr>
                          <tr>
                            <th>POS</th>
                            <td >₹ {incomess?.expenses?.posTotalExpenses}</td>
                            <td >₹ {incomess?.incomes?.posTotalIncome}</td>
                            <td>₹ {incomess?.balance?.posBalance}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>₹ {incomess?.totalExpenes}</td>
                            <td>₹ {incomess?.totalIncomes}</td>
                            <td>₹  {incomess?.totalBalance}</td>
                          </tr>
                          {/* <tr className="text-center">
                                <td colSpan="4">
                                    <h4 className="mt-2">Total Balance : <span className="text-success">₹ {incomess?.profit}</span></h4>
                                </td>
                            </tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Ledgerbalance
