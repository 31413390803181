import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    CardFooter,
    Form,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    CardTitle,
    FormGroup,
    Toast,
    Table,
} from "reactstrap"
import classnames from "classnames"

import { addData, updateData } from "Servicescalls"
import { imgUrl } from "Baseurls"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, useHistory, Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-2.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate";


const UserProfile = () => {
    const history = useHistory()
    //meta title
    //  document.title="Profile | Belwails - React Admin & Dashboard Template";

    const dispatch = useDispatch()

    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [idx, setidx] = useState(1)

    const { error, success } = useSelector(state => ({
        error: state.Profile.error,
        success: state.Profile.success,
    }))

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                setname(obj.displayName)
                setemail(obj.email)
                setidx(obj.uid)
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                setname(obj.username)
                setemail(obj.email)
                setidx(obj.uid)
            }
            setTimeout(() => {
                dispatch(resetProfileFlag())
            }, 3000)
        }
    }, [dispatch, success])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: name || "",
            idx: idx || "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your UserName"),
        }),
        onSubmit: values => {
            dispatch(editProfile(values))
        },
    })

    const [activeTab1, setactiveTab1] = useState("1")
    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab)
        }
    }

    const [admindata, setAdmindata] = useState([])
    const [posts, setposts] = useState([])
    const [followingss, setfollowingss] = useState([])
    const [followierss, setfollowierss] = useState([])
    const [blockeduser, setblockeduser] = useState([])

    const [form, setForm] = useState([])
    const [useid, setuseid] = useState([])
    const [password, setPassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })

    const handleChange1 = e => {
        const myForm = { ...form }
        myForm[e.target.name] = e.target.value
        setForm(myForm)
    }
    const handleChange = e => {
        const myForm = { ...password }
        myForm[e.target.name] = e.target.value
        setPassword(myForm)
    }

    const bodydata = sessionStorage.getItem('userid') || useid

    // get user data
    const getAdmindata = async () => {
        const bodydatas = {
            id: bodydata
        }
        const response = await addData("customer/getbyid", bodydatas)
        var _data = response.data
        setAdmindata(_data.result)
        setForm(_data.result)
    }
    // get User posts
    const getAllPosts = async () => {
        const response = await addData("socialmedia/getuserposts/" + bodydata)
        var _data = response.data
        setposts(_data.userposts)
    }

    const redirectpost = (data) => {
        sessionStorage.setItem('postid', data._id)
        history.push('/postsdetails')
    }

    // get User followings
    const getAllFollowings = async () => {
        const response = await addData("socialmedia/getfollowingbycustomerid/" + bodydata)
        var _data = response.data
        setfollowingss(_data.followings)
    }
    // get User followings
    const getAllFollowers = async () => {
        const response = await addData("socialmedia/getfollowersbycustomerid/" + bodydata)
        var _data = response.data
        setfollowierss(_data.followers)
    }
    // get Blocked Users
    const getAllBlockedusers = async () => {
        const response = await addData("socialmedia/getcustomersusersblocked/" + bodydata)
        var _data = response.data
        setblockeduser(_data.blockedUsers)
    }

    const redirectuser = async (data) => {
        sessionStorage.setItem('userid', data.customerObjId)
        setuseid(data.customerObjId)
        getAdmindata()
        getAllPosts()
        getAllFollowings()
        getAllFollowers()
        getAllBlockedusers()
    }

    // edit admin
    const editAdmindata = async e => {
        e.preventDefault()
        const bodydata = {
            name: form.name,
            email: form.email,
            phone: form.phone,
            address: form.address,
            status: form.status,
        }
        try {
            const response = await updateData("customer/edit/" + form._id, bodydata)
            var _data = response.data
            console.log(_data)
            toast.success(_data.message)
            getAdmindata()
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    useEffect(() => {
        getAdmindata()
        getAllPosts()
        getAllFollowings()
        getAllFollowers()
        getAllBlockedusers()
    }, [])

    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * listPerPage;
    const lists = posts.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(posts.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    // followings 
    const [listPerPage1] = useState(5);
    const [pageNumber1, setPageNumber1] = useState(0);
    const pagesVisited1 = pageNumber1 * listPerPage1;
    const lists1 = followingss.slice(pagesVisited1, pagesVisited1 + listPerPage1);
    const pageCount1 = Math.ceil(followingss.length / listPerPage1);
    const changePage1 = ({ selected }) => {
        setPageNumber1(selected);
    };

    // followers 
    const [listPerPage2] = useState(5);
    const [pageNumber2, setPageNumber2] = useState(0);
    const pagesVisited2 = pageNumber2 * listPerPage2;
    const lists2 = followierss.slice(pagesVisited2, pagesVisited2 + listPerPage2);
    const pageCount2 = Math.ceil(followierss.length / listPerPage2);
    const changePage2 = ({ selected }) => {
        setPageNumber2(selected);
    };

    // Blocked users 
    const [listPerPage3] = useState(5);
    const [pageNumber3, setPageNumber3] = useState(0);
    const pagesVisited3 = pageNumber3 * listPerPage3;
    const lists3 = blockeduser.slice(pagesVisited3, pagesVisited3 + listPerPage3);
    const pageCount3 = Math.ceil(blockeduser.length / listPerPage3);
    const changePage3 = ({ selected }) => {
        setPageNumber3(selected);
    };




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Belwails" breadcrumbItem="User Details" />
                    <Row className="mb-2">
                        <Col md="12"><Button style={{ float: "right" }} onClick={() => { history.goBack() }} color="warning" > <i className="bx bx-left-arrow-alt" /> Back</Button></Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs="12">
                                            <div className="text-primary p-3 mb-5">

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <Row>
                                        <Col sm="12">
                                            <div className="profile-user-wid">
                                                <div className="text-center">
                                                    <Label >
                                                        {/* <Input name="profilepic" style={{ display: "none" }} type="file" /> */}
                                                        <img
                                                            type="button"
                                                            style={{ width: "100px", height:"100px" }}
                                                            src={imgUrl + admindata.profilePic}
                                                            // src={`imgUrl${admindata.profilePic}`}
                                                            alt="profilepic"

                                                            className="img-thumbnail rounded-circle"
                                                        />
                                                    </Label>

                                                </div>
                                            </div>
                                            <h5 className="font-size-15 text-center mt-1">
                                                # {admindata.name}
                                            </h5>
                                            <p className="font-size-15 mt-4 text-truncate text-dark">
                                                Details
                                            </p>
                                            <Row className="mb-3">
                                                <div className="col col-5">
                                                    <span className=""> Name</span>
                                                </div>
                                                <div className="col col-7">
                                                    <span>: {admindata.name}</span>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="col col-5">
                                                    <span className=""> Username</span>
                                                </div>
                                                <div className="col col-7">
                                                    <span>: {admindata.userName}</span>
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <div className="col col-5">
                                                    <span className="">Email</span>
                                                </div>
                                                <div className="col col-7">
                                                    <span>:{admindata.email}</span>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <div className="col col-5">
                                                    <span className="">Phone number</span>
                                                </div>
                                                <div className="col col-7">
                                                    <span>: {admindata.phone}</span>
                                                    <br />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <div className="col col-5">
                                                    <span className="">Status</span>
                                                </div>
                                                <div className="col col-7">
                                                    <span>: {admindata.status == true ? "Active" : "Blocked"}</span>
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <span><b>Blocked Reason:</b>{admindata.status == true?(""):admindata.reason} </span>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="8">
                            <Card>
                                <CardBody>
                                    <div className="mt-3">
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("1")
                                                    }}
                                                >
                                                    Update
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("2")
                                                    }}
                                                >
                                                    View All Posts
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "3",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("3")
                                                    }}
                                                >
                                                    View Followings
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "4",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("4")
                                                    }}
                                                >
                                                    View Followers
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="border border-primary rounded m-1">
                                                <NavLink
                                                    style={{ cursor: "pointer", padding: "5px" }}
                                                    className={classnames({
                                                        active: activeTab1 === "5",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("5")
                                                    }}
                                                >
                                                    Blocked Users
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                        <TabPane tabId="1">
                                            <form
                                                onSubmit={e => {
                                                    editAdmindata(e)
                                                }}
                                            >
                                                <h5 className="mb-4">Edit Details</h5>
                                                <Row>
                                                    <Col md={4} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Name <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            name="name"
                                                            value={form.name}
                                                            placeholder="Enter Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            required
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={4} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Email <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            placeholder="Enter Email"
                                                            type="email"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            name="email"
                                                            value={form.email}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col md={4} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Phone No. <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input
                                                            placeholder="Enter Number"
                                                            type="number"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            name="phone"
                                                            value={form.phone}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                        />
                                                    </Col>
                                                    {/* <Col md={6} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Status <span className="text-danger">*</span>
                                                        </Label>
                                                        <select
                                                            name="status"
                                                            value={form.status}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                            className="form-select"
                                                        >
                                                            <option value="true">Active</option>
                                                            <option value="false">Blocked</option>
                                                        </select>
                                                    </Col> */}
                                                    <Col md={4} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            City 
                                                        </Label>
                                                        <Input
                                                            placeholder="No data."
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            name="phone"
                                                            disabled
                                                            value={form.city}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={4} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Area 
                                                        </Label>
                                                        <Input
                                                            placeholder="No data."
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            name="phone"
                                                            disabled
                                                            value={form.area}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={12} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Address 
                                                        </Label>
                                                        <textarea
                                                            placeholder="Enter Address"
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom01"
                                                            name="address"
                                                            value={form.address}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                        />
                                                    </Col>
                                                    {/* <Col md={6} className="mt-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Status <span className="text-danger">*</span>
                                                        </Label>
                                                        <select
                                                            name="status"
                                                            value={form.status}
                                                            onChange={e => {
                                                                handleChange1(e)
                                                            }}
                                                            className="form-select"
                                                        >
                                                            <option value="active">Active</option>
                                                            <option value="inactive">In Active</option>
                                                        </select>
                                                    </Col> */}
                                                  
                                                </Row>

                                                <div className="mt-3" style={{ float: "right" }}>
                                                    <Button
                                                        style={{ width: "150px" }}
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </form>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Table bordered className="responsive">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Total views</th>
                                                        <th>Total Like</th>
                                                        <th>Total comment</th>
                                                        <th>Status</th>
                                                        <th style={{ width: "80px" }}> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists.length == 0 ? (
                                                        <tr>
                                                            <td colSpan="4">No Posts..</td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {lists.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>{(pageNumber - 1) * 5 + key + 6}</td>
                                                                    <td>{data.description}</td>
                                                                    <td>{data.views}</td>
                                                                    <td>{data.likes}</td>
                                                                    <td>{data.comments}</td>
                                                                    <td>{data.isBlocked == true ? "Blocked" : "Active"}</td>
                                                                    <td>
                                                                        <div>
                                                                            <a onClick={() => { redirectpost(data) }}> <i className="fa fa-eye text-warning m-1" /> </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists.length}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Table bordered className="responsive">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User Name</th>
                                                        <th>Email</th>
                                                        <th>Phone No.</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists1.length == 0 ? (
                                                        <tr>
                                                            <td colSpan="4">No Followings..</td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {lists1.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                                                    <td><a className="text-primary" onClick={() => { redirectuser(data) }}> {data.name} </a></td>
                                                                    <td>{data.email}</td>
                                                                    <td>{data.phone}</td>

                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount1}
                                                    onPageChange={changePage1}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists1.length}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Table bordered className="responsive">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User Name</th>
                                                        <th>Email</th>
                                                        <th>Phone No.</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists2.length == 0 ? (
                                                        <tr>
                                                            <td colSpan="4">No Followers..</td>
                                                        </tr>
                                                    ) : (
                                                        <>

                                                            {lists2.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                                                    <td><a className="text-primary" onClick={() => { redirectuser(data) }}> {data.name} </a></td>
                                                                    <td>{data.email}</td>
                                                                    <td>{data.phone}</td>

                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount2}
                                                    onPageChange={changePage2}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists2.length}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Table bordered className="responsive">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User Name</th>
                                                        <th>Email</th>
                                                        <th>Phone No.</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lists3.length == 0 ? (
                                                        <tr>
                                                            <td colSpan="4">No Blocked Users..</td>
                                                        </tr>
                                                    ) : (
                                                        <>

                                                            {lists3.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                                                    <td><a className="text-primary" onClick={() => { redirectuser(data) }}> {data.name} </a></td>
                                                                    <td>{data.email}</td>
                                                                    <td>{data.phone}</td>

                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <div className="mt-3" style={{ float: "right" }}>

                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount3}
                                                    onPageChange={changePage3}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"disabled"}
                                                    activeClassName={"active"}
                                                    total={lists3.length}
                                                />
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Toaster />

                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(UserProfile)
