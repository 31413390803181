import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Suppliers = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [show, setshow] = useState(false)
  const [customer, setcustomers] = useState([])
  const [form, setform] = useState({ supplier: "", address: "", phone: "", email: "", state: "", city: "", zipCode: "" })
  const [form1, setform1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])
  const [states, setstates] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllCustomers = async () => {
    const resonse = await addData("supplier/getall")
    var _data = resonse
    setcustomers(_data.data.supplierResult)
    setFilter(_data.data.supplierResult)
  }

  const getAllStates = async () => {
    const resonse = await addData("state/get")
    var _data = resonse
    setstates(_data.data.activeStateResult)
  }

  useEffect(() => {
    getAllCustomers()
    getAllStates()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Name",
      selector: row => row.supplier,
      sortable: true,
    },
    // {
    //   name: "Email",
    //   selector: row => row.email,
    //   sortable: true,
    // },
    {
      name: "Phone",
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: "State",
      selector: row => row.state,
      sortable: true,
    },
    {
      name: "Address",
      selector: row => row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },

    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = customer.filter((data) => {
      return (
        (data.supplier && data.supplier.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.email && data.email.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.phone && data.phone.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      supplier: form.supplier,
      email: form.email,
      phone: form.phone,
      address: form.address,
      state: form.state,
      city: form.city,
      zipCode: form.zipCode,
    }
    try {
      const resonse = await addData("supplier/add", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ supplier: "", address: "", phone: "", email: "", state: "", city: "", zipCode: "" })
      getAllCustomers()
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      supplier: form1.supplier,
      email: form1.email,
      phone: form1.phone,
      address: form1.address,
      state: form1.state,
      city: form1.city,
      zipCode: form1.zipCode,
      status: form1.status,
    }
    try {
      const resonse = await addData("supplier/edit/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1({ supplier: "", address: "", phone: "", email: "", state: "", city: "", zipCode: "" })
      setmodal_small(false)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Delete fuction
  const deletebenners = async data => {
    try {
      const resonse = await deletedData(
        "supplier/delete/" + data._id,
        {}
      )
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [forms, setforms] = useState([])
  console.log(forms)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Suppliers" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          {show == true ? (
            <Card>
              <CardHeader className="bg-white">
                <CardTitle>Add Supplier</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={e => {
                    addCustomer(e)
                  }}
                >
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          required
                          name="supplier"
                          value={form.supplier}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Email 
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Email"
                          
                          name="email"
                          value={form.email}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Phone <span className="text-danger">*</span>
                        </Label>
                        <Input
                          // type="number"
                          type="text"
                          maxLength="10"
                          pattern="[0-9]{10}"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Phone No"
                          required
                          name="phone"
                          value={form.phone}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          States <span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-select"
                          required
                          name="state"
                          value={form.state}
                          onChange={e => {
                            handleChange(e)
                          }}
                        >
                          <option value="">Select</option>
                          {states.map((data, key) => (
                            <option key={key} value={data.stateName}>{data.stateName}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          City <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter City"
                          required
                          name="city"
                          value={form.city}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Zip Code <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          maxLength="6"
                          // pattern="[0-5]{6}"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Zip Code"
                          required
                          name="zipCode"
                          value={form.zipCode}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Address <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Address"
                          required
                          name="address"
                          value={form.address}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4" style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setshow(false)
                      }}
                      color="danger"
                      type="button"
                      className="m-2"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button className="m-2" color="success" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                {/* <CardHeader className="bg-white">
                  <CardTitle>Customers List</CardTitle>
                </CardHeader> */}

                <CardBody>

                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                color="primary"
                                onClick={() => {
                                  setshow(!show)
                                }}
                              >
                                Add Supplier <i className="bx bx-plus-circle" />
                              </Button>
                            </div>
                            <div className="col-md-6">
                              <div style={{ float: "right" }}>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control "
                                  placeholder="Search..."
                                />
                              </div>
                            </div>
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Supplier
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCustomer(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="supplier"
                      value={form1.supplier}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Email 
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Email"
                      name="email"
                      value={form1.email}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Phone <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      maxLength="10"
                      pattern="[0-9]{10}"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Phone No"
                      required
                      name="phone"
                      value={form1.phone}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      States <span className="text-danger">*</span>
                    </Label>
                    <select
                      className="form-select"
                      required
                      name="state"
                      value={form1.state}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    >
                      <option value="">Select</option>
                      {states.map((data, key) => (
                        <option key={key} value={data.stateName}>{data.stateName}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      City <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter City"
                      required
                      name="city"
                      value={form1.city}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Zip Code <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      maxLength="6"
                      pattern="[0-5]{6}"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Zip Code"
                      required
                      name="zipCode"
                      value={form1.zipCode}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">In Active</option>
                    </select>
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Address <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Address"
                      required
                      name="address"
                      value={form1.address}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>

              </Row>
              <div className="mt-4" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Suppliers
