import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link, useLocation  } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Departments = () => {
    const history = useHistory()
    const location = useLocation();
    const showToast = location.state && location.state.showToast;
  const [customer, setcustomers] = useState([])
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])


  // get all function

  const getAllRoles = async () => {
    const resonse = await addData("role/getall")
    var _data = resonse
    setcustomers(_data.data.roleResult)
    setFilter(_data.data.roleResult)
  }

  const getpopup =(data)=>{
    sessionStorage.setItem("roleid", data._id)
    history.push("/editrole")
  }

  useEffect(() => {
    getAllRoles()
    if (showToast) {
      toast();
    }
  }, [])

  const coloums = [
    {
      name: "#",
      filterable: true,
      disableFilters: true,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Role",
      selector: row => row.role,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },
    {
      name: "Date",
      selector: row => (row.logModifiedDate).slice(0, 10),
      sortable: true,
    },

    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}

            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button>
        </>
      ),
    },
  ]

  useEffect(() => {
    const result = customer.filter((data) => {
      return (
        (data.role && data.role.toLowerCase().includes(search.toLowerCase())) ||
        (data.status && data.status.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

    // Delete fuction
    const deletebenners = async data => {
        try {
          const resonse = await deletedData(
            "role/delete/" + data._id,
            {}
          )
          var _data = resonse
          console.log(_data)
          toast.success(_data.data.message)
          getAllRoles()
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message)
          } else {
            toast.error("An error occurred. Please try again.")
          }
        }
      }
    
      const manageDelete = data => {
        const confirmBox = window.confirm("Do you want to Delete?")
        if (confirmBox === true) {
          deletebenners(data)
        }
      }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Roles & Permissions" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>

            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Departments </CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                         <div className="row">
                          <div className="col-md-6">
                          <Link to="/addrole"><Button  color="primary">Add Role <i className="bx bx-plus-circle" /></Button></Link> 
                          </div>
                          <div className="col-md-6">
                          <div style={{ float: "right" }}>
                          <input
                            value={search}
                            onChange={e => {
                              setSearch(e.target.value)
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                          />
                          </div>
                          </div>
                         </div>
                        }
                        // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Departments;
