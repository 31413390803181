import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import avatar from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import swim from "../../assets/images/crypto/blog/swim.jpg"

const Posts = () => {
    const history = useHistory()
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small1, setmodal_small1] = useState(false)
    const [post, setposts] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState({ title: "", description: "" })
    const [form1, setform1] = useState([])
    console.log(form1)
    const [form2, setform2] = useState([])
    const [Files1, setFiles1] = useState({ file: "" })
    const [Files, setFiles] = useState({ file: "" })

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    const changeHandler = (e) => {
        setFiles(e.target.files)
    }
    const changeHandler1 = (e) => {
        setFiles1(e.target.files)
    }

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    function tog_small1() {
        setmodal_small1(!modal_small1)
        removeBodyCss()
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllPosts = async () => {
        const resonse = await addData("socialmedia/getallposts")
        var _data = resonse
        setposts(_data.data.userposts)
        setFilter(_data.data.userposts)
    }

    useEffect(() => {
        getAllPosts()
    }, [])

    const redirectuser = (data) => {
        sessionStorage.setItem('userid', data.customerId)
        history.push('/viewuser')
    }
    const redirectpost = (data) => {
        sessionStorage.setItem('postid', data._id)
        history.push('/postsdetails')
    }

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        // {
        //   name: "Title",
        //   selector: row => row.description,
        //   sortable: true,
        // },
        {
            name: "Username",
            selector: row => <a className="text-primary" onClick={() => { redirectuser(row) }} >{row.customerName}</a>,
            sortable: true,
        },
        {
            name: "Total views",
            selector: row => row.views,
            sortable: true,
        },
        // {
        //   name: "Total Like",
        //   selector: row => row.likes,
        //   sortable: true,
        // },
        // {
        //   name: "Total comment",
        //   selector: row => row.comments,
        //   sortable: true,
        // },
        // {
        //   name: "Status",
        //   selector: row => row.isBlocked == true?"Blocked":"Active",
        //   sortable: true,
        // },
        {
            name: "Date & Time",
            selector: row => row.date + row.time,
            sortable: true,
        },
        {
            name: "Story",
            selector: row => <img type="button" onClick={() => { openmodels(row) }} style={{ width: "100px", height: "100px" }} src={swim} />,
            sortable: true,
        },
        // {
        //   name: "Action",
        //   selector: row => (
        //     <>
        //       <Button
        //         onClick={() => {
        //           redirectpost(row)
        //         }}
        //         className="mr-2"
        //         style={{ padding: "6px", margin: "3px" }}
        //         color="success"
        //         outline
        //       >
        //         <i className="fa fa-eye"></i>
        //       </Button>
        //       <Button
        //         onClick={() => {
        //           manageDelete(row)
        //         }}
        //         style={{ padding: "6px", margin: "3px" }}
        //         color="danger"
        //         outline
        //       >
        //         <i className="bx bx-trash"></i>
        //       </Button>
        //     </>
        //   ),
        // },
    ]

    useEffect(() => {
        const trimmedSearch = search.trim();
        const result = post.filter((data) => {
            return (
                (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
                (data.description?.toLowerCase?.().includes(trimmedSearch.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);


    // Add fuction
    const addPosts = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("title", form.title)
        dataArray.append("description", form.description)
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("file", Files[i])
        }
        try {
            const resonse = await addData("post/add", dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setmodal_small1(false)
            setFiles({ file: "" })
            setform({ title: "", description: "" })
            getAllPosts()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Edit fuction
    const editbenners = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("title", form1.title)
        dataArray.append("description", form1.description)
        dataArray.append("status", form1.status)
        for (let i = 0; i < Files1.length; i++) {
            dataArray.append("file", Files1[i])
        }
        try {
            const resonse = await updateData("post/edit/" + form1._id, dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setFiles1({ file: "" })
            setform1({ title: "", description: "" })
            setmodal_small(false)
            getAllPosts()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Delete fuction
    const deletebenners = async data => {
        try {
            const resonse = await updateData(
                "socialmedia/deletepost/" + data._id,
                {}
            )
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllPosts()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = data => {
        const confirmBox = window.confirm("Do you really want to Delete?")
        if (confirmBox === true) {
            deletebenners(data)
        }
    }

    const getpopup = data => {
        setform1(data)
        tog_small()
    }

    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])
    console.log(imgview1)


    function openmodels(data) {
        setimgview1(data)
        setimgview(!imgview)
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Stories" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader className="bg-white">
                                    {/* <CardTitle>Posts </CardTitle> */}
                                </CardHeader>

                                <CardBody>
                                    <div>
                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div style={{ float: "right" }}>
                                                        <input
                                                            value={search}
                                                            onChange={e => {
                                                                setSearch(e.target.value)
                                                            }}
                                                            type="text"
                                                            className="form-control mb-3"
                                                            placeholder="Search..."
                                                        />
                                                    </div>
                                                }
                                                subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>



                    </Row>
                </Container>

                <Modal
                    isOpen={imgview}
                    toggle={() => {
                        setimgview()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setimgview(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <img style={{ width: "100%" }} src={swim} />
                        </Row>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Posts
