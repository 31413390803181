import React, { useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Modal,
  Button,
} from "reactstrap"
import Select from "react-select"
import toast, { Toaster } from "react-hot-toast"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"

//Import Images

import food1 from "../../assets/images/brands/food1.jpg"
import popcorn from "../../assets/images/brands/pop_corn.jpg"
import burger from "../../assets/images/brands/burger.jpg"
import mohito from "../../assets/images/brands/mohito.jpg"
import success from "../../assets/images/brands/success.gif"
import { saveAs } from "file-saver"

import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"

const EcommerceCheckout = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small2, setmodal_small2] = useState(false)

  const [products, setproducts] = useState([])
  const [ordercount, setordercount] = useState(1)

  function removeCartItem(_id) {
    var filtered = products.filter(function (item) {
      return item._id !== _id
    })

    setproducts(filtered)
  }

  useEffect(() => {
    setordercount(products.map((product) => 1));
  }, [products]);

  const countUP = async (product, index) => {
    setordercount((prevCounts) =>
      prevCounts.map((count, i) => (i === index ? count + 1 : count))
    );

  }

  const countDown = async (product, index) => {
    setordercount((prevCounts) =>
      prevCounts.map((count, i) => (i === index ? Math.max(count - 1, 1) : count))
    );
  }

  const [subcategory, setsubcategory] = useState([])


  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  // get all function
  const getAllCategories = async () => {
    const resonse = await addData("swimming/swimmingaccessories/getactive")
    var _data = resonse
    setsubcategory(_data.data.swimmingAccessories)


  }

  // get all search function
  const getAllsearch = async (e) => {
    const resonse = await addData("swimming/swimmingaccessories/getactive?searchQuery=" + e.target.value)
    var _data = resonse
    setsubcategory(_data.data.swimmingAccessories)
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  const getData = (data, key) => {
    setproducts(prevProducts => {
      const existingProductNames = prevProducts.map(value => value.subCategory);
      if (existingProductNames.includes(data.subCategory)) {
        toast.error("Product is already added");
        return prevProducts;
      } else {
        return [...prevProducts, data];
      }
    });
  };

  // Add products
  const addProducts = async e => {
    e.preventDefault()
    const productData = products.map((data, index) => ({
      productId: data._id,
      productName: data.subCategory,
      quantity: ordercount[index],
      tax: data.tax,
      price: data.price,
    }))

    const bodydata = {
      customerId: selectedGroup.value || form1._id,
      subAmount: subamount,
      gst: parseFloat(sumtax),
      totalAmount: subamount + parseFloat(sumtax),
      products: productData,
      moneyType: monytypes || "Cash",
    }
    try {
      const resonse = await addData("order/addorder", bodydata)
      var _data = resonse
      console.log(_data)
      // toast.success(_data.data.message)
      setinvoice(_data.data.order)
      setmodal_small(false)
      setmodal_small2(true)
      getAllCategories()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const saveDatainsess =()=>{
    sessionStorage.setItem('acccount', ordercount)
    sessionStorage.setItem('assData', JSON.stringify(products))
    history.push("/addswimmingbookings")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Accessories" />

          <div className="checkout-tabs">
            <Row>
              <Col md="8">
                <Row>
                  {/* <Col md="2 p-1">
                    <Nav className="flex-column" pills>
                      {category.map((data, key) => (
                        <NavItem
                          key={key}
                          onClick={() => {
                            getAllSubcategories(data)
                          }}
                        >
                          <NavLink className={`navcardshadow ${activeCategory === data || category1 == data? 'active' : ''}`}>
                            <img src={imgUrl + data.image} />
                            <p className="font-weight-bold">{data.name}</p>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col> */}
                  <Col md="12">
                    <div className="mb-3">
                      <Row>
                        <Col md="6">
                          <Input onChange={getAllsearch} type="text" className="rounded-pill" placeholder="Search..." />

                        </Col>
                        <Col md="6"></Col>
                      </Row>
                    </div>
                  
                        <div>
                          <Row
                            style={{
                              maxHeight: "700px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {subcategory.length == 0 ? (
                              <Card>
                                <CardBody>
                                  <div className="text-center">
                                    <h5>No Data...</h5>
                                  </div>
                                </CardBody>
                              </Card>
                            ) : (
                              <>
                                {subcategory.map((data, key) => (
                                  <Col key={key} md="3">
                                    <Card>
                                      <div className="text-center ">
                                        <img
                                          className="rounded-top p-2"
                                          src={imgUrl + data.image}
                                          style={{
                                            height: "120px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>{" "}
                                      <CardBody style={{ padding: "10px" }}>
                                        <h6 style={{ fontSize: "14px" }}>{data.name.length > 15 ? data.name.substring(0, 15) + "..." : data.name}</h6>
                                        {/* <span>{data.description.length > 60 ? data.description.substring(0, 60) + "..." :data.description}</span> */}
                                        <Row className="mt-3">
                                            <Col>
                                            <h6 className="mt-2">
                                              ₹ {data.amount}
                                            </h6>
                                            </Col>
                                          <Col>
                                          <Button className="btn-sm w-100"
                                                onClick={() => {
                                                  getData(data, key)
                                                }}
                                                color="warning"
                                              >
                                                Add +
                                              </Button>
                                          </Col>

                                          {/* <Col className="text-end">
                                            {" "}
                                            <h6 className="mt-2 text-muted">
                                              {data.quantity} items
                                            </h6>
                                          </Col> */}

                                          {/* <Col className="mt-2" md="12">
                                            {data.quantity == '0' ? (
                                              <Button disabled className="btn-sm w-100"
                                                onClick={() => {
                                                  getData(data, key)
                                                }}
                                                style={{ width: "110px" }}
                                                color="danger"
                                              >
                                                Out of Stock
                                              </Button>
                                            ) : (
                                              <Button className="btn-sm w-100"
                                                onClick={() => {
                                                  getData(data, key)
                                                }}
                                                style={{ width: "100px" }}
                                                color="warning"
                                              >
                                                Add +
                                              </Button>
                                            )}

                                          </Col> */}

                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Row>
                        </div>
                  
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <Form
                    //   onSubmit={e => {
                    //     addProducts(e)
                    //   }}
                    >
                      <div >
                        <CardTitle className="mb-3">Accessories List</CardTitle>
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Table className="table align-middle mb-0 table-nowrap">
                            <tbody>
                              {products.map((product, index) => (
                                <tr key={product._id}>
                                  <td>
                                    <img
                                      src={imgUrl + product.image}
                                      alt="product-img"
                                      title="product-img"
                                      className="avatar-md"
                                    />
                                  </td>

                                  <td>
                                    <h5 className="font-size-14 text-truncate">
                                      {product.name}
                                    </h5>
                                    <p className="mb-0">
                                      Price :{" "}
                                      <span className="fw-medium">
                                        ₹ {product.amount}
                                      </span>
                                    </p>
                                    <div style={{ width: "120px" }}>
                                      <div className="input-group mt-2">
                                        <div className="input-group-append">
                                          <button
                                            type="button"
                                            className="btn-primary rounded-left"
                                            onClick={() => countDown(product, index)}
                                       
                                          >
                                            -
                                          </button>
                                        </div>
                                        <Input
                                          style={{ height: "26px" }}
                                          className="text-center"
                                          type="text"
                                          value={ordercount[index]}
                                          name="demo_vertical"
                                          readOnly
                                        />
                                        <div className="input-group-prepend">
                                          <button
                                            type="button"
                                            className="btn-primary rounded-right"

                                            onClick={() => countUP(product, index)}
                                          // onClick={() => {
                                          //   countUP(
                                          //     product._id,
                                          //     ordercount
                                          //   )
                                          // }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        removeCartItem(product._id)
                                      }
                                      className="action-icon text-danger"
                                    >
                                      {" "}
                                      <i className="mdi mdi-trash-can font-size-18" />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        
                      </div>
                 
                      <div>
                       
                        <Button
                          type="button"
                          style={{ width: "100%" }}
                          color="success"
                          onClick={()=>{saveDatainsess()}}
                        >
                          Add Accessories <i className="bx bx-check-circle" />
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default EcommerceCheckout
