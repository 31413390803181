import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Snookerbookings = () => {
  const history = useHistory()
  const [prodects, setProducts] = useState([])
  const [prodects1, setProducts1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])



  // get all function

  const getAllProducts = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("swimmingbooking/getallswimmingbookings", bodyData)
    var _data = resonse
    setProducts(_data.data.swimmingBooking)
    setProducts1(_data.data)
    setFilter(_data.data.swimmingBooking)
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Booked On",
      selector: row => <div>
        {row.date}<br /> ({row.bookingTime})
      </div>,
      sortable: true,
    },
    {
      name: "Booking Date",
      selector: row =><div>
      {row.bookingDate}<br /> ({row.logCreatedDate.slice(11, 16)})
    </div>,
      sortable: true,
    },
    {
      name: "Booking Id",
      selector: row => row.bookingNo,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: row => row.bookingSlot == "A" ? "Slot A" : "Slot B",
      sortable: true,
    },
    {
      name: "Customers",
      selector: row => row.customerName,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: row => row.customerPhone,
      sortable: true,
    },

    {
      name: "Total Price",
      selector: row => "₹ " + row.totalPrice,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: row => row.status == true?<p className="text-success">Success</p>:<p className="text-danger">Cancel</p>,
    //   sortable: true,
    // },
    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="warning"
            outline

          >
            <i className="bx bx-show"></i>
          </Button>

        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = prodects.filter((data) => {
      return (
        (data.bookingNo && data.bookingNo.toLowerCase().includes(trimmedSearch.toLowerCase()))
        || (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase()))
        || (data.customerPhone && data.customerPhone.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);



  const getpopup = (data) => {
    sessionStorage.setItem('swimbookid', data._id)
    history.push('/swimmingbookingview')
  }
  const [dates, setDates] = useState([]);

  const handleDateChange = async (NewDate) => {
    if (NewDate.length === 0) {

    } else {
      const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
      const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
      // const newDates = [date1, date2];
      const bodydate = {
        dates: [date1, date2]
      }
      setDates(NewDate);
      const response = await addData("swimmingbooking/getallswimmingbookings", bodydate);
      const _data = response;
      setProducts(_data.data.swimmingBooking)
      setProducts1(_data.data)
      setFilter(_data.data.swimmingBooking)
    }
  };

  const getReportData1 = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("swimmingbooking/getallswimmingbookings", bodyData)
    var _data = resonse
    setProducts(_data.data.swimmingBooking)
    setProducts1(_data.data)
    setFilter(_data.data.swimmingBooking)
    setDates([]);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Bookings" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}


          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Products List</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    {/* <Button
                      onClick={() => {
                        setshow(!show)
                      }}
                      color="primary"
                    >
                      Add Table <i className="bx bx-plus-circle" />
                    </Button> */}
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <Row>
                            <Col md="5">
                              <div className="mt-2">
                                <b className="mr-5"> Total Bookings :  <span className="text-primary">  {prodects1.count} </span></b>
                                <b> Total Amount : <span className="text-primary"> ₹ {prodects1.grandTotal}</span></b>
                              </div>
                            </Col>
                            <Col md="4" >
                              <Row>
                                <Col style={{ padding: "0px" }} md="9">
                                  <div >
                                    <Flatpickr
                                      placeholder="Select date"
                                      className="form-control"
                                      name="dates"
                                      value={dates}
                                      onChange={(e) => { handleDateChange(e) }}
                                      options={{
                                        mode: "range",
                                        dateFormat: "d M, Y",
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col style={{ padding: "0px" }} md="3">
                                  <div >
                                    <Button style={{ height: "33px" }} onClick={getReportData1} size="sm" outline color="danger" >Clear <i className="bx bx-x-circle" /></Button>
                                  </div>
                                </Col>
                              </Row>

                            </Col>
                            <Col md={3} >
                              <div>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control mb-3"
                                  placeholder="Search..."
                                />
                              </div>
                            </Col>
                          </Row>

                          // <div style={{ float: "right" }}>
                          //   <input
                          //     value={search}
                          //     onChange={e => {
                          //       setSearch(e.target.value)
                          //     }}
                          //     type="text"
                          //     className="form-control mb-3"
                          //     placeholder="Search..."
                          //   />
                          // </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />


      </div>
    </React.Fragment>
  )
}

export default Snookerbookings
