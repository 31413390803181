import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link, useHistory } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  let history = useHistory()

  const logout = () => {
    localStorage.clear()
    history.push("/logout")
  }

  const Authdetails = localStorage.getItem("authUser")
  const Auth = JSON.parse(Authdetails)
  const access = Auth?.userData?.permissions[0]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {access?.dashview == true || access?.dashview1 == true || access?.dashview2 == true || access?.all == true ? (
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            ) : ""}

            {access?.Administration == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user"></i>
                  <span>{props.t("Administration")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/departments">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Roles & Permissions")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/employees">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Employees")}</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/employereports">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Employee Reports")}</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
            ) : ""}
            {access?.users == true || access?.posts == true || access?.assets == true || access?.all == true ? (
              <li className="menu-title">{props.t("Social media")} </li>
            ) : ""}
            {access?.users == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-user-circle"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/customers">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Users")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/repotedusers">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("Reported Users")}</span>
                  </Link>
                </li> */}
                  <li>
                    <Link to="/userverification">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Blocked Users")}</span>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/userprofilecat">
                    <i className="bx bx-radio-circle-marked"></i>
                    <span>{props.t("User Profile Category")}</span>
                  </Link>
                </li> */}

                </ul>
              </li>
            ) : ""}

            {access?.posts == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-shield-quarter"></i>
                  <span>{props.t("Post")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/posts">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Post")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reportedpost">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Reported Post")}</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/stories">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Stories")}</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
            ) : ""}

            {access?.assets == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-devices"></i>
                  <span>{props.t("Assets")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/assets">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Assets")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reportreasons">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Report Reasons")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}

            {access?.cafe == true || access?.pos == true || access?.SnookerParlour || access?.Swimmingpool == true || access?.all == true ? (
              <li className="menu-title">{props.t("Bookings")} </li>
            ) : ""}
            {access?.cafe == true || access?.all == true ? (

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store"></i>
                  <span>{props.t("Cafe")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/category">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Category")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/products">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Products")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}
            {access?.pos == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-credit-card-alt"></i>
                  <span>{props.t("POS")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pos">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("New Sale")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Orders")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/income">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Income")}</span>
                    </Link>
                  </li>


                </ul>
              </li>
            ) : ""}

            {/* <li>
              <Link to="/category">
                <i className=""></i>
                <span>{props.t("")}</span>
              </Link> 
            </li>
            <li>
              <Link to="/products">
                <i className="bx bx-selection"></i>
                <span>{props.t("")}</span>
              </Link> 
            </li> */}
            {access?.SnookerParlour == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-doughnut-chart"></i>
                  <span>{props.t("Snooker Parlour")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/snookertables">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Tables")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/addsnookerbookings">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("New Bookings")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/snookerbookings">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Bookings")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/snookerpayments">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Payments")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}
            {access?.Swimmingpool == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-extension"></i>
                  <span>{props.t("Swimming pool")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/plans">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("plans")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/swimmingaccessories">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Accessories")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/addswimmingbookings">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("New Bookings")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/swimmingbookings">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Bookings")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/swimmingpayments">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Payments")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}
            {access?.Inventory == true || access?.Expenses == true || access?.Ledgerbalance == true || access?.all == true ? (
              <li className="menu-title">{props.t("Accounts")} </li>
            ) : ""}
            {access?.Inventory == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-unlink"></i>
                  <span>{props.t("Inventory")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/suppliers">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Suppliers")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/stocks">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Stock")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}
            {/* <li>
              <Link to="/plans">
                <i className=""></i>
                <span>{props.t("Plans")}</span>
              </Link>
            </li> */}
            {access?.Expenses == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-tone"></i>
                  <span>{props.t("Expenses")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/expencecategory">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Category")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/expense">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Expenses List")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}
            {/* <li>
              <Link to="/expense">
                <i className="bx bx-tone"></i>
                <span>{props.t("Expenses List")}</span>
              </Link>
            </li> */}
            {access?.Ledgerbalance == true || access?.all == true ? (
              <li>
                <Link to="/ledgerbalance">
                  <i className="bx bx-check-shield"></i>
                  <span>{props.t("Ledger balance")}</span>
                </Link>
              </li>
            ) : ""}


            {/* <li>
              <Link to="/departments">
                <i className="bx bx-git-merge"></i>
                <span>{props.t("Departments")}</span>
              </Link>
            </li>
            <li>
              <Link to="/employees">
                <i className="bx bx-user"></i>
                <span>{props.t("Employees")}</span>
              </Link>
            </li> */}
            {access?.LockerManagement == true || access?.Support == true || access?.CompanyPolicy == true || access?.all == true ? (
              <li className="menu-title">{props.t("Settings")} </li>
            ) : ""}
            {access?.LockerManagement == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-lock-open"></i>
                  <span>{props.t("Locker Management")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/lockers">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Book Locker")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/visitors">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Visitor Customers")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}

            {/* {access?.Support == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-coupon"></i>
                  <span>{props.t("Support")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/supportrequest">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("New Requests")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/completedrequests">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Request Response")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""} */}

            {access?.CompanyPolicy == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-book-bookmark"></i>
                  <span>{props.t("Company Policy")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/aboutus">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("About Us")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/claim">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Claim Policy")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/refund">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Refund Policy")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/termscondition">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Terms & Conditions")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Privacy Policy")}</span>
                    </Link>
                  </li>

                </ul>
              </li>
            ) : ""}
            {access?.Settings == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-timer"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/taxes">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Taxes")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/notifications">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/banners">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Banners")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/marquee">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Marquee")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/coupons">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Coupons")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/overal_reports">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>{props.t("Overall Reports")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : ""}

            {access?.reports == true ? (
              <li className="menu-title">{props.t("Reports")} </li>
            ) : ""}

            {access?.reports == true || access?.all == true ? (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Employee Reports")}</span>
                </Link>
                <ul className="sub-menu">
                  {access?.Swimmingpool == true || access?.all == true ? (

                    <li>
                      <Link to="/swimming_reports">
                        <i className="bx bx-radio-circle-marked"></i>
                        <span>{props.t("Swimming Pool")}</span>
                      </Link>
                    </li>
                  ) : ""}
                  {access?.SnookerParlour == true || access?.all == true ? (
                    <li>
                      <Link to="/snooker_reports">
                        <i className="bx bx-radio-circle-marked"></i>
                        <span>{props.t("Snooker Parlour")}</span>
                      </Link>
                    </li>
                  ) : ""}
                  {access?.pos == true || access?.all == true ? (
                    <li>
                      <Link to="/pos_reports">
                        <i className="bx bx-radio-circle-marked"></i>
                        <span>{props.t("Pos Orders")}</span>
                      </Link>
                    </li>
                  ) : ""}

                </ul>
              </li>
            ) : ""}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
