import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
import Select from "react-select"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Notifications = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [coupon, setCoupon] = useState([])
    const [form, setform] = useState({ title: "", fcmDescription: "", description: "" })
    const [form1, setform1] = useState([])
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState([])

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
        setform({ title: "", fcmDescription: "", description: "" })
        setselectedGroup([])
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllCoupon = async () => {
        const resonse = await addData("notification/showallnotification")
        var _data = resonse
        setCoupon(_data.data.notifyResult)
        setFilter(_data.data.notifyResult)
    }

    const [customers, setcustomers] = useState([])
    const getAllcustomers = async () => {
        const resonse = await addData("customer/getactivecutomers")
        var _data = resonse
        setcustomers(_data.data.result)
    }

    useEffect(() => {
        getAllCoupon()
        getAllcustomers()
    }, [])


    const options = customers.map(data => ({
        value: data._id,
        label: data.name,
        phone: data.phone,
    }))

    const [selectedGroup, setselectedGroup] = useState([])

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }
    function selectAllOptions() {
        setselectedGroup(options);
    }

    function clearSelection() {
        setselectedGroup([]);
    }


    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Date & Time",
            selector: row => row.date + ", " + row.time,
            sortable: true,
        },
        {
            name: "Customers",
            selector: row => row.user_ids.map((data) => (data.label + ",")),
            sortable: true,
        },
        {
            name: "Titles",
            selector: row => row.title,
            sortable: true,
        },
        // {
        //     name: "FCM",
        //     selector: row => row.title,
        //     sortable: true,
        // },
        {
            name: "Message",
            selector: row => row.description,
            sortable: true,
        },

        {
            name: "Action",
            selector: row => (
                <>
                    {/* <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="success"
                        outline
                    >
                        <i className="bx bx-edit "></i>
                    </Button> */}
                    <Button
                        onClick={() => {
                            manageDelete(row)
                        }}
                        style={{ padding: "6px", margin: "3px" }}
                        color="danger"
                        outline
                    >
                        <i className="bx bx-trash"></i>
                    </Button>
                </>
            ),
        },
    ]


    useEffect(() => {
        const trimmedSearch = search.trim();
        const result = coupon.filter((data) => {
            return (
                (data.title && data.title.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
                (data.status?.toLowerCase?.().includes(trimmedSearch.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    // Add fuction
    const addCustomer = async e => {
        e.preventDefault()
        const bodydata = {
            title: form.title,
            fcmDescription: form.fcmDescription,
            description: form.description,
            userList: selectedGroup,
        }
        try {
            const resonse = await addData("notification/add", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform({ title: "", fcmDescription: "", description: "" })
            setmodal_small(false)
            setselectedGroup([])
            getAllCoupon()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Edit fuction
    const editCustomer = async e => {
        e.preventDefault()
        const bodydata = {
            couponName: form1.couponName,
            type: form1.type,
            value: form1.value,
            status: form1.status,
        }
        try {
            const resonse = await addData("coupon/editbyid/" + form1._id, bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform1({ name: "", area: "", phone: "", email: "", status: "" })
            setmodal_small(false)
            getAllCoupon()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    //   Delete fuction
    const deletebenners = async data => {
        try {
            const resonse = await deletedData("notification/deletenotification/" + data._id, {})
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllCoupon()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = data => {
        const confirmBox = window.confirm("Do you really want to Delete?")
        if (confirmBox === true) {
            deletebenners(data)
        }
    }

    const getpopup = data => {
        setform1(data)
        tog_small()
    }

    const [forms, setforms] = useState([])
    console.log(forms)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Notifications" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Button onClick={() => { setmodal_small(true) }} color="primary">Add Notification</Button>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div style={{ float: "right" }}>
                                                                <input
                                                                    value={search}
                                                                    onChange={e => {
                                                                        setSearch(e.target.value)
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            // subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Add Notification
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                addCustomer(e)
                            }}
                        >
                            <div className="mb-3">
                                <Row>
                                    <Col>
                                        <Label for="basicpill-firstname-input1">
                                            Customers <span className="text-danger">*</span>
                                        </Label>
                                    </Col>
                                    {selectedGroup.length == 0 ? (
                                        <Col >
                                            <Label type="button" style={{float:"right"}} onClick={selectAllOptions} >Select all</Label>
                                        </Col>
                                    ) : (
                                        <Col >
                                            <Label type="button" style={{float:"right"}} onClick={clearSelection} >Clear all</Label>
                                        </Col>
                                    )}

                                </Row>

                                <Select
                                    onChange={handleSelectGroup}
                                    name="userList"
                                    options={options}
                                    value={selectedGroup}
                                    isMulti
                                    required
                                />

                            </div>

                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Title <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Title"
                                    required
                                    name="title"
                                    value={form.title}
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    FCM Description <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter FCM Description"
                                    required
                                    name="fcmDescription"
                                    value={form.fcmDescription}
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div> */}
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Message <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="number"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Message"
                                    required
                                    name="description"
                                    value={form.description}
                                    onChange={e => {
                                        handleChange(e)
                                    }}
                                />
                            </div>
                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button disabled={selectedGroup.length == 0} color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>

                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Notifications
