import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import avatar from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import swim from "../../assets/images/crypto/blog/swim.jpg"

const Posts = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [post, setposts] = useState([])
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [form, setform] = useState({ title: "", description: "" })
  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])
  const [Files1, setFiles1] = useState({ file: "" })
  const [Files, setFiles] = useState({ file: "" })

  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])

  const changeHandler = (e) => {
    setFiles(e.target.files)
  }
  const changeHandler1 = (e) => {
    setFiles1(e.target.files)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
    removeBodyCss()
  }


  // get all function

  const getAllPosts = async () => {
    const resonse = await addData("socialmedia/blockedposts")
    var _data = resonse
    setposts(_data.data.blockedPosts)
    setFilter(_data.data.blockedPosts)
  }

  useEffect(() => {
    getAllPosts()
  }, [])

  const redirectuser=(data)=>{
    sessionStorage.setItem('userid', data.customerId)
    history.push('/viewuser')
  }
  const redirectpost=(data)=>{
    sessionStorage.setItem('postid', data._id)
    history.push('/postsdetails')
  }

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Title",
      selector: row => row.description,
      sortable: true,
    },
    {
      name: "Username",
      selector: row => <a className="text-primary" onClick={()=>{redirectuser(row)}} >{row.customerName}</a>,
      sortable: true,
    },
    {
      name: "Total views",
      selector: row => row.views,
      sortable: true,
    },
    {
      name: "Total Like",
      selector: row => row.likes,
      sortable: true,
    },
    {
      name: "Total comment",
      selector: row => row.comments,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.isBlocked == true?"Blocked":"Active",
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: row => row.date + row.time,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
        {/* <Link to="/"> */}
          <Button
            onClick={() => {
              redirectpost(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="fa fa-eye"></i>
          </Button>
          {/* </Link> */}
         
        </>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = post.filter((data) => {
        return (
            (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
            (data.description?.toLowerCase?.().includes(trimmedSearch.toLowerCase()))
        );
    });
    setFilter(result);
}, [search]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Reported Post" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Posts </CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div style={{ float: "right" }}>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Posts
