import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Category = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small1, setmodal_small1] = useState(false)
    const [banner, setbanner] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState({ name: "", description: "", amount: "" })
    const [form1, setform1] = useState([])
    const [form2, setform2] = useState([])
    const [Files1, setFiles1] = useState({ image: "" })
    const [Files, setFiles] = useState({ image: "" })

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    const changeHandler = (e) => {
        setFiles(e.target.files)
    }
    const changeHandler1 = (e) => {
        setFiles1(e.target.files)
    }

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }
    function tog_small1() {
        setmodal_small1(!modal_small1)
        removeBodyCss()
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllCategories = async () => {
        const resonse = await addData("swimming/swimmingaccessories/getall")
        var _data = resonse
        setbanner(_data.data.swimmingAccessories)
        setFilter(_data.data.swimmingAccessories)
    }

    useEffect(() => {
        getAllCategories()
    }, [])

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true,
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Image",
            selector: row => (
                <img className="p-2"
                    src={imgUrl + row.image}
                    style={{ width: "100px", height: "50px" }}
                />
            ),
            sortable: true,
        },
        {
            name: "Purchase Amount",
            selector: row => row.purchaseAmount,
            sortable: true,
        },
        {
            name: "Quantitiy",
            selector: row => row.accessoryCount,
            sortable: true,
        },
        {
            name: "Selling Price",
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: "Description",
            selector: row => row.description.slice(0, 30),
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status == true ? "Active" : "Inactive",
            sortable: true,
        },
        {
            name: "Action",
            selector: row => (
                <>
                    <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="success"
                        outline
                    >
                        <i className="bx bx-edit "></i>
                    </Button>
                    <Button
                        onClick={() => {
                            manageDelete(row)
                        }}
                        style={{ padding: "6px", margin: "3px" }}
                        color="danger"
                        outline
                    >
                        <i className="bx bx-trash"></i>
                    </Button>
                </>
            ),
        },
    ]

    useEffect(() => {
        const trimmedSearch = search.trim();
        const result = banner.filter(data => {
            return data.name && data.name.toLowerCase().match(trimmedSearch.toLowerCase())
        })
        setFilter(result)
    }, [search])

    // Add fuction
    const addcategory = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("name", form.name)
        dataArray.append("description", form.description)
        dataArray.append("purchaseAmount", form.purchaseAmount)
        dataArray.append("accessoryCount", form.accessoryCount)
        dataArray.append("amount", form.amount)
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("image", Files[i])
        }
        try {
            const resonse = await addData("swimming/swimmingaccessories/add", dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setFiles({ image: "" })
            setform({ name: "", description: "", amount: "" })
            setmodal_small1(false)
            getAllCategories()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Edit fuction
    const editCategory = async (e) => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("name", form1.name)
        dataArray.append("status", form1.status)
        dataArray.append("description", form1.description)
        dataArray.append("purchaseAmount", form1.purchaseAmount)
        dataArray.append("accessoryCount", form1.accessoryCount)
        dataArray.append("amount", form1.amount)
        for (let i = 0; i < Files1.length; i++) {
            dataArray.append("image", Files1[i])
        }
        try {
            const resonse = await updateData("swimming/swimmingaccessories/editbyid/" + form1._id, dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setFiles1({ image: "" })
            setmodal_small(false)
            getAllCategories()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Delete fuction
    const deleteCategory = async data => {
        try {
            const resonse = await deletedData(
                "swimming/swimmingaccessories/delete/" + data._id,
                {}
            )
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllCategories()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = data => {
        const confirmBox = window.confirm("Do you want to Delete?")
        if (confirmBox === true) {
            deleteCategory(data)
        }
    }

    const getpopup = data => {
        setform1(data)
        tog_small()
    }

    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])


    function openmodels(data) {
        setimgview(!imgview)
        setimgview1(data)
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Accessories" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>

                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <Row>
                                                        <Col md={6}>
                                                            <Button
                                                                onClick={() => { setmodal_small1(!modal_small1) }}
                                                                color="primary">Add New <i className="bx bx-plus-circle" />
                                                            </Button>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div style={{ float: "right" }}>
                                                                <input
                                                                    value={search}
                                                                    onChange={e => {
                                                                        setSearch(e.target.value)
                                                                    }}
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Edit Accessories
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                editCategory(e)
                            }}
                        >
                            <Row>

                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                            name="name"
                                            value={form1.name}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>

                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">Image</Label> <a className="text-danger">(45 x 45)</a> <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a>

                                        <Input
                                            type="file"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter image"
                                            name="image"
                                            onChange={changeHandler1}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Purchase Amount <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Purchase Amount"
                                            required
                                            name="purchaseAmount"
                                            value={form1.purchaseAmount}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Quantity <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Quantity"
                                            required
                                            name="accessoryCount"
                                            value={form1.accessoryCount}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Price <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Price"
                                            required
                                            name="amount"
                                            value={form1.amount}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Status <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                            name="status"
                                            value={form1.status}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                            className="form-select"
                                        >
                                            <option value="true">Active</option>
                                            <option value="false">In Active</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Description <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Description"
                                            required
                                            name="description"
                                            value={form1.description}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={modal_small1}
                    toggle={() => {
                        tog_small1()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Add Accessories
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small1(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={(e) => {
                                addcategory(e)
                            }}
                        >
                            <Row >
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                            name="name"
                                            value={form.name}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Image <span className="text-danger">*</span> <a className="text-danger">(45 x 45)</a>
                                        </Label>
                                        <Input
                                            type="file"
                                            className="form-control"
                                            placeholder="Enter Image"
                                            required
                                            name="image"
                                            // value={Files.image}
                                            onChange={changeHandler}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Purchase Amount <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Purchase Amount"
                                            required
                                            name="purchaseAmount"
                                            value={form.purchaseAmount}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Quantity <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Quantity"
                                            required
                                            name="accessoryCount"
                                            value={form.accessoryCount}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Price <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Price"
                                            required
                                            name="amount"
                                            value={form.amount}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Description <span className="text-danger">*</span>
                                        </Label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Description"
                                            required
                                            name="description"
                                            value={form.description}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small1(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    size="sm"
                    isOpen={imgview}
                    toggle={() => {
                        setimgview()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setimgview(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <img src={imgUrl + imgview1.image} />
                        </Row>
                    </div>
                </Modal>
                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Category
