import React, { useState, useEffect, useRef } from "react"

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Modal,
  Button,
} from "reactstrap"
import Select from "react-select"
import toast, { Toaster } from "react-hot-toast"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"

//Import Images

import food1 from "../../assets/images/brands/food1.jpg"
import popcorn from "../../assets/images/brands/pop_corn.jpg"
import burger from "../../assets/images/brands/burger.jpg"
import mohito from "../../assets/images/brands/mohito.jpg"
import success from "../../assets/images/brands/success.gif"
import { saveAs } from "file-saver"

import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import { useReactToPrint } from "react-to-print"
import logoLightPng from "../../assets/images/raglogo.png"

const EcommerceCheckout = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small2, setmodal_small2] = useState(false)
  const [btnshows, setbtnshows] = useState(false)
  //meta title
  // document.title = "Checkout | Skote - React Admin & Dashboard Template"

  function tog_small2() {
    setmodal_small2(!modal_small2)

  }
  const modalclose = () => {
    setmodal_small2(false)
    setproducts([])
    setform({ name: "", phone: "", customvalue: false })
    setform2({couponAmount:''})
    setforminfo([])

  }


  const [activeTab, setactiveTab] = useState("1")
  console.log(activeTab)
  const [selectedGroup, setselectedGroup] = useState("")
  console.log(selectedGroup)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const printsecctions = () => {
    handlePrint()
    setmodal_small2(!modal_small2)
    setproducts([])
    setform({ name: "", phone: "", customvalue: false })
    setform2({couponAmount:''})
    setforminfo([])
  }



  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const [products, setproducts] = useState([])
  const [products1, setproducts1] = useState([])
  console.log(products)
  const [ordercount, setordercount] = useState(1)

  // const subamount = products.reduce(function (prev, current, index) {
  //   return prev + +current.price * current.quantity[index]
  // }, 0)
  // console.log(subamount)

  const caramout = products.map((data) => (parseFloat(data.price) * parseFloat(data.quantity)))
  // console.log(caramout)

  const subamount = caramout.reduce((acc, value) => {
    if (!isNaN(value)) {
      return acc + value;
    } else {
      return acc; // Skip NaN values
    }
  }, 0);

  const [sumtax, setsumtax] = useState(0)
  const getAlltaxes = async () => {
    const resonse = await addData("plan/getall")
    var _data = resonse
    setsumtax(_data.data.planResult[2].gst)
  }

  function removeCartItem(_id) {
    var filtered = products.filter(function (item) {
      return item._id !== _id
    })

    setproducts(filtered)
  }

  useEffect(() => {
    setordercount(products.map((product) => 1));
  }, [products]);

  const countUP = async (index, quantity) => {
    setexctra({
      cashPrice: ''
    })

    let myUser = [...products];
    myUser[index].quantity = parseFloat(quantity) + 1;
    setproducts(myUser);

    // setordercount((prevCounts) =>
    //   prevCounts.map((count, i) => (i === index ? count + 1 : count))
    // );
    // const getAllcustomers = async () => {
    const bodydata = {
      quantity: parseFloat(myUser[index].quantity)
    }
    try {
      const resonse = await addData("stock/checkstockquantity/" + myUser[index]._id, bodydata)
      var _data = resonse
      // toast.success(_data.data.message)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        // toast.error("Please try again.")
      }
    }

    // }
  }

  const quantityChange = async(e, index)=>{
    setexctra({
      cashPrice: ''
    })
    let myUser = [...products];
    myUser[index][e.target.name] = e.target.value;
    setproducts(myUser);
    const bodydata = {
      quantity: e.target.value
    }
    try {
      const resonse = await addData("stock/checkstockquantity/" + myUser[index]._id, bodydata)
      var _data = resonse
      // toast.success(_data.data.message)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("Please try again.")
      }
    }
  }

  const countDown = async (index, quantity) => {
    setexctra({
      cashPrice: ''
    })
    let myUser = [...products];
    myUser[index].quantity = Math.max(parseFloat(quantity) - 1, 1);
    setproducts(myUser);

    setordercount((prevCounts) =>
      prevCounts.map((count, i) => (i === index ? Math.max(count - 1, 1) : count))
    );
    const bodydata = {
      quantity: parseFloat(myUser[index].quantity)
    }
    try {
      const resonse = await addData("stock/checkstockquantity/" + myUser[index]._id, bodydata)
      var _data = resonse
      // toast.success(_data.data.message)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("Please try again.")
      }
    }
  }

  // function countUP(_id, prev_product) {
  //   // setordercount(_id === _id ?ordercount + 1:1);
  //   setordercount(
  //     products.map(p =>
  //       p._id === _id ? parseFloat(ordercount) + 1  : p
  //     )
  //   )
  // }

  // // function countDown(_id, prev_product) {
  // //   setproducts(
  // //     products.map(p =>
  // //       p._id === _id ? { ...p, product: prev_product - 1 } : p
  // //     )
  // //   )
  // // }
  // function countDown(_id, prev_product) {
  //   // setordercount(ordercount - 1);
  //   const updatedProducts = products.map(p =>
  //     p._id === _id ?Math.max(ordercount - 1, 1) : p
  //   );
  //   setordercount(updatedProducts);
  // }

  const [category, setcategory] = useState([])
  const [category1, setcategory1] = useState([])
  const [subcategory, setsubcategory] = useState([])
  const [customers, setcustomers] = useState([])
  const [form, setform] = useState({ name: "", phone: "", customvalue: false })
  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])
  const [invoice, setinvoice] = useState([])
  const [monytypes, setmonytypes] = useState([])
  const [exctra, setexctra] = useState([])
  const [balnceamss, setbalnceamss] = useState([])
  const [totalamounts, settotalamounts] = useState([])
  // console.log(totalamounts)

  // const totamount = subamount + parseFloat(sumtax)
  // settotalamounts(totamount)

  useEffect(() => {
    const totamount = parseFloat(subamount) + parseFloat(sumtax) - parseFloat(form2.couponAmount == "" || form2.couponAmount == undefined ? 0 : form2.couponAmount)
    settotalamounts(parseFloat(totamount).toFixed(2))
  }, [subamount, sumtax])


  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange2 = (e) => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
    const totalamount = parseFloat(subamount) + parseFloat(sumtax) - parseFloat(e.target.value == "" ? 0 : e.target.value)
    settotalamounts(parseFloat(totalamount).toFixed(2))
  }

  const handleChange000 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.checked
    setform(myUser)
  }

  const handleChangeAmount = (e) => {
    let myUser = { ...monytypes }
    myUser[e.target.name] = e.target.value
    setmonytypes(myUser)
  }
  const handleChangeexctra = (e) => {
    let myUser = { ...exctra }
    myUser[e.target.name] = e.target.value
    setexctra(myUser)
    const balnceam = totalamounts - e.target.value
    setbalnceamss(parseFloat(balnceam).toFixed(2))
  }

  // get all function
  const getAllCategories = async () => {
    const resonse = await addData("category/getactive")
    var _data = resonse
    setcategory(_data.data.result)
    setcategory1(_data.data.result[0])
    const bodydata = { categoryId: _data.data.result[0]._id }
    const resonse1 = await addData(
      "stock/getstocksbycategoryid",
      bodydata
    )
    var _data = resonse1
    setsubcategory(_data.data.stockResult)
  }

  const [activeCategory, setActiveCategory] = useState(null);

  console.log(activeCategory)
  const [subsearch, setsubsearch] = useState([]);

  const getAllSubcategories = async (data) => {
    const bodydata = { categoryId: data._id }
    const resonse = await addData(
      "stock/getstocksbycategoryid",
      bodydata
    )
    var _data = resonse
    setsubcategory(_data.data.stockResult)
    setcategory1([])
    setActiveCategory(data)
    setsubsearch(data)
  }

  const getAllcustomers = async () => {
    const resonse = await addData("customer/getactivecutomers")
    var _data = resonse
    setcustomers(_data.data.result)
  }

  const [forminfo, setforminfo] = useState([])
  const findcustomers = async (e) => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const bodydata = {
      phone: e.target.value,
    }
    const resonse = await addData("customer/getactivecutomers", bodydata)
    var _data = resonse
    // setform({ name: _data?.data?.result[0].name, phone: _data?.data?.result[0].phone, customvalue: false })
    setforminfo(_data?.data?.result[0])
  }

  // get all search function
  const getAllsearch = async (e) => {
    const bodydata = { categoryId: subsearch._id || category1._id }
    const resonse = await addData("stock/getstocksbycategoryid?searchQuery=" + e.target.value, bodydata)
    var _data = resonse
    setsubcategory(_data.data.stockResult)
  }

  // Add fuction
  const addCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      name: form.name,
      phone: form.phone,
    }
    try {
      const resonse = await addData("order/addcustomer", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setmodal_small(false)
      // setform({ name: "", phone: "" })
      setform1(_data.data.customer)
      getAllcustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  useEffect(() => {
    getAllCategories()
    getAllcustomers()
    getAlltaxes()
  }, [])


  // const getData = (data, key) => {
  //   if (products.map(value => value.name) == data.name) {
  //     toast.error("Product is allready added")
  //   } else {
  //     setproducts(prevProducts => {
  //       if (Array.isArray(prevProducts)) {
  //         return [...prevProducts, data]
  //       } else {
  //         return [data]
  //       }
  //     })
  //   }
  // }

  const getData = (data, key) => {
    console.log(data)
    setexctra({
      cashPrice: ''
    })

    setproducts(prevProducts => {
      const existingProductNames = prevProducts.map(value => value.subCategory);
      if (existingProductNames.includes(data.subCategory)) {
        toast.error("Product is already added");
        return prevProducts;
      } else {
        var resdata = {
          _id: data._id,
          image: data.image,
          subCategory: data.subCategory,
          price: data.price,
          tax: data.tax,
          quantity: 1
        };
        return [...prevProducts, resdata];
      }
    });
  };

  const options = customers.map(data => ({
    value: data._id,
    label: data.name + " / " + data.phone,
    phone: data.phone,
  }))

  const taxvalue = products.map(
    (data, index) => (parseFloat(data.price) * parseFloat(ordercount[index]) * parseFloat(data.tax)) / 100
  )

  // const sumtax = taxvalue.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue,
  //   0
  // )

  // Add products

  const addProducts = async e => {
    e.preventDefault()
    setbtnshows(true)
    const productData = products.map((data, index) => ({
      productId: data._id,
      productName: data.subCategory,
      quantity: data.quantity,
      tax: data.tax,
      price: parseFloat(data.price).toFixed(2),
    }))

    const bodydata = {
      customerId: form?.phone,
      name: form?.name,
      // customerId: selectedGroup.value || form1._id,
      subAmount: subamount,
      couponAmount: form2.length == 0 ? 0 : parseFloat(form2.couponAmount).toFixed(2) || 0,
      gst: parseFloat(sumtax),
      totalAmount: totalamounts.length == 0 ? parseFloat(subamount + parseFloat(sumtax)).toFixed(2) : totalamounts,
      products: productData,
      moneyType: monytypes.moneyType || "Cash",
      cashPrice: monytypes.moneyType == "Cash" ? subamount : exctra.cashPrice || 0,
      onlinePrice: monytypes.moneyType == "Card" ? subamount : balnceamss.length == 0 ? 0 : balnceamss,
    }
    try {
      const resonse = await addData("order/addorder", bodydata)
      var _data = resonse
      console.log(_data)
      // toast.success(_data.data.message)
      setinvoice(_data?.data?.order)
      setproducts1(_data?.data?.order?.products)
      setmodal_small(false)
      sessionStorage.setItem("orderid", _data.data._id)
      // history.push("/invoice")
      setmodal_small2(true)
      setbtnshows(false)
      getAllCategories()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
        setbtnshows(false)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  // const downloadImage = () => {
  //   history.push("/invoice")
  //   sessionStorage.setItem("orderid", invoicedata._id)
  //   // saveAs(imgUrl+ invoicedata.invoice)
  // }

  const [activeCategory1, setActiveCategory1] = useState(null);

  const handleOnClick = (value) => {
    setmonytypes(value)
    setActiveCategory1(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Pos" />

          <div className="checkout-tabs">
            <Row>
              <Col md="8">
                <Row>
                  <Col md="2 p-1">
                    <Nav className="flex-column" pills>
                      {category.map((data, key) => (
                        <NavItem
                          key={key}
                          onClick={() => {
                            getAllSubcategories(data)
                          }}
                        >
                          <NavLink className={`navcardshadow ${activeCategory === data || category1 == data ? 'active' : ''}`}>
                            <img style={{ height: "45px", width: "45px" }} src={imgUrl + data.image} />
                            <p className="font-weight-bold">{data.name}</p>
                          </NavLink>
                        </NavItem>
                      ))}

                      {/* <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            setactiveTab("2")
                          }}
                        >
                          <i className="bx bx-palette d-block check-nav-icon " />
                          <p className="font-weight-bold">Snacks</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => {
                            setactiveTab("3")
                          }}
                        >
                          <i className="bx bxs-badge-check d-block check-nav-icon " />
                          <p className="font-weight-bold">Burger</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => {
                            setactiveTab("4")
                          }}
                        >
                          <i className="bx bxs-coffee-alt d-block check-nav-icon " />
                          <p className="font-weight-bold">Drinks</p>
                        </NavLink>
                      </NavItem> 

                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "5" })}
                          onClick={() => {
                            setactiveTab("5")
                          }}
                        >
                          <i className="bx bxs-buoy d-block check-nav-icon " />
                          <p className="font-weight-bold">Ice Creams</p>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </Col>
                  <Col md="10">
                    <div className="mb-3">
                      <Row>
                        <Col md="6">
                          <Input onChange={getAllsearch} type="text" className="rounded-pill" placeholder="Search..." />

                        </Col>
                        <Col md="6"></Col>
                      </Row>
                      {/* <div className="col-auto">
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i style={{fontSize:"19px"}} className="bx bx-search-alt-2" />
                            </div>
                          </div>
                          <input
                            type="text"
                            onChange={getAllsearch}
                            className="form-control"
                            id="inlineFormInputGroup"
                            placeholder="Search..."
                          />
                        </div>
                      </div> */}
                      {/* <Input className="mb-3" type="text" /> */}
                    </div>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div>
                          <Row
                            style={{
                              maxHeight: "700px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {subcategory.length == 0 ? (
                              <Container>
                                <Card>
                                  <CardBody>
                                    <div className="text-center">
                                      <h5>No Data...</h5>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Container>
                            ) : (
                              <>
                                {subcategory.map((data, key) => (
                                  <Col key={key} md="4">
                                    <Card>
                                      <div className="text-center ">
                                        <img
                                          className="rounded-top"
                                          src={imgUrl + data.image}
                                          style={{
                                            height: "150px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>{" "}
                                      <CardBody style={{ padding: "10px" }}>
                                        <h6 style={{ fontSize: "14px" }}>{data.subCategory.length > 15 ? data.subCategory.substring(0, 15) + "..." : data.subCategory}</h6>
                                        {/* <span>{data.description.length > 60 ? data.description.substring(0, 60) + "..." :data.description}</span> */}
                                        <Row className="mt-2">
                                          <Col>
                                            {" "}
                                            <h6 className="mt-2">
                                              ₹ {data.price}
                                            </h6>
                                          </Col>
                                          <Col className="text-end">
                                            {" "}
                                            <h6 className="mt-2 text-muted">
                                              {data.quantity} items
                                            </h6>
                                          </Col>
                                          <Col className="mt-2" md="12">
                                            {data.quantity == '0' ? (
                                              <Button disabled className="btn-sm w-100"
                                                onClick={() => {
                                                  getData(data, key)
                                                }}
                                                style={{ width: "110px" }}
                                                color="danger"
                                              >
                                                Out of Stock
                                              </Button>
                                            ) : (
                                              <Button className="btn-sm w-100"
                                                onClick={() => {
                                                  getData(data, key)
                                                }}
                                                style={{ width: "100px" }}
                                                color="warning"
                                              >
                                                Add +
                                              </Button>
                                            )}

                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Row>
                        </div>
                      </TabPane>

                    </TabContent>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        addProducts(e)
                      }}
                    >
                      <div >
                        <CardTitle className="mb-3">Order Summary</CardTitle>
                        <div
                        // style={{
                        //   maxHeight: "300px",
                        // }}
                        >
                          <Row>
                            <Col md="6">
                              <div className="form-check form-switch form-switch-md" >
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleChange000(e)
                                  }}
                                  id="customSwitchsizemd1"
                                  className="form-check-input"
                                  value={form.customvalue}
                                  name="customvalue"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customSwitchsizemd1"
                                >
                                  Guest User
                                </label>
                              </div>

                            </Col>
                            <Col className="md-6">
                              {/* <Button
                                onClick={() => {
                                  setmodal_small(!modal_small)
                                }}
                                style={{ width: "100%" }}
                                color="primary"
                                outline
                                disabled={form.customvalue == true}
                              >
                                Add Customer <i className="bx bx-plus-circle" />
                              </Button> */}
                            </Col>
                          </Row>


                          {/* {form1.length == 0 ? (
                            <div className="mt-2 mb-2">
                              <Select
                                onChange={handleSelectGroup}
                                name="customerId"
                                options={options}
                                placeholder="Select Customer"
                                isDisabled={form.customvalue == true}
                              />
                            </div>
                          ) : (
                            <div className="table-responsive mt-2 mb-2">
                              <Table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th>Name :</th>
                                    <td>{form1.name}</td>
                                  </tr>
                                  <tr>
                                    <th>Mobile :</th>
                                    <td>{form1.phone}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          )} */}

                          <div>
                            <div className="mb-2 row">
                              <div style={{ padding: "1px" }} className="col-6">
                                {/* <label className="sr-only" htmlFor="inlineFormInputGroupUsername">Username</label> */}
                                {/* <div className="input-group"> */}
                                {/* <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Username" /> */}
                                <Input
                                  type="text"
                                  maxLength="10"
                                  pattern="[0-9]{10}"
                                  className="form-control"
                                  id="inlineFormInputGroupUsername"
                                  placeholder="Enter Customer Mobile No"

                                  name="phone"
                                  value={form?.phone}
                                  onChange={e => {
                                    findcustomers(e)
                                  }}
                                />
                                {/* <div className="input-group-prepend">
                                                                            <div type="button"
                                                                            //  onClick={() => { findcustomers() }} 
                                                                             style={{ padding: "5px" }} className="input-group-text">Search</div>
                                                                        </div>
                                                                    </div> */}
                              </div>
                              <div style={{ padding: "1px" }} className="col-6">
                                <Input
                                  type="text"
                                  pattern="^[a-zA-Z ]*$"
                                  className="form-control"
                                  id="basicpill-firstname-input1"
                                  placeholder="Enter Name"

                                  name="name"
                                  value={form?.name}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                              </div>
                              <div>
                                <a className="text-success">
                                  {forminfo?.name == undefined ? (
                                    ""
                                  ) : (
                                    <b>Customer Name: {forminfo?.name}</b>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <Table className="table align-middle mb-0 table-nowrap">
                            <tbody>
                              {products.map((product, index) => (
                                <tr key={product._id}>
                                  <td>
                                    <img
                                      src={imgUrl + product.image}
                                      alt="product-img"
                                      title="product-img"
                                      className="avatar-md"
                                    />
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 text-truncate">
                                      {/* {product.subCategory} */}
                                      {product.subCategory.length > 10 ? product.subCategory.substring(0, 10) + "..." : product.subCategory}
                                    </h5>
                                    <p className="mb-0">
                                      Price :{" "}
                                      <span className="fw-medium">
                                        ₹ {parseFloat(product.price * product.quantity).toFixed(2)}
                                      </span>
                                    </p>
                                    <div style={{ width: "120px" }}>
                                      <div className="input-group mt-2">
                                        <div className="input-group-append">
                                          <button
                                            type="button"
                                            className="btn-primary rounded-left"
                                            onClick={() => countDown(index, product.quantity)}
                                          // onClick={() => {
                                          //   countDown(
                                          //     product._id,
                                          //     ordercount
                                          //   )
                                          // }}
                                          >
                                            -
                                          </button>
                                        </div>
                                        <Input
                                          style={{ height: "26px" }}
                                          className="text-center"
                                          type="number"
                                          // value={product.quantity}
                                          value={products[index].quantity}
                                          // value={ordercount[index]}
                                          name="quantity"
                                          onChange={(e)=>{quantityChange(e, index)}}
                                        />
                                        <div className="input-group-prepend">
                                          <button
                                            type="button"
                                            className="btn-primary rounded-right"

                                            onClick={() => countUP(index, product.quantity)}
                                          // onClick={() => {
                                          //   countUP(
                                          //     product._id,
                                          //     ordercount
                                          //   )
                                          // }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        removeCartItem(product._id)
                                      }
                                      className="action-icon text-danger"
                                    >
                                      {" "}
                                      <i className="mdi mdi-trash-can font-size-18" />
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <td>Subtotal:</td>
                              <td className="text-end">₹ {parseFloat(subamount).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td> Tax : </td>
                              <td className="text-end">₹ {sumtax}</td>
                            </tr>
                            <tr>
                              <td>Discount: </td>
                              <td >
                                <Input
                                  name="couponAmount"
                                  value={form2.couponAmount}
                                  onChange={(e) => { handleChange2(e) }}
                                  style={{ float: "right" }}
                                  size="sm" className="w-50" placeholder="Enter Amount" type="number" />
                              </td>
                            </tr>
                            <tr>
                              <th>Total :</th>
                              <th className="text-end">₹ {totalamounts} </th>
                              {/* <th className="text-end">₹ {totalamounts.length == 0?(subamount + parseFloat(sumtax)):totalamounts} </th> */}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div>
                        <Row className="mt-3">
                          {/* <Col md="6">
                            <Card
                              onClick={() => handleOnClick("Cash")}
                              className={`test ${activeCategory1 === "Cash" ? 'border border-danger' : ''}`}
                            >
                              <div className="text-center">
                                <i
                                  style={{ fontSize: "20px" }}
                                  className="bx bx-money mt-2"
                                />
                                <p className="font-weight-bold">Cash</p>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card
                              onClick={() => handleOnClick("Card")}
                              className={`test ${activeCategory1 === "Card" ? 'border border-danger' : ''}`}
                            >
                              <div className="text-center">
                                <i
                                  style={{ fontSize: "20px" }}
                                  className="bx bx-credit-card mt-2"
                                />
                                <p className="font-weight-bold">Card</p>
                              </div>
                            </Card>
                          </Col> */}
                          <Col md="4" >
                            <div
                              className="form-check">
                              <input
                                type="radio"
                                onChange={(e) => {
                                  handleChangeAmount(e)
                                }}
                                id="Cash"
                                className="form-check-input"
                                value="Cash"
                                name="moneyType"

                              />
                              <label
                                className="form-check-label"
                                htmlFor="Cash"
                              >
                                Cash
                              </label>

                            </div>
                          </Col>
                          <Col md="4" >
                            <div
                              className="form-check">
                              <input
                                type="radio"

                                onChange={(e) => {
                                  handleChangeAmount(e)
                                }}
                                id="Online"
                                className="form-check-input"
                                value="Card"
                                name="moneyType"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Online"
                              >
                                Online
                              </label>

                            </div>
                          </Col>
                          <Col md="4" >
                            <div
                              className="form-check">
                              <input
                                type="radio"

                                onChange={(e) => {
                                  handleChangeAmount(e)
                                }}
                                id="Split"
                                className="form-check-input"
                                value="Split"
                                name="moneyType"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Split"
                              >
                                Split
                              </label>

                            </div>
                          </Col>
                        </Row>
                        {monytypes.moneyType == "Split" ? (
                          <Row className="mt-3">
                            <Col>
                              <Input
                                onChange={(e) => { handleChangeexctra(e) }}
                                max={totalamounts}
                                value={exctra.cashPrice} required name="cashPrice" type="number" placeholder="Enter Cash Amount" />
                            </Col>
                            <Col>
                              <Input value={balnceamss} disabled name="onlinePrice" type="number" placeholder="Enter Online Amount" />
                            </Col>
                          </Row>
                        ) : ""}
                        <Button
                          type="submit"
                          style={{ width: "100%" }}
                          color="success"
                          className="mt-3"
                          disabled={btnshows}
                        >
                          Print <i className="bx bx-check-circle" />
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Toaster />
        <Modal
          size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Customer
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                addCustomer(e)
              }}
            >
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      pattern="^[a-zA-Z ]*$"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={form.name}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Mobile No <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      maxLength="10"
                      pattern="[0-9]{10}"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Mobile No"
                      required
                      name="phone"
                      value={form.phone}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={modal_small2}
          toggle={() => {
            tog_small2()
          }}
        >
          <div className="modal-body">
            <div className="">
              <Button
                onClick={printsecctions}
                className="m-2"
                color="success"
              >
                <i className="bx bx-printer"></i> Proceed If thermal
                printer is ready
              </Button>
              <Button
                onClick={() => { modalclose() }}
                className="m-2" color="danger">
                <i className="bx bx-left-arrow-alt"></i> Cancel
              </Button>
              <hr />
            </div>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <div ref={componentRef}>
                  <div className="modal-body">
                    <div id="printableArea">
                      <div className="initial-38-1">
                        {/* <div className="pt-3">
                          <img src={logoLightPng} className="initial-38-2 mb-2" alt="" />
                        </div> */}
                        <div className="text-center">
                          <h5 style={{fontSize:"12px"}} className="text-break initial-38-4">
                            Name : {invoice.customerName}
                          </h5>
                          <h5 style={{fontSize:"12px"}} className="initial-38-4 initial-38-3">
                            Phone : {invoice.customerPhone}
                          </h5>
                        </div>
                        <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                        <div className="row">
                          <div className="col-6">
                            <h5 style={{ fontSize: "12px", fontWeight: "800" }}>Order ID
                              <span style={{marginBottom:"0px"}} className="font-light"> {invoice.orderNo}</span>
                            </h5>
                          </div>
                          <div className="col-6">
                            <h5 style={{ fontSize: "12px", fontWeight: "800" }}>
                              <span className="font-light">
                                {invoice.date} - {invoice.time}
                              </span>
                            </h5>
                          </div>
                          <div className="col-12">
                          </div>
                        </div>
                        <h5 style={{ margin: "0px" }} className="text-uppercase" />
                        <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                        <table style={{ fontSize: "10px", marginBottom: "7px", border: "2px solid black" }} className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{padding:"5px"}} className="initial-38-6 border-top-0 border-bottom-0">SNo</th>
                              <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Description</th>
                              <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products1.map((data, key) => (
                              <tr key={key}>
                                <td style={{padding:"5px"}}>{key + 1}</td>
                                <td style={{padding:"5px"}}>
                                  <div>
                                    <h5 style={{fontSize:"12px"}}><b>{data.quantity} - {data.stockName}</b></h5>
                                    {/* <b>Qty :</b> <span><b></b></span>
                                    <br />
                                    <b>Unit Price :</b>
                                    <span> <b>₹ {data.amount}</b></span>
                                    <br /> */}
                                  </div>
                                </td>
                                <td style={{padding:"5px"}} className="w-28p"> <b>₹ {data.amount * data.quantity}</b></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="px-3">
                          <dl style={{ margin: "0px" }} className="row text-right justify-content-center">
                            <dt style={{ margin: "0px" }} className="col-6">Subtotal :</dt>
                            <dd style={{ margin: "0px" }} className="col-6">₹ {invoice.subAmount}</dd>
                            <dt style={{ margin: "0px" }} className="col-6">Discount :</dt>
                            <dd style={{ margin: "0px" }} className="col-6">₹ {invoice.couponAmount == "NaN" ? 0 : invoice.couponAmount} </dd>
                            <dt style={{ margin: "0px" }} className="col-6">GST :</dt>
                            <dd style={{ margin: "0px" }} className="col-6">
                              ₹{invoice.gst}
                              <hr style={{marginBottom:"5px", marginTop:"5px"}} />
                            </dd>
                            <dt className="col-6 font-20px">Total:</dt>
                            <dd className="col-6 font-20px">₹ {invoice.totalAmount}</dd>
                            <dt className="col-6 font-20px">Money Type:</dt>
                            <dd className="col-6 font-20px">{invoice.moneyType}</dd>
                          </dl>
                          <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                          <h5 style={{ fontWeight: "800" }} className="text-center">
                            <span className="d-block">"""THANK YOU"""</span>
                          </h5>
                          <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                          <span className="d-block text-center">Copyright @ Belwails</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="2"></Col>
            </Row>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EcommerceCheckout
