import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import logoLightPng from "../../assets/images/raglogo.png"
import userimg from "../../assets/images/brands/userimg.png"
import { useReactToPrint } from "react-to-print"

const OrderDetails = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const [invoice, setinvoice] = useState([])
  const [products, setproducts] = useState([])
  const [phone, setphone] = useState([])

  const getAllOrders = async () => {
    const bodydata = {
      id: sessionStorage.getItem("orderid"),
    }
    const resonse = await addData("order/getbyid", bodydata)
    var _data = resonse
    setinvoice(_data.data.orderResult)
    setproducts(_data?.data?.orderedProducts)
    setphone(_data?.data?.orderResult?.customerPhone)
  }

  useEffect(() => {
    getAllOrders()
  }, [])

  const downloadImage = () => {
    history.push("/invoice")
    sessionStorage.setItem("orderid", invoice._id)
    // saveAs(imgUrl+ data.invoice) 
  }
  const handleChange1 = (e) => {
    let myUser = { ...invoice }
    myUser[e.target.name] = e.target.value
    setinvoice(myUser)
    myUser.onlinePrice = parseFloat(myUser.totalAmount) - parseFloat(e.target.value)
  }

  const editPrice = async (e) => {
    e.preventDefault()
    const bodydata = {
      moneyType: invoice.moneyType,
      onlinePrice: invoice.onlinePrice,
      cashPrice: invoice.cashPrice,
    }
    try {
      const resonse = await updateData("order/editorder/" + invoice._id, bodydata)
      var _data = resonse
      toast.success(_data.data.message)
      setmodal_small(false)
      getAllOrders()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  // Delete fuction

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Order Details" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <div>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    history.goBack()
                  }}
                  className="m-2"
                  color="danger"
                >
                  <i className="bx bx-left-arrow-alt"></i> Back
                </Button>
              </div>
            </div>

            <Col md={8}>
              <Card>
                <CardBody>
                  <div>
                    <Row>
                      <Col md="12">
                        <div className="p-2">
                          <div>
                            <Row>
                              <Col>
                                <div>
                                  <h5>
                                    <b>Order ID :</b>
                                    <span style={{ color: "#000" }}>
                                      {" "}
                                      {invoice.orderNo}
                                    </span>
                                  </h5>
                                  <p>
                                    <b>
                                      <i className="bx bx-calendar"></i>{" "}
                                      {invoice.date} - {invoice.time}
                                    </b>
                                  </p>
                                </div>
                              </Col>
                              <Col>
                                <div
                                  className="mb-4"
                                  style={{ direction: "rtl" }}
                                >
                                  <Button onClick={() => { downloadImage() }} className="m-2" color="success">
                                    Print Invoice{" "}
                                    <i className="bx bx-printer"></i>
                                  </Button>
                                  <br />
                                  <a className="mt-2 text-dark">
                                    Payment Method : <b>{invoice.moneyType == "Card"?"Online":invoice.moneyType}</b>
                                  </a>
                                  <br />
                                  <a className="mt-2 text-dark">
                                    Payment Status : <b>Paid</b>
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div>
                            <Table className="mt-2" bordered responsive>
                              <thead style={{ background: "#e5e5e5" }}>
                                <tr>
                                  <th>S No</th>
                                  <th>Image</th>
                                  <th>Item Details</th>
                                  <th>Total Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {products.length == 0 ? (
                                  <tr className="text-center">
                                    <td colSpan="3">No data...</td>
                                  </tr>
                                ) : (
                                  <>
                                    {products.map((data, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>
                                          <div className="text-center">
                                            <img
                                              src={imgUrl + data.stockImage}
                                              style={{ width: "60px" }}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            <h5>{data.stockName}</h5>
                                            <b>Qty :</b>{" "}
                                            <span>{data.quantity}</span>
                                            <br />
                                            <b>Unit Price :</b>
                                            <span> ₹ {data.amount}</span>
                                            <br />
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          ₹ {data.amount * data.quantity}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>

                          <div className="mt-2">
                            <Row>
                              <Col md="3">
                                {invoice.moneyType == "Split" ? (
                                  <div>
                                    <Button onClick={tog_small} size="sm" outline color="primary">Change Price</Button>
                                  </div>
                                ) : ""}
                              </Col>
                              <Col md="9">
                                <Row>
                                  <Col>
                                    <div style={{ direction: "rtl" }}>
                                      {/* <b>: Subtotal </b>
                                      <br /> */}
                                      <b>: Cash </b>
                                      <br />
                                      <b>: Online </b>
                                      <br />
                                      <b>: Discount </b>
                                      <br />
                                      <b>: Tax / VAT </b>
                                      <br />
                                      <br />
                                      <h5 className="mt-2">: Total </h5>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="text-end">
                                      {/* <b> ₹ {invoice.subAmount}</b>
                                      <br /> */}
                                      <b> ₹ {invoice.cashPrice == undefined ? 0 : invoice.cashPrice}</b>
                                      <br />
                                      <b> ₹ {invoice.onlinePrice == undefined ? 0 : invoice.onlinePrice}</b>
                                      <br />
                                      {/* <b> ₹ {invoice.cashPrice}</b>
                                      <br />
                                      <b> ₹ {invoice.onlinePrice}</b>
                                      <br /> */}
                                      <b> - ₹ {invoice.couponAmount == undefined ? 0 : invoice.couponAmount}</b>
                                      <br />
                                      <b> ₹ {invoice.gst}</b>
                                      <br />
                                      <hr />
                                      <h5> ₹ {invoice.totalAmount}</h5>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardBody>
                  <div>
                    <h5> Customer Information</h5>
                    <div className="mb-4">
                      <Row>
                        <Col className="mt-4" md="4">
                          <img src={userimg} style={{ width: "90px" }} />
                        </Col>
                        <Col md="8" className="mt-4 pt-3">
                          <p>
                            <b>Name</b>: {invoice.customerName}
                          </p>
                          <p>
                            <b>Phone</b>: {phone}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          size="sm"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Change Amount
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editPrice(e)
              }}
            >
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Cash Amount <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Amount"
                      required
                      name="cashPrice"
                      value={invoice.cashPrice}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Online Amount <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Amount"
                      required
                      disabled
                      name="onlinePrice"
                      value={invoice.onlinePrice}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>

              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default OrderDetails
