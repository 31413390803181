import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import avatar from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import social from "../../assets/images/letast/social.mp4"

const Postdetails = () => {
    const history = useHistory()
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small1, setmodal_small1] = useState(false)
    const [post, setposts] = useState([])
    const [reports, setreports] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState({ blockedReason: "" })
    const [form1, setform1] = useState([])
    console.log(form1)
    const [form2, setform2] = useState([])
    const [Files1, setFiles1] = useState({ file: "" })
    const [Files, setFiles] = useState({ file: "" })

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    const changeHandler = (e) => {
        setFiles(e.target.files)
    }
    const changeHandler1 = (e) => {
        setFiles1(e.target.files)
    }

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    function tog_small1() {
        setmodal_small1(!modal_small1)
        removeBodyCss()
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllPosts = async () => {
        const bodydata = sessionStorage.getItem('postid')
        const resonse = await addData("socialmedia/getuserpostsbyid/" + bodydata)
        var _data = resonse
        setposts(_data.data.userposts)
        setreports(_data.data.reportedUsers)
        setFilter(_data.data.comments)
    }

    const [listPerPage] = useState(5);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * listPerPage;
    const lists = filter.slice(pagesVisited, pagesVisited + listPerPage);
    const pageCount = Math.ceil(filter.length / listPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [listPerPage1] = useState(5);
    const [pageNumber1, setPageNumber1] = useState(0);
    const pagesVisited1 = pageNumber1 * listPerPage1;
    const lists1 = reports.slice(pagesVisited1, pagesVisited1 + listPerPage1);
    const pageCount1 = Math.ceil(reports.length / listPerPage1);
    const changePage1 = ({ selected }) => {
        setPageNumber1(selected);
    };

    useEffect(() => {
        getAllPosts()
    }, [])

    // Add fuction
    const addBlockandunblock = async (e) => {
        e.preventDefault()
        const bodydata = sessionStorage.getItem('postid')
        const bodyDate = {
            blockedReason: form.blockedReason == '' ? '' : form.blockedReason
        }
        try {
            const resonse = await updateData("socialmedia/blockpost/" + bodydata, bodyDate)
            var _data = resonse
            toast.success(_data.data.message)
            setmodal_small1(false)
            setform({ blockedReason: "" })
            getAllPosts()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])


    function openmodels(data) {
        setimgview(!imgview)
        setimgview1(data)
    }

    const redirectuser=(data)=>{
        sessionStorage.setItem('userid', data.commenterId)
        history.push('/viewuser')
      }
    const redirectuser1=(data)=>{
        sessionStorage.setItem('userid', data.customerId)
        history.push('/viewuser')
      }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Post Details" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row className="mb-2">
                        <Col md="6">
                            {/* <Button className="m-1" color="info" >Rejected Reported Request</Button> */}
                            <Button
                                onClick={() => {
                                    setmodal_small1(true)
                                }}
                                className="m-1" color={post.isBlocked == true ? 'info' : 'primary'}>{post.isBlocked == true ? "Rejected Reported Request" : "Block Post"}</Button>
                        </Col>
                        <Col md="6">
                            {/* <Link to="/posts"> */}
                            <Button onClick={history.goBack} className="m-1" style={{ float: "right" }} color="warning" > <i className="bx bx-left-arrow-alt" /> Back</Button>
                            {/* </Link> */}

                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardBody>

                                    <Table bordered className="responsive">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "150px" }}>Title</th>
                                                <td>{post.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{post.isBlocked == true ? "Blocked" : "Active"}</td>
                                            </tr>
                                            <tr>
                                                <th>Total views</th>
                                                <td>{post.views}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Like</th>
                                                <td>{post.likes}</td>
                                            </tr>
                                            <tr>
                                                <th>Total comment</th>
                                                <td>{post.comments}</td>
                                            </tr>
                                            <tr>
                                                <th>User</th>
                                                <td>{post.customerName}</td>
                                            </tr>
                                            {/* <tr>
                                                <th>User Mobile</th>
                                                <td>942365465</td>
                                            </tr> */}
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{post.date + post.time}</td>
                                            </tr>
                                            {post.isBlocked == true ? 
                                            <tr>
                                                <th>Blocked Reason</th>
                                                <td>{post.blockedReason}</td>
                                            </tr>
                                            :""}
                                        </tbody>
                                    </Table>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            {post.postType == "Video" ? (
                                                <>
                                                    {post?.postMedia?.map((data, key) => (
                                                        <div key={key} className={`carousel-item ${key === 0 ? 'active' : ''}`}>

                                                            <iframe
                                                                src={`${imgUrl}${data}`}
                                                                className="d-block w-100"
                                                                alt={`Slide ${key + 1}`}
                                                                style={{ height: '360px', objectFit: 'cover' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                            ) :
                                                <>
                                                    {post?.postMedia?.map((data, key) => (
                                                        <div key={key} className={`carousel-item ${key === 0 ? 'active' : ''}`}>

                                                            <img
                                                                src={`${imgUrl}${data}`}
                                                                className="d-block w-100"
                                                                alt={`Slide ${key + 1}`}
                                                                style={{ height: '360px' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                            }

                                        </div>
                                        <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                                            <span className="sr-only">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true" />
                                            <span className="sr-only">Next</span>
                                        </button>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>



                    </Row>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Comments</h5>
                                    <Table bordered className="responsive">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th>User Id</th>
                                                <th>Created At</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lists.map((data, key) => (
                                                <tr key={key}>
                                                    <td >{(pageNumber - 1) * 5 + key + 6}</td>
                                                    <td><a className="text-primary" onClick={()=>{redirectuser(data)}}>{data.commenterName}</a></td>
                                                    <td>{data.date + " , " + data.time}</td>
                                                    <td>{data.comment}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                    <div style={{ float: "right" }}>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                            total={lists.length}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardBody>
                                    <h5>Report Post</h5>
                                    <Table bordered className="responsive">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th >Reported By</th>
                                                <th >Reported At</th>
                                                <th>Report Reason</th>
                                                {/* <th>Resolved At</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lists1.map((data, key) => (
                                                <tr key={key}>
                                                    <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                                    <td><a className="text-primary" onClick={()=>{redirectuser1(data)}}>{data?.userName}</a></td>
                                                    <td>{data.date + "," + data.time}</td>
                                                    <td>{data.reportReason}</td>
                                                    {/* <td>(not set)</td> */}
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                    <div style={{ float: "right" }}>
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount1}
                                            onPageChange={changePage1}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"disabled"}
                                            activeClassName={"active"}
                                            total={lists1.length}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small1}
                    toggle={() => {
                        tog_small1()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setmodal_small1(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form

                            onSubmit={(e) => { addBlockandunblock(e) }}>
                            <div className="text-center">
                                <i style={{ fontSize: "80px" }} className="bx bx-error-circle text-warning" />
                                <h5 >Are you sure you want to {post.isBlocked == true ? "Reactive" : "Blocked"} this post?</h5>
                                {post.isBlocked == true ? <span>
                                    <b>Blocked Reason: </b> {post.blockedReason}
                                </span> :
                                    <div className="container">
                                        <textarea required
                                            onChange={(e) => { handleChange(e) }}
                                            value={form.blockedReason}
                                            name="blockedReason"
                                            placeholder="Enter Reason"
                                            className="form-control mt-2" />
                                    </div>
                                }

                            </div>


                            <div className="mt-4" style={{ textAlign: "center" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small1(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Yes <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Postdetails
