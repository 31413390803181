import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Userprofilecat = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small2, setmodal_small2] = useState(false)
    const [modal_small3, setmodal_small3] = useState(false)
    const [show, setshow] = useState(false)
    const [customer, setcustomers] = useState([])
    const [form, setform] = useState({ name: "", area: "", phone: "", email: "" })
    const [form1, setform1] = useState([])
    const [form2, setform2] = useState({ reason: "" })
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState([])

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    function tog_small2() {
        setmodal_small2(!modal_small2)
        removeBodyCss()
    }
    function tog_small3() {
        setmodal_small3(!modal_small3)
        removeBodyCss()
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    const handleChange2 = e => {
        let myUser = { ...form2 }
        myUser[e.target.name] = e.target.value
        setform2(myUser)
    }

    // get all function

    const getAllCustomers = async () => {
        const resonse = await addData("customer/getall")
        var _data = resonse
        setcustomers(_data.data.result)
        setFilter(_data.data.result)
    }

    useEffect(() => {
        getAllCustomers()
    }, [])

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Name",
            selector: row => "It",
            sortable: true,
        },
        {
            name: "Image",
            selector: row => <img style={{ width: "70px" }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV4ahJC5bjrrz2vZ6CEJVwgZo-SzLL6V5saEkhNWj5LA&s" />,
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status == true ? <p className="text-success">Verified </p> : <p className="text-danger">Rejected</p>,
            sortable: true,
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true,
        },
        {
            name: "Action",
            selector: row => (
                <>

                    <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="success"
                        outline
                    >
                        <i className="fa fa-edit"></i>
                    </Button>

                    <Button
                        // onClick={() => {
                        //   getpopup(row)
                        // }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="danger"
                        outline
                    >
                        <i className="fa fa-trash"></i>
                    </Button>

                </>
            ),
        },
    ]

    // useEffect(() => {
    //   const result = customer.filter(data => {
    //     return data.name && data.name.toLowerCase().match(search.toLowerCase())
    //   })
    //   setFilter(result)
    // }, [search])

    useEffect(() => {
        const result = customer.filter(data => {
            return (
                (data.name && data.name.toLowerCase().includes(search.toLowerCase())) ||
                (data.email &&
                    data.email.toLowerCase().includes(search.toLowerCase())) ||
                (data.phone && data.phone.toLowerCase().includes(search.toLowerCase()))
            )
        })
        setFilter(result)
    }, [search])

    // Add fuction
    const addCustomer = async e => {
        e.preventDefault()
        const bodydata = {
            name: form.name,
            email: form.email,
            phone: form.phone,
            area: form.area,
        }
        try {
            const resonse = await addData("customer/add", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform({ name: "", area: "", phone: "", email: "" })
            getAllCustomers()
            setshow(false)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Edit fuction
    const editCustomer = async e => {
        e.preventDefault()
        const bodydata = {
            name: form1.name,
            email: form1.email,
            phone: form1.phone,
            area: form1.area,
            status: form1.status,
        }
        try {
            const resonse = await updateData("customer/edit/" + form1._id, bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform1({ name: "", area: "", phone: "", email: "", status: "" })
            setmodal_small(false)
            getAllCustomers()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Delete fuction

    const usestatusChange = async (e) => {
        e.preventDefault()
        const bodydata = {
            reason: form2.reason
        }
        try {
            const resonse = await updateData(
                "customer/editstatus/" + form2._id, bodydata,
                {}
            )
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform2({ reason: "" })
            setmodal_small2(false)
            setmodal_small3(false)
            getAllCustomers()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // const manageDelete = data => {
    //   const confirmBox = window.confirm("Do you really want to Delete?")
    //   if (confirmBox === true) {
    //     deletebenners(data)
    //   }
    // }

    const getpopup = (data) => {
        setform1(data)
        tog_small()
    }
    const getpopup2 = (data) => {
        setform2(data)
        tog_small2()
    }
    const getpopup3 = (data) => {
        setform2(data)
        tog_small3()
    }

    const [forms, setforms] = useState([])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="User Profile Category" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    {show == true ? (
                        <Card>
                            <CardHeader className="bg-white">
                                <CardTitle>Create Profile Category</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    onSubmit={e => {
                                        addCustomer(e)
                                    }}
                                >
                                    <Row>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Label for="basicpill-firstname-input1">
                                                    Name <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    pattern="^[a-zA-Z\s]*$"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    placeholder="Enter Name"
                                                    required
                                                    name="name"
                                                    value={form.name}
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Label for="basicpill-firstname-input1">
                                                    Image <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="file"
                                                    // pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    placeholder="Enter Email"
                                                    required
                                                    name="email"
                                                    value={form.email}
                                                    onChange={e => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <div className="mt-4" style={{ float: "right" }}>
                                                    <Button
                                                        onClick={() => {
                                                            setshow(false)
                                                        }}
                                                        className="m-1"
                                                        color="danger"
                                                        type="button"
                                                    >
                                                        Cancel <i className="fas fa-times-circle"></i>
                                                    </Button>
                                                    <Button className="m-1" color="success" type="submit">
                                                        Submit <i className="fas fa-check-circle"></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>
                    ) : (
                        ""
                    )}

                    <Row>
                        <Col md={12}>
                            <Card>
                                {/* <CardHeader className="bg-white">
                  <CardTitle>Customers List</CardTitle>
                </CardHeader> */}

                                <CardBody>

                                    <div>
                                        <div className="table-responsive" style={{ width: "100%" }}>
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div style={{ float: "left" }}>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setshow(!show)
                                                                    }}
                                                                >
                                                                    Add New <i className="bx bx-plus-circle" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div style={{ float: "right" }}>
                                                                <input
                                                                    value={search}
                                                                    onChange={e => {
                                                                        setSearch(e.target.value)
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                // subHeaderAlign="right"
                                                responsive
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Update Profile Category
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                editCustomer(e)
                            }}
                        >
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    pattern="^[a-zA-Z\s]*$"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Name"
                                    required
                                    name="name"
                                    value={form1.name}
                                    onChange={e => {
                                        handleChange1(e)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Image <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="file"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Email"
                                    required
                                    name="email"
                                    // value={form1.email}
                                    onChange={e => {
                                        handleChange1(e)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input3">
                                    Status <span className="text-danger">*</span>
                                </Label>
                                <select
                                    name="status"
                                    value={form1.status}
                                    onChange={e => {
                                        handleChange1(e)
                                    }}
                                    className="form-select"
                                >
                                    <option value="active">Active</option>
                                    <option value="inactive">In Active</option>
                                </select>
                            </div>

                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={modal_small2}
                    toggle={() => {
                        tog_small2()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Blocked Customer
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small2(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                usestatusChange(e)
                            }}
                        >
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Reason  <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                    type="text"
                                    pattern="^[a-zA-Z\s]*$"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Reason"
                                    required
                                    name="reason"
                                    value={form2.reason}
                                    onChange={e => {
                                        handleChange2(e)
                                    }}
                                />
                            </div>


                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small2(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={modal_small3}
                    toggle={() => {
                        tog_small3()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        {/* <h5 className="modal-title mt-0" id="mySmallModalLabel">
              locked Customer
            </h5> */}
                        <button
                            onClick={() => {
                                setmodal_small3(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                usestatusChange(e)
                            }}
                        >
                            {/* <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Reason  <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  pattern="^[a-zA-Z\s]*$"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Reason"
                  required
                  name="reason"
                  value={form2.reason}
                  onChange={e => {
                    handleChange2(e)
                  }}
                />
              </div> */}

                            <div className="text-center">
                                <i style={{ fontSize: "100px" }} className="bx bx-error-circle text-warning" />
                                <h4 >Do you want unblock this user</h4>
                            </div>


                            <div className="mt-4" style={{ textAlign: "center" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small2(false)
                                    }}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                    style={{ width: "100px" }}
                                >
                                    No <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button style={{ width: "100px" }} className="m-1" color="success" type="submit">
                                    Yes <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Userprofilecat
