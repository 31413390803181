import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { saveAs } from "file-saver"
import Flatpickr from "react-flatpickr"
import { format } from "date-fns"

const Orders = () => {
  const history = useHistory()
  const [customer, setcustomers] = useState([])
  const [prodects1, setProducts1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])
  const [show, setshow] = useState(false)

  // get all function

  const getAllOrders = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("order/getallorders", bodyData)
    var _data = resonse
    setcustomers(_data.data.orderResult)
    setProducts1(_data.data)
    setFilter(_data.data.orderResult)
  }

  useEffect(() => {
    getAllOrders()
  }, [])

  const downloadImage = data => {
    sessionStorage.setItem("orderid", data._id)
    history.push("/invoice")
    // saveAs(imgUrl+ data.invoice)
  }
  const downloadImage1 = data => {
    sessionStorage.setItem("orderid", data._id)
    history.push("/vieworders")
    // saveAs(imgUrl+ data.invoice)
  }

  const coloums = [
    {
      name: "#",
      filterable: true,
      disableFilters: true,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Date",
      selector: row =><div>
      {row.date}<br /> 
      ({row.time})
    </div>,
      sortable: true,
    },
    {
      name: "Order NO",
      selector: row => row.orderNo,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: row => row.customerName,
      sortable: true,
    },
    {
      name: "Phone",
      selector: row => row.customerPhone,
      sortable: true,
    },
    {
      name: "Amount",
      selector: row => "₹ " + row.totalAmount,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <div className="text-center">
          <Button
            onClick={() => {
              downloadImage1(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="warning"
            outline
          >
            <i className="bx bx-show"></i>
          </Button>
          <Button
            onClick={() => {
              downloadImage(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-printer"></i>
          </Button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = customer.filter((data) => {
      return (
        (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.orderNo && data.orderNo.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.customerPhone && data.customerPhone.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);


  const [dates, setDates] = useState([]);

  const handleDateChange = async (NewDate) => {
    if (NewDate.length === 0) {

    } else {
      const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
      const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
      // const newDates = [date1, date2];
      const bodydate = {
        dates: [date1, date2]
      }
      setDates(NewDate);
      const response = await addData("order/getallorders", bodydate);
      const _data = response;
      setcustomers(_data.data.orderResult)
      setProducts1(_data.data)
      setFilter(_data.data.orderResult)
    }
  };

  const getReportData1 = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("order/getallorders", bodyData)
    var _data = resonse
    setcustomers(_data.data.orderResult)
    setProducts1(_data.data)
    setFilter(_data.data.orderResult)
    setDates([]);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Orders" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}

          <Row>
            <Col md={12}>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      filterCustomer(e)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-4">
                          <Label>Date Range</Label>
                          <Flatpickr
                            placeholder="Select date"
                            className="form-control"
                            name="date"
                            onChange={e => {
                              handleDateChange(e)
                            }}
                            options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="text-end mt-4">
                          <Button type="submit" color="success m-1" outline>
                            Submit <i className="bx bx-check-circle"></i>
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              setshow(!show)
                            }}
                            color="danger m-1"
                            outline
                          >
                            Cancel <i className="bx bx-x-circle"></i>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              ) : (
                ""
              )}

              <Card>
                <CardHeader className="bg-white">
                  {/* <CardTitle>Departments </CardTitle> */}
                </CardHeader>

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <Row>
                          <Col md="6">
                           <div className="mt-2">
                           <Link to="/pos">
                                <Button color="primary">
                                  Add Oders <i className="bx bx-plus-circle" />
                                </Button>
                              </Link>

                           <b className="mr-4"> Total Bookings :  <span className="text-primary">  {prodects1.count} </span></b>
                            <b> Total Amount : <span className="text-primary"> ₹ {prodects1.grandTotal}</span></b>
                           </div>
                          </Col>
                          <Col md="3" >
                            <Row>
                              <Col style={{padding:"0px"}} md="9">
                                <div >
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="dates"
                                    value={dates}
                                    onChange={(e) => { handleDateChange(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col style={{padding:"0px"}} md="3">
                                <div >
                                  <Button style={{ height: "33px" }} onClick={getReportData1} size="sm" outline color="danger" >Clear </Button>
                                </div>
                              </Col>
                            </Row>

                          </Col>
                          <Col md={3} >
                            <div>
                              <input
                                value={search}
                                onChange={e => {
                                  setSearch(e.target.value)
                                }}
                                type="text"
                                className="form-control mb-3"
                                placeholder="Search..."
                              />
                            </div>
                          </Col>
                        </Row>

                        }
                      // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Orders
