import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"

const Income = () => {
  const [prodects, setProducts] = useState([])
  const [prodectstot, setProductstot] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  // get all function

  const getAllProducts = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("finance/posincomes", bodyData)
    var _data = resonse
    setProducts(_data.data.posIncomes)
    setFilter(_data.data.posIncomes)
    setProductstot(_data.data)
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Date",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Order No",
      selector: row => row.orderNo,
      sortable: true,
    },
    {
      name: "User Name",
      selector: row => row.customerName,
      sortable: true,
    },
    {
      name: "Products",
      selector: row => row.products + ",",
      sortable: true,
    },
    {
      name: "Purchase Price",
      selector: row => row.purchasePrice,
      sortable: true,
    },
    {
      name: "Selling Price",
      selector: row => row.subAmount,
      sortable: true,
    },
    {
      name: "gst",
      selector: row => row.gst,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: row => row.totalAmount,
      sortable: true,
    },
    {
      name: "Profit",
      selector: row => row.profit,
      sortable: true,
    },


  ]

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = prodects.filter((data) => {
      return (
        (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.orderNo && data.orderNo.toLowerCase().includes(trimmedSearch.toLowerCase()))
        // (data.products && data.products.toLowerCase().includes(trimmedSearch.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  const [dates, setDates] = useState([]);

  const handleDateChange = async (NewDate) => {
    if (NewDate.length === 0) {

    } else {
      const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
      const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
      // const newDates = [date1, date2];
      const bodydate = {
        dates: [date1, date2]
      }
      setDates(NewDate);
      const response = await addData("finance/posincomes", bodydate);
      const _data = response;
      setProducts(_data.data.posIncomes)
      setFilter(_data.data.posIncomes)
      setProductstot(_data.data)
    }
  };

  const getReportData1 = async () => {
    const bodyData = {
      dates: [new Date().toISOString().split('T')[0]]
    }
    const resonse = await addData("finance/posincomes", bodyData)
    var _data = resonse
    setProducts(_data.data.posIncomes)
    setFilter(_data.data.posIncomes)
    setProductstot(_data.data)
    setDates([]);
  }




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Income" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>

                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                         <Row>
                          <Col md="6">
                            <Label className="mr-4">Total Purchase Price : <b className="text-primary">₹ {prodectstot.grandPurchase}</b></Label> 
                            <Label className="mr-4">Total Selling Price : <b className="text-primary">₹ {prodectstot.grandSubAmount}</b></Label> 
                            <Label>Total Profit : <b className="text-primary">₹ {prodectstot.grandProfit}</b></Label>
                          </Col>
                          <Col md="3">
                          <Row>
                              <Col style={{padding:"0px"}} md="9">
                                <div >
                                  <Flatpickr
                                    placeholder="Select date"
                                    className="form-control"
                                    name="dates"
                                    value={dates}
                                    onChange={(e) => { handleDateChange(e) }}
                                    options={{
                                      mode: "range",
                                      dateFormat: "d M, Y",
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col style={{padding:"0px"}} md="3">
                                <div >
                                  <Button style={{ height: "33px" }} onClick={getReportData1} size="sm" outline color="danger" >Clear </Button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="3">
                          <div>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                          </Col>
                         </Row>
                        }
                        subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Income
