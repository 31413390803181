import React, { useState, useEffect, useRef } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { CSVLink } from "react-csv"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import Flatpickr from "react-flatpickr"
import { useReactToPrint } from "react-to-print"
import { format } from "date-fns";

const Snookerbookings = () => {
    const history = useHistory()
    const [prodects, setProducts] = useState([])
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState([])
    const [exceldata, setexceldata] = useState([])
    const [form, setform] = useState([])
    const [show, setshow] = useState(false)
    console.log(prodects)

    // get all function
    const Authdetails = localStorage.getItem("authUser")
    const Auth = JSON.parse(Authdetails)
    const access = Auth?.userData?.permissions[0]
    const userIds = Auth?.userData?._id

    const getAllProducts = async () => {
        const bodydata = {
            dates: [],
            employeeId: access?.pos == true ? userIds : ""
        }
        const resonse = await addData("reports/orderemployees", bodydata)
        var _data = resonse
        setProducts(_data?.data?.orderReport)
        setFilter(_data?.data?.orderReport)
        setexceldata(_data?.data?.orderExcelReport)
    }

    const employeedata = async (e) => {
        const myData = { ...form };
        myData[e.target.name] = e.target.value;
        setform(myData)
        const bodydata = {
            dates: [],
            employeeId: e.target.value
        }
        const resonse = await addData("reports/orderemployees", bodydata)
        var _data = resonse
        setProducts(_data?.data?.orderReport)
        setFilter(_data?.data?.orderReport)
        setexceldata(_data?.data?.orderExcelReport)
    }

    const [customer, setcustomers] = useState([])
    console.log(customer)

    const getAllCustomers = async () => {
        const resonse = await addData("getactiveusers")
        var _data = resonse
        setcustomers(_data.data.employees)
    }

    useEffect(() => {
        getAllProducts()
        getAllCustomers()
    }, [])

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Order Date",
            selector: row => row.date,
            sortable: true,
        },
        {
            name: "Order No",
            selector: row => row.orderNo,
            sortable: true,
        },
        {
            name: "Customers",
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: "Mobile",
            selector: row => row.customerPhone,
            sortable: true,
        },

        {
            name: "Total Price",
            selector: row => "₹ " + row.totalAmount,
            sortable: true,
        },
        // {
        //   name: "Status",
        //   selector: row => row.status == true?<p className="text-success">Success</p>:<p className="text-danger">Cancel</p>,
        //   sortable: true,
        // },
        {
            name: "Action",
            selector: row => (
                <>
                    <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="warning"
                        outline

                    >
                        <i className="bx bx-show"></i>
                    </Button>

                </>
            ),
        },
    ]

    useEffect(() => {
        const trimmedSearch = search.trim();
        const result = prodects.filter((data) => {
            return (
                (data.bookingNo && data.bookingNo.toLowerCase().includes(trimmedSearch.toLowerCase()))
                || (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase()))
                || (data.customerPhone && data.customerPhone.toLowerCase().includes(trimmedSearch.toLowerCase()))
                || (data.bookingSlot && data.bookingSlot.toLowerCase().includes(trimmedSearch.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);


    const [datastate, setDatastate] = useState([])

    let dates = [];

    const handleDateChange = (NewDate) => {
        console.log(NewDate[0]);

        const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
        dates.push(date1);
        dates.push(date2);
        setDatastate(dates);
    };

    // filter

    const filteBooking = async (e) => {
        e.preventDefault()
        const bodydata = {
            dates: datastate,
            employeeId: access?.pos == true ? userIds : form.employeeId
        }
        const resonse = await addData("reports/orderemployees", bodydata)
        var _data = resonse
        setProducts(_data?.data?.orderReport)
        setFilter(_data?.data?.orderReport)
        setexceldata(_data?.data?.orderExcelReport)
        setshow(false)
    }

    // excel
    const csvReport = {
        filename: "POSReport.csv",
        // headers: headers,
        data: exceldata,
    }

    // pdf
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const getpopup = (data) => {
        sessionStorage.setItem("orderid", data._id)
        history.push("/vieworders")
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="POS Report" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}


                    <Row>
                        <Col md={12}>
                            {show == true ? (
                                <Card className="p-4">
                                    <Form
                                        onSubmit={(e) => {
                                            filteBooking(e)
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-4">
                                                    <Label>Date Range</Label>
                                                    <Flatpickr
                                                        placeholder="Select date"
                                                        className="form-control"
                                                        name="date"
                                                        onChange={(e) => {
                                                            handleDateChange(e);
                                                        }}
                                                        options={{
                                                            mode: "range",
                                                            dateFormat: "d M, Y",
                                                        }}
                                                    />

                                                </div>
                                            </Col>


                                            <Col md="3">
                                                <div className="text-end mt-4">
                                                    <Button type="submit" color="success m-1" outline>
                                                        Submit <i className="bx bx-check-circle"></i>
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        onClick={() => {
                                                            setshow(!show)
                                                        }}
                                                        color="danger m-1"
                                                        outline
                                                    >
                                                        Cancel <i className="bx bx-x-circle"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            ) : (
                                ""
                            )}

                            <Card>
                                <CardHeader className="bg-white">
                                </CardHeader>

                                <CardBody>
                                    <div>

                                        <div ref={componentRef} className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <Row>
                                                        <Col md="3">
                                                        {access?.all == true ? (
                                                            <select name="employeeId" onChange={(e) => { employeedata(e) }} className="form-select">
                                                                <option value="">Select Employee</option>
                                                                {customer.map((data, key) => (
                                                                    <option key={key} value={data._id}>{data.name}</option>
                                                                ))}

                                                            </select>
                                                        ):""}
                                                        </Col>
                                                        <Col md="3" />
                                                        <Col md="6">
                                                            <Row>
                                                                <Col className="text-end" md="8">
                                                                    <div>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setshow(!show)
                                                                            }}
                                                                            color="warning"
                                                                            outline
                                                                        >
                                                                            Filter <i className="bx bx-filter-alt"></i>
                                                                        </Button>

                                                                        <CSVLink {...csvReport}>
                                                                            <Button type="button" color="success m-1" outline>
                                                                                Excel <i className="fas fa-file-excel"></i>
                                                                            </Button>
                                                                        </CSVLink>
                                                                        <Button
                                                                            onClick={handlePrint}
                                                                            type="button"
                                                                            color="danger m-1"
                                                                            outline
                                                                        >
                                                                            PDF <i className="fas fa-file-pdf"></i>
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                                <Col className="text-end" md="4">
                                                                    <div style={{ float: "right" }}>
                                                                        <input
                                                                            value={search}
                                                                            onChange={e => {
                                                                                setSearch(e.target.value)
                                                                            }}
                                                                            type="text"
                                                                            className="form-control mb-3"
                                                                            placeholder="Search..."
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>


                                                }
                                                subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Toaster />


            </div>
        </React.Fragment>
    )
}

export default Snookerbookings
