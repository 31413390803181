import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Employees = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [customer, setcustomers] = useState([])
  const [form, setform] = useState({ name: "", area: "", phone: "", email: "", password: "", roleId: "", address: "" })
  const [form1, setform1] = useState([])
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])
  const [roles, setroles] = useState([])
  const [show, setshow] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // get all function

  const getAllCustomers = async () => {
    const resonse = await addData("getallusers")
    var _data = resonse
    setcustomers(_data.data.profilesResult)
    setFilter(_data.data.profilesResult)
  }

  const getAllActiveRoles = async () => {
    const resonse = await addData("role/getallactive")
    var _data = resonse
    setroles(_data.data.roleResult)
  }

  useEffect(() => {
    getAllCustomers()
    getAllActiveRoles()
  }, [])

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Role",
      selector: row => row.designation,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },

    {
      name: "Action",
      selector: row => (
        <>
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="bx bx-edit "></i>
          </Button>
          {/* <Button
            onClick={() => {
              manageDelete(row)
            }}
            style={{ padding: "6px", margin: "3px" }}
            color="danger"
            outline
          >
            <i className="bx bx-trash"></i>
          </Button> */}
        </>
      ),
    },
  ]

  // useEffect(() => {
  //   const result = customer.filter(data => {
  //     return data.name && data.name.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilter(result)
  // }, [search])

  useEffect(() => {
    const result = customer.filter((data) => {
      return (
        (data.name && data.name.toLowerCase().includes(search.toLowerCase())) ||
        (data.email && data.email.toLowerCase().includes(search.toLowerCase())) ||
        (data.designation && data.designation.toLowerCase().includes(search.toLowerCase())) ||
        (data.phone && data.phone.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilter(result);
  }, [search]);

  // Add fuction
  const addCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      roleId: form.roleId,
      name: form.name,
      email: form.email,
      phone: form.phone,
      password: form.password,
      address: form.address,
    }
    try {
      const resonse = await addData("register", bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform({ name: "", area: "", phone: "", email: "", password: "", roleId: "", address: "" })
      getAllCustomers()
      setshow(false)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }
  // Edit fuction
  const editCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      roleId: form1.roleId,
      name: form1.name,
      email: form1.email,
      phone: form1.phone,
      address: form1.address,
      status: form1.status,
    }
    try {
      const resonse = await addData("edit/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1({ name: "", area: "", phone: "", email: "", status: "" })
      setmodal_small(false)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Belwails" breadcrumbItem="Employees" />

          {/* {permissioins.banner === true || roles === "admin" ? ( */}
          {show == true ? (
            <Card>
              <CardHeader className="bg-white">
                <CardTitle>Add Employee</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={e => {
                    addCustomer(e)
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Role Name <span className="text-danger">*</span>
                        </Label>
                        <select
                          required
                          name="roleId"
                          value={form.roleId}
                          onChange={e => {
                            handleChange(e)
                          }}
                          className="form-select">
                          <option value="">Select</option>
                          {roles.map((data, key) => (
                            <option key={key} value={data._id}>{data.role}</option>
                          ))}
                        </select>

                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          pattern="^[a-zA-Z\s]*$"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Name"
                          required
                          name="name"
                          value={form.name}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Email <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Email"
                          required
                          name="email"
                          value={form.email}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Phone <span className="text-danger">*</span>
                        </Label>
                        <Input
                         type="text"
                         maxLength="10"
                         pattern="[0-9]{10}"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Phone No"
                          required
                          name="phone"
                          value={form.phone}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Password"
                          required
                          name="password"
                          value={form.password}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Address <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Address"
                          required
                          name="address"
                          value={form.address}
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-4" style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setshow(false)
                      }}
                      className="m-1"
                      color="danger"
                      type="button"
                    >
                      Cancel <i className="fas fa-times-circle"></i>
                    </Button>
                    <Button className="m-1" color="success" type="submit">
                      Submit <i className="fas fa-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <Row>
            {/* <Col md={4}>
              <Card>
                <CardHeader className="bg-white">
                  
                </CardHeader>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      addCustomer(e)
                    }}
                  >
                 

                    <div className="mt-4" style={{ float: "right" }}>
                      <Button color="success" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col> */}

            <Col md={12}>
              <Card>
                {/* <CardHeader className="bg-white">
                  <CardTitle>Employees List</CardTitle>
                </CardHeader> */}

                <CardBody>
                  <div>
                    <div className="table-responsive">
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div className="row">
                            <div className="col-md-6">
                              <Button
                                color="primary"
                                onClick={() => {
                                  setshow(!show)
                                }}
                              >
                                Add Employee <i className="bx bx-plus-circle" />
                              </Button>
                            </div>
                            <div className="col-md-6">
                              <div style={{ float: "right" }}>
                                <input
                                  value={search}
                                  onChange={e => {
                                    setSearch(e.target.value)
                                  }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                />
                              </div>
                            </div>
                          </div>
                        }
                      // subHeaderAlign="right"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Employee
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCustomer(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Role Name <span className="text-danger">*</span>
                    </Label>
                    <select
                      required
                      name="roleId"
                      value={form1.roleId}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select">
                      <option value="">Select</option>
                      {roles.map((data, key) => (
                        <option key={key} value={data._id}>{data.role}</option>
                      ))}
                    </select>

                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      pattern="^[a-zA-Z\s]*$"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={form1.name}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="email"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Email"
                      required
                      name="email"
                      value={form1.email}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Phone <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Phone No"
                      required
                      name="phone"
                      value={form1.phone}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Address <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Address"
                      required
                      name="address"
                      value={form1.address}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Status <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="status"
                      value={form1.status}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      className="form-select"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">In Active</option>
                    </select>
                  </div>
                </Col>
              </Row>

              <div className="mt-4" style={{ float: "right" }}>
                <Button
                  className="m-1"
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger" type="button">
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Employees
