import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import Select from "react-select"

const Income = () => {
    const [form, setForm] = useState([])
    const [userdata, setuserdata] = useState([])
    const [lockerss, setlockerss] = useState([])
    const [bookids, setbookids] = useState([])
    const [lockersscount, setlockersscount] = useState([])
    const [lockernum, setlockernum] = useState(0)
    const [form1, setForm1] = useState({ name: '', phone: '', members: '' })
    const [form10, setForm10] = useState([])
    const [modal_small1, setmodal_small1] = useState(false)
    const [modal_small, setmodal_small] = useState(false)

    const handleChange = (e) => {
        const myData = { ...form }
        myData[e.target.name] = e.target.value;
        setForm(myData)
    }

    const handleChange1 = (e) => {
        const myData = { ...form1 }
        myData[e.target.name] = e.target.value;
        setForm1(myData)
    }

    const handleChange12 = async (e) => {
        const myData = { ...form1 }
        myData[e.target.name] = e.target.value;
        setForm1(myData)
        const bodydata = {
            phone: e.target.value
        }
        try {
            const resonse = await addData("locker/getcustomerbyphone", bodydata)
            var _data = resonse
            setForm10(_data.data)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const [selectedGroup, setselectedGroup] = useState("")

    const handleSelectGroup = async (selectedGroup) => {
        setselectedGroup(selectedGroup)
        const bodydata = {
            phone: selectedGroup.value
        }
        try {
            const resonse = await addData("locker/getcustomerbyphone", bodydata)
            var _data = resonse
            setForm10(_data.data)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }


    function tog_small1() {
        setmodal_small1(!modal_small1)
        setForm1({ name: '', phone: '', membersCount: '' })
        setmaintenance({ underMaintenance: '' })
    }
    function tog_small() {
        setmodal_small(!modal_small)
    }

    const modelvalue = (data) => {
        setlockernum(data)
        tog_small1()
    }
    const rleasedata = (data) => {
        setuserdata(data)
        tog_small()
    }

    // get lockers 

    const getAlllockers = async () => {
        const resonse = await addData("locker/getlockers")
        var _data = resonse
        setlockerss(_data.data.lockerResult)
        setlockersscount(_data.data)
    }
    const getAllbookingids = async () => {
        const resonse = await addData("locker/getbookingidslist")
        var _data = resonse
        setbookids(_data.data.bookings)
    }

    const options = bookids.map(data => ({
        value: data.bookingNo,
        label: data.bookingNo,
    }))


    useEffect(() => {
        getAlllockers()
        getAllbookingids()
    }, [])

    const [maintenance, setmaintenance] = useState([])
    const handleChange123 = (e) => {
        const myData = { ...maintenance };
        myData[e.target.name] = e.target.checked;
        setmaintenance(myData)
    }

    // Add fuction
    const addLocker = async (e) => {
        e.preventDefault()
        const bodydata = {
            name: form1.name,
            phone: maintenance.underMaintenance == true ? '' : form10._id,
            membersCount: maintenance.underMaintenance == true ? '' : form10.adultsCount,
            lockerNumber: lockernum,
            status: maintenance.underMaintenance == true ? "repair" : "inactive",
        }
        try {
            const resonse = await addData("locker/addlocker", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setmodal_small1(false)
            setForm1({ name: '', phone: '', membersCount: '' })
            setForm10([])
            getAlllockers()
            getAllbookingids()
            setmaintenance({ underMaintenance: '' })
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // Delete fuction
    const deleteLocker = async (e) => {
        e.preventDefault()
        const bodydata = {
            status: "active"
        }
        try {
            const resonse = await addData("locker/deletelocker/" + userdata._id, bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setmodal_small(false)
            getAlllockers()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    let loopCount;
    if (form.exampleRadios === '101to200') {
        loopCount = 201;
    } else if (form.exampleRadios === '201to300') {
        loopCount = 301;
    } else if (form.exampleRadios === '301to400') {
        loopCount = 401;
    } else if (form.exampleRadios === '401to500') {
        loopCount = 501;
    } else {
        loopCount = 101;
    }
    let loopCount1;
    if (form.exampleRadios === '101to200') {
        loopCount1 = 101;
    } else if (form.exampleRadios === '201to300') {
        loopCount1 = 201;
    } else if (form.exampleRadios === '301to400') {
        loopCount1 = 301;
    } else if (form.exampleRadios === '401to500') {
        loopCount1 = 401;
    } else {
        loopCount1 = 1;
    }

    const bookedlockers = lockerss.map((data) => ({ lockernum: data.lockerNumber, userid: data, lockstatus: data.status }))
    console.log(bookedlockers)
    {/* <input
                                type="checkbox"
                                value="1"
                                name="apartmentType"
                            /> */}

    const elements = [];
    for (let i = loopCount1; i < loopCount; i++) {
        const isBookedLocker = bookedlockers.some((locker) => locker.lockernum === i);
        console.log(isBookedLocker)

        elements.push(
            <div style={{ float: "left" }} key={i}>
                {isBookedLocker ? (
                    <div onClick={() => rleasedata(bookedlockers.find((locker) => locker.lockernum === i).userid)}
                        // className={bookedlockers.map((data)=>(data.lockstatus == "inactive"))?"cat1":"cat2"} 
                        className={bookedlockers.find((data) => data.lockernum === i)?.lockstatus === "inactive" ? "cat1" : "cat2"}
                    >
                        <label>
                            <span>
                                <i style={{ fontSize: "28px" }} className="bx bx-lock-alt" />
                                <p className="lockp">{i}</p>
                            </span>
                        </label>
                    </div>
                ) : (
                    <div onClick={() => modelvalue(i)} className="cat">
                        <label>
                            <span>
                                <i style={{ fontSize: "28px" }} className="bx bx-lock-open-alt" />
                                <p className="lockp">{i}</p>
                            </span>
                        </label>
                    </div>
                )}
            </div>
        );
    }

    // for (let i = loopCount1; i < loopCount; i++) {
    //     elements.push(
    //       <div style={{ float: "left" }} key={i}>
    //         <div
    //           onClick={() => modelvalue(i)}
    //           key={i}
    //           className="cat"
    //         >
    //           <label>
    //             <span>
    //               <i style={{ fontSize: "28px" }} className="bx bx-lock-open-alt" />
    //               <p className="lockp">{i}</p>
    //             </span>
    //           </label>
    //         </div>
    //       </div>
    //     );
    //   }

    console.log(elements)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Lockers" />
                    <Row>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <h5>Today Visitors: <span className="text-primary">{lockersscount?.todayVisitors}</span></h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <h5>Today Already Left : <span className="text-primary">{lockersscount?.todayAlreadyLeft}</span></h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <h5>Booked Lockers : <span className="text-primary">{lockersscount?.total?.bookedlockers}</span></h5>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <h5>Available Lockers : <span className="text-primary">{lockersscount?.total?.availableLokers}</span></h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col style={{ borderRight: "1px solid #bbbbbb", padding: "2px" }}>
                                            <div className="form-check mb-3 lockercountst">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios1"
                                                    value="1to100"
                                                    defaultChecked
                                                    onChange={(e) => { handleChange(e) }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios1"
                                                >
                                                    1 - 100
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Available: <b className="text-success">{lockersscount?.lockerBelow100?.available}</b></span>
                                                </div>
                                                <div className="col">
                                                    <span>Booked: <b className="text-primary">{lockersscount?.lockerBelow100?.booked}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col style={{ borderRight: "1px solid #bbbbbb", padding: "2px" }}>
                                            <div className="form-check mb-3 lockercountst">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios12"
                                                    value="101to200"
                                                    onChange={(e) => { handleChange(e) }}

                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios12"
                                                >
                                                    101 - 200
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Available: <b className="text-success">{lockersscount?.lockerBelow200?.available}</b></span>
                                                </div>
                                                <div className="col">
                                                    <span>Booked: <b className="text-primary">{lockersscount?.lockerBelow200?.booked}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col style={{ borderRight: "1px solid #bbbbbb", padding: "2px" }}>
                                            <div className="form-check mb-3 lockercountst">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios13"
                                                    value="201to300"
                                                    onChange={(e) => { handleChange(e) }}

                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios13"
                                                >
                                                    201 - 300
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Available: <b className="text-success">{lockersscount?.lockerBelow300?.available}</b></span>
                                                </div>
                                                <div className="col">
                                                    <span>Booked: <b className="text-primary">{lockersscount?.lockerBelow300?.booked}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col style={{ borderRight: "1px solid #bbbbbb", padding: "2px" }}>
                                            <div className="form-check mb-3 lockercountst">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios14"
                                                    value="301to400"
                                                    onChange={(e) => { handleChange(e) }}

                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios14"
                                                >
                                                    301 - 400
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Available: <b className="text-success">{lockersscount?.lockerBelow400?.available}</b></span>
                                                </div>
                                                <div className="col">
                                                    <span>Booked: <b className="text-primary">{lockersscount?.lockerBelow400?.booked}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col >
                                            <div className="form-check mb-3 lockercountst">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios15"
                                                    value="401to500"
                                                    onChange={(e) => { handleChange(e) }}

                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios15"
                                                >
                                                    401 - 500
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <span>Available: <b className="text-success">{lockersscount?.lockerBelow500?.available}</b></span>
                                                </div>
                                                <div className="col">
                                                    <span>Booked: <b className="text-primary">{lockersscount?.lockerBelow500?.booked}</b></span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <div className="mt-4 pt-2">
                                            <ul
                                                className="nav nav-pills mb-3 "
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item " role="presentation">
                                                    {elements}
                                                </li>

                                            </ul>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <Modal
                    // size="sm"
                    className="modal-dialog modal-dialog-centered"
                    isOpen={modal_small1}
                    toggle={() => {
                        tog_small1()
                    }}
                >
                    <div className="modal-header">
                        <h4 className="modal-title mt-0" id="mySmallModalLabel">

                            Book A Locker - {lockernum}
                        </h4>
                        <button
                            onClick={() => {
                                setmodal_small1(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={(e) => { addLocker(e) }}
                        >
                            <Row>
                                <Col md="10">
                                    <h5>Would you like to go to the repair locker?</h5>
                                </Col>
                                <Col md="2">
                                    <div className="form-check form-switch form-switch-md mb-3" >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitchsizemd"
                                            name="underMaintenance"
                                            defaultChecked={maintenance.underMaintenance}
                                            // value={maintenance.underMaintenance}
                                            onChange={(e) => { handleChange123(e) }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Booking Invoice <span className="text-danger">*</span>
                                </Label>
                                <Select
                                    onChange={handleSelectGroup}
                                    name="Invoice"
                                    options={options}
                                    placeholder="Select Invoice"
                                    isDisabled={maintenance.underMaintenance}
                                />

                                {/* <Input
                                    type="text"
                                    // pattern="[0-9]{10}"
                                    // maxLength={10}
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Booking Invoice"
                                    required
                                    name="phone"
                                    value={form1.phone}
                                    onChange={(e) => {
                                        handleChange12(e)
                                    }}
                                /> */}
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    User Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Name"
                                    required
                                    readOnly
                                    name="name"
                                    value={form10.userName}
                                    onChange={e => {
                                        handleChange1(e)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    Members Count <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Members"
                                    required
                                    readOnly
                                    name="membersCount"
                                    value={form10.adultsCount}
                                    onChange={e => {
                                        handleChange1(e)
                                    }}
                                />
                            </div>


                            <div className="mt-4" style={{ float: "right" }}>
                                <Button
                                    onClick={tog_small1}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                {maintenance.underMaintenance == true ? (
                                    <Button className="m-1" color="success" type="submit">
                                        Submit <i className="fas fa-check-circle"></i>
                                    </Button>
                                ) : (
                                    <Button disabled={selectedGroup.length == 0} className="m-1" color="success" type="submit">
                                        Submit <i className="fas fa-check-circle"></i>
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    // size="sm"
                    className="modal-dialog modal-dialog-centered"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    {/* <div className="modal-header">
                        <h2 className="modal-title mt-0" id="mySmallModalLabel">

                            Book A Locker - {lockernum}
                        </h2>
                        <button
                            onClick={() => {
                                setmodal_small1(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
                    <div className="modal-body">
                        <Form
                            onSubmit={(e) => { deleteLocker(e) }}
                        >
                            <div className="text-center">
                                <i className="bx bx-error-circle text-warning" style={{ fontSize: "100px" }} />
                                <h4 className="mt-2">Do You Want To Release</h4>
                                <h5>Locker No - {userdata.lockerNumber}</h5>
                            </div>

                            <div className="mt-4 text-center">
                                <Button
                                    onClick={tog_small}
                                    color="danger"
                                    type="button"
                                    className="m-1"
                                    style={{ width: "100px" }}
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button style={{ width: "100px" }} className="m-1" color="success" type="submit">
                                    Yes <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Income
