import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"
import { format } from "date-fns"
import Flatpickr from "react-flatpickr"
import { CSVLink } from "react-csv"


const Visitors = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])
    const [show, setshow] = useState(false)
    const [show2, setshow2] = useState(false)
    const [prodects, setProducts] = useState([])
    const [excelprodects, setexcelprodects] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState([])
    const [form1, setform1] = useState([])

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5


    const handleChange = async (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
        const bodydate = {
            dates: e.target.value
        }
        const resonse = await addData("locker/filterusers", bodydate)
        var _data = resonse
        setProducts(_data.data.lockerResult)
        setFilter(_data.data.lockerResult)
    }

    const [datastate, setDatastate] = useState([])
    console.log(datastate)

    let dates = [];

    const handleDateChange = async (NewDate) => {
        const date1 = format(new Date(NewDate[0]), "yyyy-MM-dd");
        const date2 = format(new Date(NewDate[1]), "yyyy-MM-dd");
        dates.push(date1);
        dates.push(date2);
        console.log(dates)
        const bodydate = {
            dates: dates
        }
        const resonse = await addData("locker/filterusers", bodydate)
        var _data = resonse
        dates = [];
        setProducts(_data.data.lockerResult)
        setFilter(_data.data.lockerResult)

    };



    // const handleChange1 = async (e) => {
    //     let myUser = { ...form }
    //     myUser[e.target.name] = e.target.value
    //     setform(myUser)
    //     const bodydate = {
    //         dates: [e.target.value]
    //     }
    //     const resonse = await addData("locker/filterusers", bodydate)
    //     var _data = resonse
    //     setProducts(_data.data.lockerResult)
    //     setFilter(_data.data.lockerResult)
    // }

    // get all function

    const getAllProducts = async () => {
        const resonse = await addData("locker/getallusers")
        var _data = resonse
        setProducts(_data.data.lockerResult)
        setexcelprodects(_data.data.lockerExcel)
        setFilter(_data.data.lockerResult)
    }

    useEffect(() => {
        getAllProducts()
    }, [])

    const coloums = [
        {
            name: "#",
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Date & Time",
            selector: row => row.date+", "+ row.logCreatedDate.slice(11, 16),
        },
        // {
        //     name: "Date & Time",
        //     selector: row => {
        //         const date = new Date(row.date);
        //         const logCreatedDate = row.logCreatedDate ? new Date(row.logCreatedDate) : null;
        //         return date + ', ' + (logCreatedDate ? logCreatedDate.toLocaleDateString() : 'N/A');
        //     },
        // },
        {
            name: "Name",
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: "Whatsapp No.",
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: "Locker No.",
            selector: row => row.lockerNumber,
            sortable: true,
        },
        {
            name: "Members Count",
            selector: row => row.membersCount,
            sortable: true,
        },

    ]

    useEffect(() => {
        const result = prodects.filter((data) => {
            return (
                (data.customerName && data.customerName.toLowerCase().includes(search.toLowerCase()))
                // ||(data.status && data.status.toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    // Add fuction
    const addcategory = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("name", form.name)
        dataArray.append("description", form.description)
        // dataArray.append("price", form.price)
        // dataArray.append("gst", form.gst)
        // dataArray.append("timeSlot", form.timeSlot)
        dataArray.append("instruction", JSON.stringify(inputList))
        dataArray.append("timming", JSON.stringify(inputList3))
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("image", Files[i])
        }
        try {
            const resonse = await addData("snooker/add", dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setform({ description: "", name: "" })
            setInputList({ label: "" })
            setInputList3({ fromTime: "", toTime: "", amount: "", gst: "" })
            setFiles({ image: "" })

            getAllProducts()
            setshow(false)
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const csvReport = {
        filename: "Visitors",
        data: excelprodects,
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Visitors" />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader className="bg-white">
                                    {/* <CardTitle>Products List</CardTitle> */}
                                </CardHeader>

                                <CardBody>
                                    <div>
                                        {/* <Button
                      onClick={() => {
                        setshow(!show)
                      }}
                      color="primary"
                    >
                      Add Table <i className="bx bx-plus-circle" />
                    </Button> */}
                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={

                                                    <div className="row">
                                                        {/* <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            /> */}


                                                        <div className={form.dates == 'custom' ? 'col-4' : 'col-4'}>
                                                            <select onChange={(e) => { handleChange(e) }} name="dates" className="form-select">
                                                                <option value="">Select</option>
                                                                <option value="today">Today</option>
                                                                <option value="yesterday">Yesterday</option>
                                                                <option value="weekly">Week</option>
                                                                <option value="monthly">Month</option>
                                                                <option value="custom">Custom date</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-4">
                                                            {form.dates == 'custom' ? (
                                                                <Flatpickr
                                                                    placeholder="Select date"
                                                                    className="form-control"
                                                                    name="date"
                                                                    onChange={(e) => {
                                                                        handleDateChange(e);
                                                                    }}
                                                                    options={{
                                                                        mode: "range",
                                                                        dateFormat: "d M, Y",
                                                                    }}
                                                                />
                                                            ) : ''}
                                                            {/* <input
                                                                    //  value={search}
                                                                    onChange={(e) => { handleChange1(e) }}
                                                                    type="date"
                                                                    className="form-control mb-3"
                                                                    placeholder="Search..."
                                                                /> */}
                                                        </div>


                                                        <div style={{ float: "right" }} className="col-4">
                                                            <CSVLink {...csvReport}>
                                                                <Button
                                                                    style={{ float: "right" }}
                                                                    className="text-white bg-success"
                                                                >
                                                                    <i className="bx bx-cloud-download" /> Excel
                                                                </Button>
                                                            </CSVLink>
                                                        </div>


                                                    </div>
                                                }
                                            // subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Toaster />

                <Modal
                    size="sm"
                    isOpen={imgview}
                    toggle={() => {
                        setimgview()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setimgview(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <img src={imgUrl + imgview1.image} />
                        </Row>
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default Visitors
