import React, { useState, useEffect, useRef } from "react"

import {
    Container,
    Row,
    Col,
    Table,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Form,
    FormGroup,
    Label,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Modal,
    Button,
} from "reactstrap"
import Select from "react-select"
import toast, { Toaster } from "react-hot-toast"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"

//Import Images

import food1 from "../../assets/images/brands/food1.jpg"
import popcorn from "../../assets/images/brands/pop_corn.jpg"
import burger from "../../assets/images/brands/burger.jpg"
import mohito from "../../assets/images/brands/mohito.jpg"
import success from "../../assets/images/brands/success.gif"
import preview from "../../assets/images/letast/preview.png"
import { saveAs } from "file-saver"
import { useReactToPrint } from "react-to-print"
import logoLightPng from "../../assets/images/raglogo.png"

import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import moment from 'moment';

const EcommerceCheckout = () => {
    const history = useHistory()
    const componentRef = useRef()
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small2, setmodal_small2] = useState(false)
    const [modal_small3, setmodal_small3] = useState(false)
    const [btnshows, setbtnshows] = useState(false)
    //meta title
    // document.title = "Checkout | Skote - React Admin & Dashboard Template"

    function tog_small2() {
        setmodal_small2(!modal_small2)

    }
    const modalclose = (e) => {
        setmodal_small2(false)
        setMapAcc([])
        swimhandleChange1(e)
        setform({ name: "", phone: "", customvalue: false })
        setform2({couponAmount:''})
        setswimminglist({adultsCount:'1'})
        setforminfo([])

    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const printsecctions = (e) => {
        handlePrint()
        setmodal_small2(!modal_small2)
        setMapAcc([])
        swimhandleChange1(e)
        setform({ name: "", phone: "", customvalue: false })
        setform2({couponAmount:''})
        setswimminglist({adultsCount:'1'})
        setforminfo([])
    }


    const [selectedGroup, setselectedGroup] = useState("")
    const [banners, setbanners] = useState([])

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    const [category, setcategory] = useState([])
    const [subcategory, setsubcategory] = useState([])
    console.log(subcategory)
    const [prices, setprices] = useState([])
    const [customers, setcustomers] = useState([])
    const [form, setform] = useState({ name: "", phone: "", customvalue: false })
    console.log(form)
    const [form1, setform1] = useState([])
    const [swimminglist, setswimminglist] = useState([])
    console.log(swimminglist)
    const [invoice, setinvoice] = useState([])
    const [invoice1, setinvoice1] = useState([])
    console.log(invoice1)
    const [monytypes, setmonytypes] = useState([])
    const [exctra, setexctra] = useState([])
    const [balnceamss, setbalnceamss] = useState([])


    const handleChangeAmount = (e) => {
        let myUser = { ...monytypes }
        myUser[e.target.name] = e.target.value
        setmonytypes(myUser)
    }
    const [form2, setform2] = useState([])
    console.log(form2)

    const handleChange2 = (e) => {
        let myUser = { ...form2 }
        myUser[e.target.name] = e.target.value
        setform2(myUser)
        //    const totalamount = parseFloat(subamount) + parseFloat(sumtax) - parseFloat(e.target.value == "" ? 0 : e.target.value)
        //    settotalamounts(totalamount)
    }


    const handleChangeexctra = (e) => {
        let myUser = { ...exctra }
        myUser[e.target.name] = e.target.value
        setexctra(myUser)
        // const balnceam = prices?.farePrice?.total - e.target.value
        const balnceam = (parseFloat(prices?.adultsAmount) + parseFloat(sumprice) + parseFloat(prices?.farePrice?.tax) - (parseFloat(e.target.value) + (parseFloat(form2.couponAmount) || 0)))
        setbalnceamss(parseFloat(balnceam).toFixed(2))
    }

    // Accessories
    const [accessories, setAccessories] = useState([])
    console.log(accessories)

    const getAllAccessories = async () => {
        const resonse = await addData("swimming/swimmingaccessories/getactive")
        var _data = resonse
        setAccessories(_data.data.swimmingAccessories)
    }

    const optionsacc = accessories.map(data => ({
        value: JSON.stringify(data),
        label: data.name,
    }))

    const [selectedGroupacc, setselectedGroupacc] = useState([])
    const [mapAcc, setMapAcc] = useState([])
    console.log(mapAcc)



    const [countValues, setCountValues] = useState({});
    console.log(countValues)
    useEffect(() => {
        const initialCountValues = {};
        mapAcc.forEach((data, key) => {
            initialCountValues[key] = 1;
        });
        setCountValues((prevValues) => ({ ...prevValues, ...initialCountValues }));
    }, [mapAcc]);

    // useEffect(() => {
    //     const initialCountValues = {};
    //     mapAcc.forEach((data, key) => {
    //         initialCountValues[key] = 1;
    //     });
    //     setCountValues([...prevsValues, initialCountValues]);
    // }, [mapAcc]);

    const quantityChange = async (e, key) => {
        console.log(e.target.value)
        setexctra({
            cashPrice: ''
        })
        let myUser = [...mapAcc];
        myUser[key][e.target.name] = e.target.value
        setMapAcc(myUser);

        const bodydata = {
            quantity: e.target.value
        }
        try {
            const resonse = await addData("swimming/swimmingaccessories/checkaccessoriesquantity/" + myUser[key]._id, bodydata)
            var _data = resonse
            console.log(_data)

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
        getAllCategories();

      

    };

    const countDown = async (data, key, quantity) => {
        setexctra({
            cashPrice: ''
        })
        const bodydata = {
            quantity: parseFloat(quantity) - 1
        }
        try {
            const resonse = await addData("swimming/swimmingaccessories/checkaccessoriesquantity/" + data._id, bodydata)
            var _data = resonse
            console.log(_data)

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
        getAllCategories();

        let myUser = [...mapAcc];
        myUser[key].quantity = Math.max(parseFloat(quantity) - 1, 1)
        console.log(myUser);
        setMapAcc(myUser);
        console.log(mapAcc);

    };

    const countUP = async (data, key, quantity) => {
        console.log(data);
        setexctra({
            cashPrice: ''
        })
        const bodydata = {
            quantity: parseFloat(quantity) + 1
        }
        try {
            const resonse = await addData("swimming/swimmingaccessories/checkaccessoriesquantity/" + data._id, bodydata)
            var _data = resonse
            console.log(_data)

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }

        getAllCategories();
        // setMapAcc();

        let myUser = [...mapAcc];
        myUser[key].quantity = parseFloat(quantity) + 1;
        console.log(myUser);
        setMapAcc(myUser);
        console.log(mapAcc);

    };


    const handleChange = (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }
    const swimhandleChange = (e) => {
        let myUser = { ...swimminglist }
        myUser[e.target.name] = e.target.value
        setswimminglist(myUser)

    }

    const swimmingAccessories = mapAcc.map((item, key) => ({
        accessoryId: item._id,
        accessoryCount: countValues[key] || 1, // Default to 1 if countValues[key] is undefined
    }));

    console.log(swimmingAccessories)
    const [pricedata, setpricedata] = useState([])
    console.log(pricedata)
    const caramout = mapAcc.map((data) => (parseFloat(data.amount) * parseFloat(data.quantity)))
    // console.log(caramout)

    const sumprice = caramout.reduce((acc, value) => {
        if (!isNaN(value)) {
            return acc + value;
        } else {
            return acc; // Skip NaN values
        }
    }, 0);

    console.log(sumprice)


    const handleSelectGroupacc = async (data, key) => {
        // getAllCategories1()
        setexctra({
            cashPrice: ''
        })
        getAllCategories()
        setMapAcc(prevProducts => {

            const existingProductNames = prevProducts.map(value => value.name);

            if (existingProductNames.includes(data.name)) {
                toast.error("Accessorie is already added");
                return prevProducts;
            } else {
                console.log(data);
                var resdata = {
                    _id: data._id,
                    name: data.name,
                    amount: data.amount,
                    image: data.image,
                    quantity: 1
                };
                const updatedProducts = [...prevProducts, resdata];
                setpricedata(updatedProducts)
                return updatedProducts;

                // return [...prevProducts, data];
            }


        });

        useEffect(() => {
            const swimmingAccessories = pricedata.map((item, key) => ({
                accessoryId: item._id,
                accessoryCount: countValues[key] || 1,
            }));


            const bodydata = {
                adultsCount: key + 1,
                swimmingId: swimminglist.swimmingId || subcategory._id,
                swimmingAccessories: swimmingAccessories,
            };

            const fetchData = async () => {
                const response = await addData("swimmingbooking/getswimmingprice", bodydata);
                const _data = response;
                setprices(_data.data);
            };

            fetchData();
        }, [pricedata, key, swimminglist, subcategory]);


        // const swimmingAccessories = updatedProducts.map((item, key) => ({
        //     accessoryId: item._id,
        //     accessoryCount: countValues[key] || 1, // Default to 1 if countValues[key] is undefined
        // }));


        // const bodydata = {
        //     adultsCount: key + 1,
        //     swimmingId: swimminglist.swimmingId || subcategory._id,
        //     swimmingAccessories: swimmingAccessories
        // }
        // const resonse = await addData("swimmingbooking/getswimmingprice", bodydata)
        // var _data = resonse
        // setprices(_data.data)

        // setselectedGroupacc(selectedGroup)
        // console.log(selectedGroup)
        // const selectedValues = selectedGroup.map(option => JSON.parse(option.value));
        // setMapAcc(selectedValues);
    }


    const cartData = async (data) => {
        setsubcategory(data)
        const bodydata = {
            adultsCount: swimminglist.adultsCount || '1',
            swimmingId: data._id || subcategory._id,
            swimmingAccessories: swimmingAccessories
        }
        const resonse = await addData("swimmingbooking/getswimmingprice", bodydata)
        var _data = resonse
        setprices(_data.data)
    }

    const swimhandleChange1 = async (e) => {
        let myUser = { ...swimminglist }
        myUser[e.target.name] = e.target.value
        setswimminglist(myUser)
        const bodydata = {
            adultsCount: e.target.value || 1,
            swimmingId: swimminglist.swimmingId || subcategory._id,
            swimmingAccessories: swimmingAccessories
        }
        const resonse = await addData("swimmingbooking/getswimmingprice", bodydata)
        var _data = resonse
        setprices(_data.data)
        setexctra({
            cashPrice: ''
        })
    }

    const handleChange000 = (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.checked
        setform(myUser)
    }

    // get all function
    const getAllCategories = async () => {
        const resonse = await addData("swimming/getactive")
        var _data = resonse
        setcategory(_data.data.swimmingResult)
        setsubcategory(_data.data.swimmingResult[0])
        setbanners(_data.data.swimmingBanners)
        const bodydata = {
            adultsCount: swimminglist.adultsCount || 1,
            swimmingId: swimminglist?.swimmingId || _data.data.swimmingResult[0]._id,
            swimmingAccessories: swimmingAccessories
        }
        const response = await addData("swimmingbooking/getswimmingprice", bodydata)
        var _data = response
        console.log(_data)
        setprices(_data.data)
        setmodal_small3(false)
    }
    const getAllCategories1 = async () => {
        const resonse = await addData("swimming/getactive")
        var _data = resonse
        setcategory(_data.data.swimmingResult)
        setsubcategory(_data.data.swimmingResult[0])
        setbanners(_data.data.swimmingBanners)
        const bodydata = {
            adultsCount: swimminglist.adultsCount || 1,
            swimmingId: swimminglist?.swimmingId || _data.data.swimmingResult[0]._id,
            swimmingAccessories: swimmingAccessories
        }
        const response = await addData("swimmingbooking/getswimmingprice", bodydata)
        var _data = response
        console.log(_data)
        setprices(_data.data)
        setmodal_small3(false)
    }

    const [subsearch, setsubsearch] = useState([]);

    const handleChangeDate = (e) => {
        let myUser = { ...subsearch }
        myUser[e.target.name] = e.target.value
        setsubsearch(myUser)
    }


    const getAllcustomers = async () => {
        const resonse = await addData("customer/getactivecutomers")
        var _data = resonse
        setcustomers(_data.data.result)
    }



    // const formChange =()=>{
    //     let myUser = { ...subsearch }
    //     myUser[e.target.name] = e.target.value
    //     setsubsearch(myUser)
    // }
    const [forminfo, setforminfo] = useState([])
    const findcustomers = async (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
        const bodydata = {
            phone: e.target.value,
        }
        const resonse = await addData("customer/getactivecutomers", bodydata)
        var _data = resonse
        // setform({ name: _data?.data?.result[0].name, phone: _data?.data?.result[0].phone, customvalue: false })
        setforminfo(_data?.data?.result[0])
    }

    const removeCartItem = (key) => {
        setexctra({
            cashPrice: ''
        })
        const updatedMapAcc = [...mapAcc];
        updatedMapAcc.splice(key, 1);
        setMapAcc(updatedMapAcc);
        setpricedata(updatedMapAcc);
    };

    // get all search function


    // Add fuction
    const addCustomer = async (e) => {
        e.preventDefault()
        const bodydata = {
            name: form.name,
            phone: form.phone,
        }
        try {
            const resonse = await addData("order/addcustomer", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setmodal_small(false)
            // setform({ name: "", phone: "" })
            setform1(_data.data.customer)
            getAllcustomers()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }



    useEffect(() => {
        getAllCategories()
        getAllcustomers()
        getAllAccessories()
    }, [])

    const options = customers.map(data => ({
        value: data._id,
        label: data.name + " / " + data.phone,
        phone: data.phone,
    }))

    const totalamountdata = parseFloat(prices?.adultsAmount) + parseFloat(sumprice) + parseFloat(prices?.farePrice?.tax) - (parseFloat(form2.couponAmount) || 0)
    // Add products

    const addProducts = async (e) => {
        e.preventDefault()
        setbtnshows(true)
        const bodydata = {
            swimmingId: swimminglist.swimmingId || subcategory._id,
            // customerId: selectedGroup.value || form1._id,
            customerId: form?.phone,
            name: form?.name,
            bookingDate: subsearch.bookingDate == undefined ? new Date().toISOString().split('T')[0] : subsearch.bookingDate,
            adultsCount: swimminglist.adultsCount || "1",
            adultsCountOffer: prices?.farePrice?.adultCountOffer,
            subAmount: parseFloat(prices?.adultsAmount).toFixed(2),
            couponAmount: form2.length == 0 ? 0 : parseFloat(form2.couponAmount).toFixed(2) || 0,
            totalPrice: parseFloat(totalamountdata).toFixed(2),
            // totalPrice: prices?.farePrice?.total,
            swimmingAccessories: mapAcc,
            moneyType: monytypes.moneyType || "Cash",
            // cashPrice: exctra.cashPrice,
            // onlinePrice: balnceamss.length == 0? "": balnceamss,

            cashPrice: monytypes.moneyType == "Cash" ? prices?.farePrice?.baseFare : exctra.cashPrice || 0,
            onlinePrice: monytypes.moneyType == "Card" ? prices?.farePrice?.baseFare : balnceamss.length == 0 ? 0 : balnceamss,
            swimmingAccessPrice: sumprice,
        }
        try {
            const resonse = await addData("swimmingbooking/addswimming", bodydata)
            var _data = resonse
            console.log(_data)
            // toast.success(_data.data.message)
            setinvoice(_data?.data?.booking)
            setinvoice1(_data?.data?.booking?.swimmingAccessories)
            setmodal_small(false)
            sessionStorage.setItem("bookingsids", _data.data._id,)
            sessionStorage.setItem("bookingtypes", "Swimming")
            // history.push("/bookinginvoice")
            setmodal_small2(true)
            setbtnshows(false)
            getAllCategories()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
                setbtnshows(false)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // const downloadImage = () => {
    //     history.push("/invoice")
    //     sessionStorage.setItem("orderid", invoicedata._id)
    //     // saveAs(imgUrl+ invoicedata.invoice)
    // }

    const [activeCategory1, setActiveCategory1] = useState(null);

    const handleOnClick = (value) => {
        setmonytypes(value)
        setActiveCategory1(value)
    }



    // const [countvalue, setCountvalue]=useState(1)
    // console.log(countvalue)
    //     const countDown = (key) => {
    //         setCountvalue(countvalue + key)
    //     };

    //     const countUP = (key) => {
    //         setCountvalue(countvalue + key)
    //     };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Swimming Bookings" />

                    <div className="checkout-tabs">
                        <Form
                            onSubmit={e => {
                                addProducts(e)
                            }}
                        >
                            {subcategory != undefined ? (
                                <Row>
                                    <Col md="8">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    {/* <Col md="6">
                                                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                            <ol className="carousel-indicators">
                                                                {banners.map((data, key) => (
                                                                    <li
                                                                        key={key}
                                                                        data-target="#carouselExampleIndicators"
                                                                        data-slide-to={key}
                                                                        className={key === 0 ? "active" : ""} // Only add "active" class to the first indicator
                                                                    />
                                                                ))}
                                                            </ol>
                                                            <div className="carousel-inner">
                                                                {banners.map((data, key) => (
                                                                    <div key={key} className={`carousel-item ${key === 0 ? "active" : ""}`}>
                                                                        <img style={{ height: "420px" }} src={imgUrl + data.bannerImage} className="d-block w-100" alt="..." />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col> */}
                                                    <Col md="12">
                                                        <Row className="mb-3">
                                                            {/* <Col className="mb-3" md="12">
                                                                <h5>Summary</h5>
                                                                <small>Belwail’s swim classes are available for children, teens, and adults.
                                                                    And regardless of where you take your swimming lessons, you can expect caring, patient,
                                                                    and safe instruction from trained, professional instructors who can help even the most timid of
                                                                    swimmers learn to enjoy the water. </small>
                                                            </Col> */}
                                                            {/* <Col md="4">
                                                                <label>Plans :</label>
                                                            </Col> */}
                                                            <Col md="12">
                                                                <label>Slots:</label>
                                                                <Row className="">
                                                                    {category.map((data, key) => (
                                                                        <Col md="4" key={key} >
                                                                            <div onClick={() => { cartData(data) }} className="form-check">
                                                                                <input
                                                                                    type="radio"
                                                                                    defaultChecked={data.shift == "A"}
                                                                                    onChange={(e) => {
                                                                                        swimhandleChange(e, key)
                                                                                    }}
                                                                                    id={`customSwitchsizemd${key}`}
                                                                                    className="form-check-input"
                                                                                    value={data._id}
                                                                                    name="swimmingId"
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`customSwitchsizemd${key}`}
                                                                                >
                                                                                    {data.shift}
                                                                                    <small >({moment(data.fromTime, 'HH:mm').format('hh:mm A')}-{moment(data.toTime, 'HH:mm').format('hh:mm A')})</small>
                                                                                </label>
                                                                                {/* <small>({data.fromTime}-{data.toTime})</small> */}
                                                                            </div>
                                                                        </Col>
                                                                    ))}

                                                                    {/* <Col>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="Bookings" defaultChecked />
                                                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                                                        Slot 1
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="Socialmedia" />
                                                                    <label className="form-check-label" htmlFor="exampleRadios2">
                                                                        Slot 2
                                                                    </label>
                                                                </div>
                                                            </Col> */}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="mb-3" md="3">
                                                                <div>
                                                                    <label>Date:</label>
                                                                    <input required onChange={(e) => { handleChangeDate(e) }}
                                                                        defaultValue={new Date().toISOString().split('T')[0]}
                                                                        min={new Date().toISOString().split('T')[0]}
                                                                        //  value={subsearch.bookingDate}
                                                                        name="bookingDate"
                                                                        className="form-control" type="date" />
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3" md="4">
                                                                <div>
                                                                    <label>Time:</label>
                                                                    <input required value={subcategory.toTime == undefined ? '00:00 , 00:00' : moment(subcategory.fromTime, 'HH:mm').format('hh:mm A') + ' - ' + moment(subcategory.toTime, 'HH:mm').format('hh:mm A')} className="form-control" disabled type="text" />
                                                                    {/* <input className="form-control" type="time" /> */}
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3" md="2">
                                                                <div>
                                                                    <label>Persons: </label>
                                                                    <input min={1} required defaultValue={1} value={swimminglist.adultsCount} onChange={(e) => { swimhandleChange1(e) }} placeholder="Enter Count" name="adultsCount" className="form-control" type="number" />

                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3 mt-4" md="3">
                                                                {/* <Link to="/accessorieslist"> */}
                                                                {/* <Button onClick={() => { setmodal_small3(!modal_small3) }} color="primary" outline >Add Accessories</Button> */}
                                                                {/* </Link> */}
                                                                {/* <div>
                                                            <label>Children <small className="text-danger">(2-12 years)</small></label>
                                                            <input placeholder="Enter Count" className="form-control" type="number" />
                                                        </div> */}
                                                            </Col>
                                                            <Col className="mt-2" md="7">
                                                                <div className="row mt-2">
                                                                    <div className="col-3">
                                                                        <h5>Price:</h5>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <h5 className="text-primary">₹ {prices?.farePrice?.baseFare == undefined ? '0.00' : parseFloat(prices?.farePrice?.baseFare).toFixed(2)}</h5>
                                                                    </div>

                                                                </div>
                                                            </Col>

                                                            {/* <div className="col-12">
                                                                <marquee className="text-info"><b>Offer by Belwail Running now is 5+1 Free.</b></marquee>
                                                            </div> */}


                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <Col md="12">
                                            <Row>
                                                {accessories.map((data, key) => (
                                                    <Col key={key} md="3">
                                                        <Card className="p-2">
                                                            {/* <div className="text-end"> */}

                                                            {/* </div> */}
                                                            <img style={{ width: "100%", height: "80px" }} src={imgUrl + data.image} />
                                                            <span>{data.name.length > 15 ? data.name.substring(0, 15) + "..." : data.name}</span>
                                                            <Row>
                                                                <Col>
                                                                    <span>₹ {data.amount}</span>
                                                                </Col>
                                                                <Col>
                                                                    <a className="border border-info accesscount rounded">{data.accessoryCount}</a>
                                                                </Col>
                                                            </Row>
                                                            <Button onClick={() => { handleSelectGroupacc(data, key) }} type="button" className="mt-2" size="sm" color="primary" outline >Add <i className="bx bx-plus-circle" /></Button>
                                                        </Card>
                                                    </Col>
                                                ))}

                                            </Row>
                                        </Col>
                                    </Col>
                                    <Col md="4" >
                                        <Card>
                                            <CardBody>

                                                <div>
                                                    <CardTitle className="mb-3">Booking Summary</CardTitle>
                                                    <div
                                                    // style={{
                                                    //   maxHeight: "300px",
                                                    // }}
                                                    >
                                                        <Row>
                                                            <Col md="6">
                                                                <div className="form-check form-switch form-switch-md" >
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) => {
                                                                            handleChange000(e)
                                                                        }}
                                                                        id="customSwitchsizemd1"
                                                                        className="form-check-input"
                                                                        value={form?.customvalue}
                                                                        name="customvalue"
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="customSwitchsizemd1"
                                                                    >
                                                                        Guest User
                                                                    </label>
                                                                </div>

                                                            </Col>
                                                            <Col md="6">
                                                                {/* <Button
                                                                    disabled={form.customvalue == true}
                                                                    onClick={() => {
                                                                        setmodal_small(!modal_small)
                                                                    }}
                                                                    color="primary"
                                                                    className="form-control"
                                                                    outline
                                                                >
                                                                    Add Customer <i className="bx bx-plus-circle" />
                                                                </Button> */}
                                                            </Col>

                                                        </Row>
                                                        <div>
                                                            <div className="mb-2 row">
                                                                <div style={{ padding: "1px" }} className="col-6">
                                                                    {/* <label className="sr-only" htmlFor="inlineFormInputGroupUsername">Username</label> */}
                                                                    {/* <div className="input-group"> */}
                                                                    {/* <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Username" /> */}
                                                                    <Input
                                                                        type="text"
                                                                        maxLength="10"
                                                                        pattern="[0-9]{10}"
                                                                        className="form-control"
                                                                        id="inlineFormInputGroupUsername"
                                                                        placeholder="Enter Customer Mobile No"

                                                                        name="phone"
                                                                        value={form?.phone}
                                                                        onChange={e => {
                                                                            findcustomers(e)
                                                                        }}
                                                                    />
                                                                    {/* <div className="input-group-prepend">
                                                                            <div type="button"
                                                                            //  onClick={() => { findcustomers() }} 
                                                                             style={{ padding: "5px" }} className="input-group-text">Search</div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                <div style={{ padding: "1px" }} className="col-6">
                                                                    <Input
                                                                        type="text"
                                                                        pattern="^[a-zA-Z ]*$"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"

                                                                        name="name"
                                                                        value={form?.name}
                                                                        onChange={e => {
                                                                            handleChange(e)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <a className="text-success">
                                                                        {forminfo?.name == undefined ? (
                                                                            ""
                                                                        ) : (
                                                                            <b>Customer Name: {forminfo?.name}</b>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* {form1.length == 0 ? (
                                                            <div className="mt-2 mb-2">
                                                                <Select
                                                                    onChange={handleSelectGroup}
                                                                    name="customerId"
                                                                    options={options}
                                                                    isDisabled={form.customvalue == true}
                                                                    placeholder="Select Customer"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="table-responsive mt-2 mb-2">
                                                                <Table className="table mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Name :</th>
                                                                            <td>{form1.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Mobile :</th>
                                                                            <td>{form1.phone}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        )} */}
                                                    </div>

                                                </div>
                                                <div className="table-responsive">

                                                    <Table className="table mb-0">
                                                        <tbody>
                                                            {mapAcc.map((data, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <img
                                                                            className="rounded-top p-2"
                                                                            src={imgUrl + data.image}
                                                                            style={{
                                                                                width: "50px", height: "50px"
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <span>{data.name.length > 10 ? data.name.substring(0, 10) + "..." : data.name}</span><br />
                                                                        <span>₹ {parseFloat(data.amount * data.quantity).toFixed(2)}</span>
                                                                        <div style={{ width: "100px" }}>
                                                                            <div className="input-group mt-2">
                                                                                <div className="input-group-append">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-primary rounded-left"
                                                                                        onClick={() => countDown(data, key, data.quantity)}

                                                                                    >
                                                                                        -
                                                                                    </button>
                                                                                </div>
                                                                                <Input
                                                                                    style={{ height: "26px" }}
                                                                                    className="text-center"
                                                                                    type="number"
                                                                                    value={data.quantity}
                                                                                    // name="demo_vertical"
                                                                                    // value={data[key].quantity}
                                                                                    // value={ordercount[index]}
                                                                                    name="quantity"
                                                                                    onChange={(e)=>{quantityChange(e, key)}}
                                                                                />
                                                                                <div className="input-group-prepend">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-primary rounded-right"

                                                                                        onClick={() => countUP(data, key, data.quantity)}

                                                                                    >
                                                                                        +
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            to="#"
                                                                            onClick={() =>
                                                                                removeCartItem(key)
                                                                            }
                                                                            className="action-icon text-danger"
                                                                        >
                                                                            {" "}
                                                                            <i className="mdi mdi-trash-can font-size-18" />
                                                                        </a>
                                                                    </td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <Table className="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <td>Subtotal :</td>
                                                                <td className="text-end">₹ {prices?.farePrice?.baseFare == undefined ? '0.00' : parseFloat(prices?.farePrice?.baseFare).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Offer(5 + 1) :</td>
                                                                <td className="text-end">- ₹ {prices?.farePrice?.savedPrice == undefined ? '0.00' : prices?.farePrice?.savedPrice}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>Accessories: </td>
                                                                <td>₹  {prices?.farePrice?.totalSwimmingAcc == undefined ? '0.00' : prices?.farePrice?.totalSwimmingAcc}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td>Accessories: </td>
                                                                <td className="text-end">₹  {parseFloat(sumprice).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Estimated Tax: </td>
                                                                <td className="text-end">₹  {prices?.farePrice?.tax == undefined ? '0.00' : prices?.farePrice?.tax}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Discount : </td>
                                                                <td className="text-end">
                                                                    <Input
                                                                        name="couponAmount"
                                                                        value={form2.couponAmount}
                                                                        onChange={(e) => { handleChange2(e) }}
                                                                        style={{ float: "right" }}
                                                                        size="sm" className="w-50" placeholder="Enter Amount" type="number" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total :</th>
                                                                <th className="text-end">₹ {prices?.adultsAmount == undefined ? '0.00' : parseFloat(totalamountdata).toFixed(2)}</th>
                                                            </tr>
                                                            {/* <tr>
                                                                <th>Total :</th>
                                                                <th>₹ {prices?.farePrice?.total == undefined ? '0.00' : prices?.farePrice?.total}</th>
                                                            </tr> */}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div>
                                                    <Row className="mt-3">
                                                        {/* <Col md="6">
                                                            <Card
                                                                onClick={() => handleOnClick("Cash")}
                                                                // className=""
                                                                className={`test ${activeCategory1 === "Cash" ? 'border border-danger' : ''}`}
                                                            >
                                                                <div className="text-center">
                                                                    <i
                                                                        style={{ fontSize: "20px" }}
                                                                        className="bx bx-money mt-2"
                                                                    />
                                                                    <p className="font-weight-bold">Cash</p>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col md="6">
                                                            <Card
                                                                onClick={() => handleOnClick("Card")}
                                                                className={`test ${activeCategory1 === "Card" ? 'border border-danger' : ''}`}
                                                            >
                                                                <div className="text-center">
                                                                    <i
                                                                        style={{ fontSize: "20px" }}
                                                                        className="bx bx-credit-card mt-2"
                                                                    />
                                                                    <p className="font-weight-bold">Card</p>
                                                                </div>
                                                            </Card>
                                                        </Col> */}
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"
                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Cash"
                                                                    className="form-check-input"
                                                                    value="Cash"
                                                                    name="moneyType"

                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Cash"
                                                                >
                                                                    Cash
                                                                </label>

                                                            </div>
                                                        </Col>
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"

                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Online"
                                                                    className="form-check-input"
                                                                    value="Card"
                                                                    name="moneyType"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Online"
                                                                >
                                                                    Online
                                                                </label>

                                                            </div>
                                                        </Col>
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"

                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Split"
                                                                    className="form-check-input"
                                                                    value="Split"
                                                                    name="moneyType"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Split"
                                                                >
                                                                    Split
                                                                </label>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {monytypes.moneyType == "Split" ? (
                                                        <Row className="mt-3">
                                                            <Col>
                                                                <Input
                                                                    onChange={(e) => { handleChangeexctra(e) }}
                                                                    max={prices?.adultsAmount == undefined ? '0.00' : parseFloat(prices?.adultsAmount) + parseFloat(sumprice) - (parseFloat(form2.couponAmount) || 0)}
                                                                    value={exctra.cashPrice} required name="cashPrice" type="number" placeholder="Enter Cash Amount" />
                                                            </Col>
                                                            <Col>
                                                                <Input value={balnceamss} disabled name="onlinePrice" type="number" placeholder="Enter Online Amount" />
                                                            </Col>
                                                        </Row>
                                                    ) : ""}
                                                    <Button
                                                        type="submit"
                                                        style={{ width: "100%" }}
                                                        color="success"
                                                        className="mt-3"
                                                        disabled={btnshows}
                                                    >
                                                        Print <i className="bx bx-check-circle" />
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (
                                <div className="text-center">
                                    <img style={{ width: "250px" }} src={preview} />
                                    <h5>Service Unavailable</h5>
                                    <span>Sorry, the service is temporarily unavailable.</span>
                                </div>
                            )}
                        </Form>
                    </div>
                </Container>
                <Toaster />
                {/* <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Add Customer
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                addCustomer(e)
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                            name="name"
                                            value={form.name}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Mobile No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            maxLength="10"
                                            pattern="[0-9]{10}"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Mobile No"
                                            required
                                            name="phone"
                                            value={form.phone}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal> */}

                <Modal
                    isOpen={modal_small2}
                    toggle={() => {
                        tog_small2()
                    }}
                >
                    <div className="modal-body">
                        <div className="">
                            <Button
                                onClick={(e)=>{printsecctions(e)}}
                                className="m-2"
                                color="success"
                            >
                                <i className="bx bx-printer"></i> Proceed If thermal
                                printer is ready
                            </Button>
                            <Button
                                onClick={(e) => { modalclose(e) }}
                                className="m-2" color="danger">
                                <i className="bx bx-left-arrow-alt"></i> Cancel
                            </Button>
                            <hr />
                        </div>
                        <Row>
                        <Col md="2"></Col>
                            <Col md="8">
                                <div ref={componentRef}>
                                    <div className="modal-body">
                                        <div id="printableArea">
                                            <div className="initial-38-1">
                                                {/* <div className="pt-3">
                                                    <img src={logoLightPng} className="initial-38-2 mb-2" alt="" />
                                                </div> */}
                                                <div className="text-center">
                                                    <h5 style={{fontSize:"12px"}} className="text-break initial-38-4">
                                                        Name : {invoice.customerName}
                                                    </h5>
                                                    <h5 style={{fontSize:"12px"}} className="initial-38-4 initial-38-3">
                                                        Phone : {invoice.customerPhone}
                                                    </h5>
                                                </div>
                                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h5 style={{ fontSize: "12px", fontWeight: "800" }}>Order ID
                                                            <p style={{marginBottom:"0px"}} className="font-light"> {invoice.bookingNo}</p>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6">
                                                        <h5 style={{ fontSize: "12px", fontWeight: "800" }}>
                                                            <span className="font-light">
                                                                {invoice.bookingDate}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-12">
                                                    </div>
                                                </div>
                                                <h5 style={{ margin: "0px" }} className="text-uppercase" />
                                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                <table style={{ fontSize: "10px", marginBottom: "7px", border: "2px solid black" }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{padding:"5px"}} className="initial-38-6 border-top-0 border-bottom-0">SNo</th>
                                                            <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Description</th>
                                                            <th style={{padding:"5px"}} className="initial-38-7 border-top-0 border-bottom-0">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{padding:"5px"}}>1</td>
                                                            <td style={{padding:"5px"}}>
                                                                <div>

                                                                    <b>{invoice.bookingSlot == "A" ? "Day" : "Night"} {invoice.adultsCount} Persons</b>

                                                                </div>
                                                            </td>
                                                            <td style={{padding:"5px"}}> ₹ {invoice.subAmount}</td>
                                                        </tr>
                                                      <>
                                                      {pricedata.map((data, key) => (
                                                            <tr key={key}>
                                                                <td style={{padding:"5px"}}>
                                                                    {key + 2}
                                                                </td>
                                                                <td style={{padding:"5px"}}>
                                                                    <b><span>{data.quantity} </span>{data.name}</b>
                                                                </td>
                                                                <td style={{padding:"5px"}}>
                                                                    ₹ {parseFloat(data.amount) * parseFloat(data.quantity)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                      </>
                                                    </tbody>
                                                </table>

                                                <div className="px-3">
                                                    <dl style={{ margin: "0px" }} className="row text-right justify-content-center">
                                                        <dt style={{ margin: "0px" }} className="col-6">Subtotal :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">₹ {parseFloat(invoice.subAmount) + parseFloat(invoice.swimmingAccessPrice)}</dd>
                                                        <dt style={{ margin: "0px" }} className="col-6">Discount :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">₹ {invoice.couponAmount == "NaN" ? 0 : invoice.couponAmount}</dd>
                                                        <dt style={{ margin: "0px" }} className="col-6">GST :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">
                                                            ₹{invoice.gst}
                                                            <hr style={{marginBottom:"5px", marginTop:"5px"}} />
                                                        </dd>
                                                        <dt className="col-6 font-20px">Total:</dt>
                                                        <dd className="col-6 font-20px">₹ {invoice.totalPrice}</dd>
                                                        <dt className="col-6 font-20px">Money Type:</dt>
                                                        <dd className="col-6 font-20px"> {invoice.moneyType}</dd>
                                                    </dl>
                                                    <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                    <h5 style={{ fontWeight: "800"}} className="text-center">
                                                        <span className="d-block">"""THANK YOU"""</span>
                                                    </h5>
                                                    <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                    <span className="d-block text-center">Copyright @ Belwails</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="2"></Col>
                        </Row>
                    </div>
                </Modal>

                <Modal
                    size="lg"
                    isOpen={modal_small3}
                // toggle={() => {
                //     tog_small3()
                // }}
                >
                    <div className="modal-body">
                        <div className="">
                            <Row>
                                <div>
                                    <Select
                                        onChange={handleSelectGroupacc}
                                        name="product"
                                        options={optionsacc}
                                        placeholder="Select Accessories"
                                        isMulti
                                    />
                                </div>
                            </Row>
                            <Table responsive bordered className="mt-3">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapAcc.map((data, key) => (
                                        <tr key={key}>
                                            <td>
                                                <img
                                                    className="rounded-top p-2"
                                                    src={imgUrl + data.image}
                                                    style={{
                                                        width: "50px", height: "50px"
                                                    }}
                                                />
                                            </td>
                                            <td>{data.name.length > 20 ? data.name.substring(0, 20) + "..." : data.name}</td>

                                            <td>₹ {data.amount}</td>
                                            <td>
                                                <div style={{ width: "120px" }}>
                                                    <div className="input-group mt-2">
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn-primary rounded-left"
                                                                onClick={() => countDown(key)}

                                                            >
                                                                -
                                                            </button>
                                                        </div>
                                                        <Input
                                                            style={{ height: "26px" }}
                                                            className="text-center"
                                                            type="text"
                                                            value={countValues[key]}
                                                            name="demo_vertical"
                                                            readOnly
                                                        />
                                                        <div className="input-group-prepend">
                                                            <button
                                                                type="button"
                                                                className="btn-primary rounded-right"

                                                                onClick={() => countUP(key)}

                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>₹ {data.amount * countValues[key]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="text-end">
                                <Button
                                    className="m-1"
                                    onClick={() => {
                                        setmodal_small3(false)
                                    }}
                                    color="danger"
                                >
                                    {" "}
                                    <i className="bx bx-x-circle"> </i> Close
                                </Button>
                                <Button
                                    className="m-1"
                                    onClick={() => {
                                        getAllCategories1()
                                    }}
                                    color="success"
                                >
                                    {" "}
                                    <i className="bx bx-plus-circle"> </i> Add
                                </Button>
                            </div>

                        </div>
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default EcommerceCheckout
