import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory, Link } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Supportrequest = () => {
  const history = useHistory()
  const [modal_small, setmodal_small] = useState(false)
  const [customer, setcustomers] = useState([])
  const [form1, setform1] = useState([])
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }


  // get all function

  const getAllCustomers = async () => {
    const resonse = await addData("support/getprocessingtickets")
    var _data = resonse
    setcustomers(_data.data.tikets)
    setFilter(_data.data.tikets)
  }

  useEffect(() => {
    getAllCustomers()
  }, [])

  const redirectuser=(data)=>{
    sessionStorage.setItem('userid', data.customerObjId)
    history.push('/viewuser')
  }

  const coloums = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
      style: {
        borderRight: "1px solid #dee4ef",
      },
    },
    {
      name: "Date",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Username",
      selector: row => <a className="text-primary" onClick={()=>{redirectuser(row)}}>{row.customerName}</a>,
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: row => row.phone,
      sortable: true,
    },
  
    {
      name: "Requests",
      selector: row => row.query.length>30?row.query.substring(0, 30)+'...': row.query,
      sortable: true,
    },
   
    {
      name: "Is Replay",
      selector: row => <button className="btn btn-outline-warning btn-sm rounded-pill">{row.status}</button>,
      sortable: true,
    },
    {
      name: "Action",
      selector: row => (
        <>
 
          <Button
            onClick={() => {
              getpopup(row)
            }}
            className="mr-2"
            style={{ padding: "6px", margin: "3px" }}
            color="success"
            outline
          >
            <i className="fa fa-eye"></i>
          </Button>
       
        </>
      ),
    },
  ]

  // useEffect(() => {
  //   const result = customer.filter(data => {
  //     return data.name && data.name.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilter(result)
  // }, [search])

  useEffect(() => {
    const trimmedSearch = search.trim();
    const result = customer.filter(data => {
      return (
        (data.customerName && data.customerName.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.email && data.email.toLowerCase().includes(trimmedSearch.toLowerCase())) ||
        (data.phone && data.phone.toLowerCase().includes(trimmedSearch.toLowerCase()))
      )
    })
    setFilter(result)
  }, [search])


  // Edit fuction
  const editCustomer = async e => {
    e.preventDefault()
    const bodydata = {
      remarks: form1.remarks,
      status: form1.status,
    }
    try {
      const resonse = await addData("support/updateticketstataus/" + form1._id, bodydata)
      var _data = resonse
      console.log(_data)
      toast.success(_data.data.message)
      setform1([])
      setmodal_small(false)
      getAllCustomers()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message)
      } else {
        toast.error("An error occurred. Please try again.")
      }
    }
  }

  const getpopup = (data) => {
    setform1(data)
    tog_small()
  }


  const [forms, setforms] = useState([])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Belwails" breadcrumbItem="New Request" />

       
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div>
                    <div className="table-responsive" style={{ width: "100%" }}>
                      <DataTable
                        className="bordered-table"
                        columns={coloums}
                        data={filter}
                        pagination
                        paginationDefaultPage={1}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        // selectableRows
                        // selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <div style={{ float: "right" }}>
                            <input
                              value={search}
                              onChange={e => {
                                setSearch(e.target.value)
                              }}
                              type="text"
                              className="form-control mb-3"
                              placeholder="Search..."
                            />
                          </div>
                        }
                        // subHeaderAlign="right"
                        responsive 
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Replay
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editCustomer(e)
              }}
            >
                  <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                 Request
                </Label>
                <textarea
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Remarks"
                  disabled
                  value={form1.query}
                />
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Status <span className="text-danger">*</span>
                </Label>
                <select
                  type="text"
                  className="form-select"
                  id="basicpill-firstname-input1"
                  required
                  name="status"
                  value={form1.status}
                  onChange={e => {
                    handleChange1(e)
                  }}
                >
                  {/* <option value="1">New</option> */}
                  <option value="Processing">Processing</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>

              {form1.status == "Resolved" || form1.status == "Cancelled"?(
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Remarks <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Remarks"
                  required
                  name="remarks"
                  value={form1.remarks}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              ):""}

              <div className="mt-2" style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="success" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Toaster />
      </div>
    </React.Fragment>
  )
}

export default Supportrequest
