import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Taxes = () => {
    const [banner, setbanner] = useState([])
    console.log(banner)
    const [form1, setform1] = useState([])

    const handleChange = (e, key) => {
        const updatedBanner = banner.map((item, index) => {
            if (index === key) {
                return {
                    ...item,
                    gst: e.target.value,
                };
            }
            return item;
        });
        setbanner(updatedBanner);
    };

    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("plan/getall")
        var _data = resonse
        setbanner(_data.data.planResult)
    }

    useEffect(() => {
        getAllbenners()
    }, [])

    // Edit fuction
    const editbenners = async (e) => {
        e.preventDefault()
        const paramsdata = {
            planEdit: banner
        }
        try {
            const resonse = await addData("plan/edit", paramsdata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Belwails" breadcrumbItem="Taxes" />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <Form onSubmit={(e) => { editbenners(e) }} >
                                            <Row>
                                                {banner.map((data, key) => (
                                                    <Col key={key} md="3">
                                                        <div className="mb-3">
                                                            <Label for="basicpill-firstname-input1">
                                                                {data.plan} Tax <span className="text-danger">*</span>
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id="basicpill-firstname-input1"
                                                                placeholder="Enter Tax"
                                                                required
                                                                name={`tax-${key}`}
                                                                value={data.gst}
                                                                onChange={(e) => {
                                                                    handleChange(e, key)
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                ))}
                                                <Col md="3">
                                                    <Button className="mt-4" color="success" type="submit">
                                                        Submit <i className="fas fa-check-circle"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Taxes
