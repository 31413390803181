import React, { useState, useEffect, useRef } from "react"

import {
    Container,
    Row,
    Col,
    Table,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Form,
    FormGroup,
    Label,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Modal,
    Button,
} from "reactstrap"
import Select from "react-select"
import toast, { Toaster } from "react-hot-toast"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"

//Import Images

import food1 from "../../assets/images/brands/food1.jpg"
import popcorn from "../../assets/images/brands/pop_corn.jpg"
import burger from "../../assets/images/brands/burger.jpg"
import mohito from "../../assets/images/brands/mohito.jpg"
import success from "../../assets/images/brands/success.gif"
import preview from "../../assets/images/letast/preview.png"
import { saveAs } from "file-saver"
import { useReactToPrint } from "react-to-print"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import moment from 'moment';
import logoLightPng from "../../assets/images/raglogo.png"

const EcommerceCheckout = () => {
    const history = useHistory()
    const componentRef = useRef()
    const [modal_small, setmodal_small] = useState(false)
    const [modal_small2, setmodal_small2] = useState(false)
    const [btnshows, setbtnshows] = useState(false)
    const [banners, setbanners] = useState([])
    //meta title
    // document.title = "Checkout | Skote - React Admin & Dashboard Template"

    function tog_small2() {
        setmodal_small2(!modal_small2)

    }

    const modalclose = () => {
        setmodal_small2(false)
        // setMapAcc([])
        settimings({ snookerId: '' })
        setform({ name: "", phone: "", customvalue: false })
        setform2({ couponAmount: '' })
        setform12({ adultsCount: '1' })
        setPrices([])
        setforminfo([])
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const printsecctions = () => {
        handlePrint()
        setmodal_small2(!modal_small2)
        // setMapAcc([])
        settimings({ snookerId: '' })
        setform({ name: "", phone: "", customvalue: false })
        setform2({ couponAmount: '' })
        setform12({ adultsCount: '1' })
        setPrices([])
        setforminfo([])
    }

    const [activeTab, setactiveTab] = useState("1")
    console.log(activeTab)
    const [selectedGroup, setselectedGroup] = useState("")

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    const [category, setcategory] = useState([])
    const [category1, setcategory1] = useState([])
    console.log(category1)
    const [subcategory, setsubcategory] = useState('')
    const [customers, setcustomers] = useState([])
    const [form, setform] = useState({ name: "", phone: "", customvalue: false })
    const [form1, setform1] = useState([])
    const [form12, setform12] = useState([])
    const [invoice, setinvoice] = useState([])
    const [monytypes, setmonytypes] = useState([])
    const [exctra, setexctra] = useState([])
    const [balnceamss, setbalnceamss] = useState([])


    const handleChange = (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }
    const handleChange12 = (e) => {
        let myUser = { ...form12 }
        myUser[e.target.name] = e.target.value
        setform12(myUser)
    }
    const handleChange000 = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.checked
        setform(myUser)
        setselectedGroup("")
        setform1([])
    }

    const handleChangeAmount = (e) => {
        let myUser = { ...monytypes }
        myUser[e.target.name] = e.target.value
        setmonytypes(myUser)
    }
    const [form2, setform2] = useState([])

    const handleChange2 = (e) => {
        let myUser = { ...form2 }
        myUser[e.target.name] = e.target.value
        setform2(myUser)
        //    const totalamount = parseFloat(subamount) + parseFloat(sumtax) - parseFloat(e.target.value == "" ? 0 : e.target.value)
        //    settotalamounts(totalamount)
    }

    const handleChangeexctra = (e) => {
        let myUser = { ...exctra }
        myUser[e.target.name] = e.target.value
        setexctra(myUser)
        const balnceam = prices?.farePrice?.total - (parseFloat(e.target.value) + (parseFloat(form2.couponAmount) || 0))
        setbalnceamss(balnceam)
    }

    // get all function
    const getAllCategories = async () => {
        const resonse = await addData("snooker/getactive")
        var _data = resonse
        setcategory(_data.data.snookerResult)
        setbanners(_data.data.snookerBanners)
        setcategory1(_data.data.snookerResult[0])
        setsubcategory(_data?.data?.snookerResult[0]?.timming[0]._id)

    }

    const [subsearch, setsubsearch] = useState([]);
    const [timings, settimings] = useState({ snookerId: '' });
    const [timings1, settimings1] = useState([]);
    const [timings123, settimings123] = useState([]);
    const [prices, setPrices] = useState([]);
    console.log(prices)
    // settimings1(JSON.parse(timings.snookerId))

    const handleChangeDate = (e) => {
        let myUser = { ...subsearch }
        myUser[e.target.name] = e.target.value
        setsubsearch(myUser)
    }
    const handleChangetime = async (e) => {
        let myUser = { ...timings }
        myUser[e.target.name] = e.target.value
        settimings(myUser)

    }
    const [forminfo, setforminfo] = useState([])
    const findcustomers = async (e) => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
        const bodydata = {
            phone: e.target.value,
        }
        const resonse = await addData("customer/getactivecutomers", bodydata)
        var _data = resonse
        // setform({ name: _data?.data?.result[0].name, phone: _data?.data?.result[0].phone, customvalue: false })
        setforminfo(_data?.data?.result[0])
    }


    const handleChangetime123 = async (e) => {
        setbtnshows(true)
        let myUser = { ...timings123 }
        myUser[e.target.name] = e.target.value
        settimings123(myUser)
        const bodydata = {
            snookerId: timings1._id,
            snookerTimingId: e.target.value
        }
        const resonse = await addData("snookerbooking/getsnookerprice", bodydata)
        var _data = resonse
        setPrices(_data.data)
        setbtnshows(false)
    }

    const getAllcustomers = async () => {
        const resonse = await addData("customer/getactivecutomers")
        var _data = resonse
        setcustomers(_data.data.result)

    }

    // Add fuction
    const addCustomer = async e => {
        e.preventDefault()
        const bodydata = {
            name: form.name,
            phone: form.phone,
        }
        try {
            const resonse = await addData("order/addcustomer", bodydata)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setmodal_small(false)
            // setform({ name: "", phone: "" })
            setform1(_data.data.customer)
            getAllcustomers()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    useEffect(() => {
        getAllCategories()
        getAllcustomers()
    }, [])
    useEffect(() => {
        if (timings.snookerId) {
            try {
                const parsedData = JSON.parse(timings.snookerId);
                settimings1(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        } else {
            settimings1([]);
        }
    }, [timings.snookerId]);


    const options = customers.map(data => ({
        value: data._id,
        label: data.name + " / " + data.phone,
        phone: data.phone,
    }))


    // Add products

    const addProducts = async e => {
        e.preventDefault()
        setbtnshows(true)
        const totalstaticprice = parseFloat(prices?.farePrice?.total) - (parseFloat(form2.couponAmount) || 0)
        const bodydata = {
            customerId: form?.phone,
            name: form?.name,
            // customerId: selectedGroup.value || form1._id,
            snookerId: timings1._id || category1._id,
            snookerTimingId: timings123.snookerTimingId || subcategory,
            adultsCount: form12.adultsCount || "1",
            bookingDate: subsearch.bookingDate == undefined ? new Date().toISOString().split('T')[0] : subsearch.bookingDate,
            subAmount: prices?.farePrice?.baseFare,
            couponAmount: form2.length == 0 ? 0 : parseFloat(form2.couponAmount).toFixed(2) || 0,
            totalPrice: parseFloat(totalstaticprice).toFixed(2),
            moneyType: monytypes.moneyType || "Cash",
            cashPrice: monytypes.moneyType == "Cash" ? prices?.farePrice?.baseFare : exctra.cashPrice || 0,
            onlinePrice: monytypes.moneyType == "Card" ? prices?.farePrice?.baseFare : balnceamss.length == 0 ? 0 : balnceamss,

            // cashPrice: exctra.cashPrice,
            // onlinePrice: balnceamss.length == 0? "": balnceamss,

        }
        try {
            const resonse = await addData("snookerbooking/addsnooker", bodydata)
            var _data = resonse
            console.log(_data)
            // toast.success(_data.data.message)
            setinvoice(_data?.data?.booking)
            setmodal_small(false)
            sessionStorage.setItem("bookingsids", _data.data._id)
            sessionStorage.setItem("bookingtypes", "Snookerbooking")
            // history.push("/bookinginvoice")
            setmodal_small2(true)
            setbtnshows(false)
            getAllCategories()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    // const downloadImage = () => {
    //     history.push("/invoice")
    //     sessionStorage.setItem("orderid", invoicedata._id)
    //     // saveAs(imgUrl+ invoicedata.invoice)
    // }

    const [activeCategory1, setActiveCategory1] = useState(null);

    const handleOnClick = (value) => {
        setmonytypes(value)
        setActiveCategory1(value)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Snooker Bookings" />

                    <div className="checkout-tabs">
                        <Form
                            onSubmit={e => {
                                addProducts(e)
                            }}
                        >
                            {subcategory != undefined ? (
                                <Row>
                                    <Col md="8">
                                        <Card>
                                            <CardBody>

                                                <Row>
                                                    <Col md="6">
                                                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                            <ol className="carousel-indicators">
                                                                {banners.map((data, key) => (
                                                                    <li
                                                                        key={key}
                                                                        data-target="#carouselExampleIndicators"
                                                                        data-slide-to={key}
                                                                        className={key === 0 ? "active" : ""} // Only add "active" class to the first indicator
                                                                    />
                                                                ))}
                                                            </ol>
                                                            <div className="carousel-inner">
                                                                {banners.map((data, key) => (
                                                                    <div key={key} className={`carousel-item ${key === 0 ? "active" : ""}`}>
                                                                        <img style={{ height: "420px" }} src={imgUrl + data.bannerImage} className="d-block w-100" alt="..." />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <Row className="mb-3">
                                                            <Col className="mb-3" md="12">
                                                                <h5>Summary</h5>
                                                                <small>
                                                                    Indoor games like billiards, pool, and snooker have a long and rich history,
                                                                    dating back centuries to their origins in Europe. These games require skill, strategy,
                                                                    and patience, and they offer a fun and engaging way to pass the time, whether you're playing alone or with friends.
                                                                </small>
                                                            </Col>

                                                            <Col md="12">
                                                                <label>Select Table :</label>
                                                                <select required onChange={(e) => { handleChangetime(e) }} value={timings.snookerId} name="snookerId" className="form-select">
                                                                    <option value="">Select</option>
                                                                    {category.map((data, key) => (
                                                                        <option key={key} value={JSON.stringify(data)}>{data.name}</option>
                                                                    ))}
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="mb-3" md="6">
                                                                <div>
                                                                    <label>Date:</label>
                                                                    <input required onChange={(e) => { handleChangeDate(e) }}
                                                                        defaultValue={new Date().toISOString().split('T')[0]}
                                                                        min={new Date().toISOString().split('T')[0]}
                                                                        //  value={subsearch.bookingDate}
                                                                        name="bookingDate"
                                                                        className="form-control" type="date" />
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3" md="6">
                                                                <div>
                                                                    <label>Time Slot:</label>
                                                                    <select required onChange={(e) => { handleChangetime123(e) }} name="snookerTimingId" className="form-select">
                                                                        <option value="">Select</option>
                                                                        {timings1?.timming?.map((data, key) => (
                                                                            <option key={key} value={data._id}> {moment(data.fromTime, 'HH:mm').format('hh:mm A')} - {moment(data.toTime, 'HH:mm').format('hh:mm A')}</option>
                                                                        ))}

                                                                    </select>
                                                                    {/* <input value="02:00AM - 06:00PM" className="form-control" disabled type="text" /> */}
                                                                </div>
                                                            </Col>
                                                            <Col className="mb-3" md="6">
                                                                <div>
                                                                    <label>Persons: </label>
                                                                    <input min={1} defaultValue="1" value={form12.adultsCount} name="adultsCount" onChange={(e) => { handleChange12(e) }} required placeholder="Enter Count" className="form-control" type="number" />

                                                                </div>
                                                            </Col>

                                                            <Col className="mt-1" md="12">
                                                                <div className="row mt-2">
                                                                    <div className="col-3">
                                                                        <h5>Price:</h5>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <h5 className="text-primary">₹ {prices?.farePrice?.baseFare == undefined || prices?.farePrice?.baseFare == "" ? '0' : prices?.farePrice?.baseFare}</h5>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            {/* <Col className="mt-4" md="6">
                                                        <div >
                                                            <Button className="form-control" color="primary" >  <i className="bx bx-plus-circle" /> Add</Button>
                                                        </div>
                                                    </Col> */}

                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4" >
                                        <Card>
                                            <CardBody>

                                                <div>
                                                    <CardTitle className="mb-3">Booking Summary</CardTitle>
                                                    <div
                                                    // style={{
                                                    //   maxHeight: "300px",
                                                    // }}
                                                    >
                                                        <Row>
                                                            <Col md="6">
                                                                <div className="form-check form-switch form-switch-md" >
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) => {
                                                                            handleChange000(e)
                                                                        }}
                                                                        id="customSwitchsizemd1"
                                                                        className="form-check-input"
                                                                        value={form.customvalue}
                                                                        name="customvalue"
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="customSwitchsizemd1"
                                                                    >
                                                                        Guest User
                                                                    </label>
                                                                </div>

                                                            </Col>
                                                            <Col md="6">
                                                                {/* <Button
                                                                    onClick={() => {
                                                                        setmodal_small(!modal_small)
                                                                    }}
                                                                    color="primary"
                                                                    className="form-control"
                                                                    outline
                                                                    disabled={form.customvalue == true}
                                                                >
                                                                    Add Customer <i className="bx bx-plus-circle" />
                                                                </Button> */}
                                                            </Col>

                                                        </Row>

                                                        <div>
                                                            <div className="mb-2 row">
                                                                <div style={{ padding: "1px" }} className="col-6">
                                                                    {/* <label className="sr-only" htmlFor="inlineFormInputGroupUsername">Username</label> */}
                                                                    {/* <div className="input-group"> */}
                                                                    {/* <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Username" /> */}
                                                                    <Input
                                                                        type="text"
                                                                        maxLength="10"
                                                                        pattern="[0-9]{10}"
                                                                        className="form-control"
                                                                        id="inlineFormInputGroupUsername"
                                                                        placeholder="Enter Customer Mobile No"

                                                                        name="phone"
                                                                        value={form?.phone}
                                                                        onChange={e => {
                                                                            findcustomers(e)
                                                                        }}
                                                                    />
                                                                    {/* <div className="input-group-prepend">
                                                                            <div type="button"
                                                                            //  onClick={() => { findcustomers() }} 
                                                                             style={{ padding: "5px" }} className="input-group-text">Search</div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                <div style={{ padding: "1px" }} className="col-6">
                                                                    <Input
                                                                        type="text"
                                                                        pattern="^[a-zA-Z ]*$"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                        placeholder="Enter Name"

                                                                        name="name"
                                                                        value={form?.name}
                                                                        onChange={e => {
                                                                            handleChange(e)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <a className="text-success">
                                                                        {forminfo?.name == undefined ? (
                                                                            ""
                                                                        ) : (
                                                                            <b>Customer Name: {forminfo?.name}</b>
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* {form1.length == 0 ? (
                                                            <div className="mt-2 mb-2">
                                                                <Select
                                                                    onChange={handleSelectGroup}
                                                                    name="customerId"
                                                                    options={options}
                                                                    placeholder="Select Customer"
                                                                    isDisabled={form.customvalue == true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="table-responsive mt-2 mb-2">
                                                                <Table className="table mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Name :</th>
                                                                            <td>{form1.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Mobile :</th>
                                                                            <td>{form1.phone}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        )} */}
                                                    </div>

                                                </div>
                                                <div className="table-responsive">
                                                    <Table className="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <td>Subtotal :</td>
                                                                <td className="text-end">₹ {prices?.farePrice?.baseFare == undefined ? '0' : prices?.farePrice?.baseFare}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Estimated Tax: </td>
                                                                <td className="text-end">₹ {prices?.farePrice?.tax == undefined ? '0' : prices?.farePrice?.tax}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Discount : </td>
                                                                <td className="text-end">
                                                                    <Input
                                                                        name="couponAmount"
                                                                        value={form2.couponAmount}
                                                                        onChange={(e) => { handleChange2(e) }}
                                                                        style={{ float: "right", width: "80px" }}
                                                                        size="sm" placeholder="Enter Amount" type="number" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total :</th>
                                                                <th className="text-end">₹ {prices?.farePrice?.total == undefined ? '0' : parseFloat(prices?.farePrice?.total - (parseFloat(form2.couponAmount) || 0)).toFixed(2)}</th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div>
                                                    <Row className="mt-3">
                                                        {/* <Col md="6">
                                                        <Card
                                                            onClick={() => handleOnClick("Cash")}
                                                            className={`test ${activeCategory1 === "Cash" ? 'border border-danger' : ''}`}
                                                        >
                                                            <div className="text-center">
                                                                <i
                                                                    style={{ fontSize: "20px" }}
                                                                    className="bx bx-money mt-2"
                                                                />
                                                                <p className="font-weight-bold">Cash</p>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                    <Col md="6">
                                                        <Card
                                                            onClick={() => handleOnClick("Card")}
                                                            className={`test ${activeCategory1 === "Card" ? 'border border-danger' : ''}`}
                                                        >
                                                            <div className="text-center">
                                                                <i
                                                                    style={{ fontSize: "20px" }}
                                                                    className="bx bx-credit-card mt-2"
                                                                />
                                                                <p className="font-weight-bold">Card</p>
                                                            </div>
                                                        </Card>
                                                    </Col> */}
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"
                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Cash"
                                                                    className="form-check-input"
                                                                    value="Cash"
                                                                    name="moneyType"

                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Cash"
                                                                >
                                                                    Cash
                                                                </label>

                                                            </div>
                                                        </Col>
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"

                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Online"
                                                                    className="form-check-input"
                                                                    value="Card"
                                                                    name="moneyType"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Online"
                                                                >
                                                                    Online
                                                                </label>

                                                            </div>
                                                        </Col>
                                                        <Col md="4" >
                                                            <div
                                                                className="form-check">
                                                                <input
                                                                    type="radio"

                                                                    onChange={(e) => {
                                                                        handleChangeAmount(e)
                                                                    }}
                                                                    id="Split"
                                                                    className="form-check-input"
                                                                    value="Split"
                                                                    name="moneyType"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="Split"
                                                                >
                                                                    Split
                                                                </label>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {monytypes.moneyType == "Split" ? (
                                                        <Row className="mt-3">
                                                            <Col>
                                                                <Input
                                                                    onChange={(e) => { handleChangeexctra(e) }}
                                                                    max={prices?.farePrice?.total == undefined ? '0' : parseFloat(prices?.farePrice?.total) - (parseFloat(form2.couponAmount) || 0)}
                                                                    value={exctra.cashPrice} required name="cashPrice" type="number" placeholder="Enter Cash Amount" />
                                                            </Col>
                                                            <Col>
                                                                <Input value={balnceamss} disabled name="onlinePrice" type="number" placeholder="Enter Online Amount" />
                                                            </Col>
                                                        </Row>
                                                    ) : ""}
                                                    <Button
                                                        type="submit"
                                                        style={{ width: "100%" }}
                                                        color="success"
                                                        className="mt-3"
                                                        disabled={btnshows}
                                                    >{btnshows == true ? <>
                                                        Please Wait...
                                                    </> : <>Print  <i className="bx bx-check-circle" /></>}
                                                    </Button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (

                                <div className="text-center">
                                    <img style={{ width: "250px" }} src={preview} />
                                    <h5>Service Unavailable</h5>
                                    <span>Sorry, the service is temporarily unavailable.</span>
                                </div>

                            )}
                        </Form>
                    </div>

                </Container>
                <Toaster />

                <Modal
                    size="sm"
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Add Customer
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                addCustomer(e)
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Name"
                                            required
                                            name="name"
                                            value={form.name}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Mobile No <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            maxLength="10"
                                            pattern="[0-9]{10}"
                                            className="form-control"
                                            id="basicpill-firstname-input1"
                                            placeholder="Enter Mobile No"
                                            required
                                            name="phone"
                                            value={form.phone}
                                            onChange={e => {
                                                handleChange(e)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={modal_small2}
                    toggle={() => {
                        tog_small2()
                    }}
                >
                    <div className="modal-body">
                        <div className="">
                            <Button
                                onClick={printsecctions}
                                className="m-2"
                                color="success"
                            >
                                <i className="bx bx-printer"></i> Proceed If thermal
                                printer is ready
                            </Button>
                            <Button
                                onClick={() => { modalclose() }}
                                className="m-2" color="danger">
                                <i className="bx bx-left-arrow-alt"></i> Cancel
                            </Button>
                            <hr />
                        </div>
                        <Row>
                            <Col md="2"></Col>
                            <Col md="8">
                                <div ref={componentRef}>
                                    <div className="modal-body">
                                        <div id="printableArea">
                                            <div className="initial-38-1">
                                                {/* <div className="pt-3">
                                                    <img src={logoLightPng} className="initial-38-2 mb-2" alt="" />
                                                </div> */}
                                                <div className="text-center">
                                                    <h5 style={{ fontSize: "12px" }} className="text-break initial-38-4">
                                                        Name : {invoice.customerName}
                                                    </h5>
                                                    <h5 style={{ fontSize: "12px" }} className="initial-38-4 initial-38-3">
                                                        Phone : {invoice.customerPhone}
                                                    </h5>
                                                </div>
                                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h5 style={{ fontSize: "12px", fontWeight: "800" }}>Booking ID
                                                            <span style={{ marginBottom: "0px" }} className="font-light"> {invoice.bookingNo}</span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-6">
                                                        <h5 style={{ fontSize: "12px", fontWeight: "800" }}>
                                                            <span className="font-light">
                                                                {invoice.bookingDate}
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-12">
                                                    </div>
                                                </div>
                                                <h5 style={{ margin: "0px" }} className="text-uppercase" />
                                                <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                <table style={{ fontSize: "10px", marginBottom: "7px", border: "2px solid black" }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ padding: "5px" }} className="initial-38-6 border-top-0 border-bottom-0">SNo</th>
                                                            <th style={{ padding: "5px" }} className="initial-38-7 border-top-0 border-bottom-0">Description</th>
                                                            <th style={{ padding: "5px" }} className="initial-38-7 border-top-0 border-bottom-0">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {products1.map((data, key) => ( */}
                                                        <tr >
                                                            <td style={{ padding: "5px" }}>1</td>
                                                            <td style={{ padding: "5px" }}>
                                                                <div>
                                                                    <h5 style={{ fontSize: "12px" }}><b>{invoice.bookedTable}</b></h5>
                                                                    <b>Persons :</b> <span><b>{invoice.adultsCount}</b></span>
                                                                    {/* <br />
                                                                    <b>Unit Price :</b>
                                                                    <span> <b>₹ {invoice.adultSinglePrice}</b></span> */}
                                                                    {/* <br /> */}
                                                                </div>
                                                            </td>
                                                            <td style={{ padding: "5px" }} className="w-28p"> <b>₹ {invoice.adultSinglePrice}</b></td>
                                                        </tr>
                                                        {/* ))} */}
                                                    </tbody>
                                                </table>

                                                <div className="px-3">
                                                    <dl style={{ margin: "0px" }} className="row text-right justify-content-center">
                                                        <dt style={{ margin: "0px" }} className="col-6">Subtotal :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">₹ {invoice.subAmount}</dd>
                                                        <dt style={{ margin: "0px" }} className="col-6">Discount :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">₹ {invoice.couponAmount == "NaN" ? 0 : invoice.couponAmount}</dd>
                                                        <dt style={{ margin: "0px" }} className="col-6">GST :</dt>
                                                        <dd style={{ margin: "0px" }} className="col-6">
                                                            ₹{invoice.gst}
                                                            <hr style={{ marginBottom: "5px", marginTop: "5px" }} />
                                                        </dd>
                                                        <dt className="col-6 font-20px">Total:</dt>
                                                        <dd className="col-6 font-20px">₹ {invoice.totalPrice}</dd>
                                                        <dt className="col-6 font-20px">Money Type:</dt>
                                                        <dd className="col-6 font-20px">{invoice.moneyType}</dd>
                                                    </dl>
                                                    <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                    <h5 style={{ fontWeight: "800" }} className="text-center">
                                                        <span className="d-block">"""THANK YOU"""</span>
                                                    </h5>
                                                    <span className="initial-38-5">---------------------------------------------------------------------------------</span>
                                                    <span className="d-block text-center">Copyright @ Belwails</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="2"></Col>

                        </Row>
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    )
}

export default EcommerceCheckout
