import React, { useState, useEffect } from "react"
import {
    CardBody,
    CardHeader,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
} from "reactstrap"
// import img1 from "../assets/images/latest/car1.jpg"

//Import Breadcrumb
// import Breadcrumbs from "../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast, { Toaster } from "react-hot-toast"
import ReactPaginate from "react-paginate"
// import { URL } from "../../Apiurls";
import axios from "axios"
import { useHistory } from "react-router-dom"
import img3 from "../../assets/images/crypto/blog/img-3.jpg"
import { addData, updateData, deletedData } from "Servicescalls"
import { imgUrl } from "Baseurls"
import DataTable from "react-data-table-component"

const Banners = () => {
    const [modal_small, setmodal_small] = useState(false)
    const [banner, setbanner] = useState([])
    const [types, settypes] = useState([])
    const [category, setcategory] = useState([])
    const [form, setform] = useState({ assetType: "" })
    const [form1, setform1] = useState([])
    console.log(form1)
    const [form2, setform2] = useState([])
    const [Files1, setFiles1] = useState({ asset: "" })
    const [Files, setFiles] = useState({ asset: "" })

    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])

    const changeHandler = (e) => {
        setFiles(e.target.files)
    }
    const changeHandler1 = (e) => {
        setFiles1(e.target.files)
    }

    function tog_small() {
        setmodal_small(!modal_small)
        removeBodyCss()
    }

    const handleChange = e => {
        let myUser = { ...form }
        myUser[e.target.name] = e.target.value
        setform(myUser)
    }

    const handleChange1 = e => {
        let myUser = { ...form1 }
        myUser[e.target.name] = e.target.value
        setform1(myUser)
    }

    // get all function

    const getAllbenners = async () => {
        const resonse = await addData("asset/getallassets")
        var _data = resonse
        setbanner(_data.data.assetResult)
        setFilter(_data.data.assetResult)
    }

    useEffect(() => {
        getAllbenners()
    }, [])

    const coloums = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "70px",
            style: {
                borderRight: "1px solid #dee4ef",
            },
        },
        {
            name: "Asset Type",
            selector: row => row.assetType,
            sortable: true,
        },
        {
            name: "Image",
            selector: row => (
                <img className="p-2"
                    src={imgUrl + row.asset}
                    style={{ width: "100px", height: "50px" }}
                />
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: row => row.status == true ? "Active" : "Inactive",
            sortable: true,
        },
        {
            name: "Action",
            selector: row => (
                <>
                    <Button
                        onClick={() => {
                            getpopup(row)
                        }}
                        className="mr-2"
                        style={{ padding: "6px", margin: "3px" }}
                        color="success"
                        outline
                    >
                        <i className="bx bx-edit "></i>
                    </Button>
                    <Button
                        onClick={() => {
                            manageDelete(row)
                        }}
                        style={{ padding: "6px", margin: "3px" }}
                        color="danger"
                        outline
                    >
                        <i className="bx bx-trash"></i>
                    </Button>
                </>
            ),
        },
    ]


    useEffect(() => {
        const result = banner.filter((data) => {
            const searchTerm = search.toLowerCase().trim();
            const searchWords = searchTerm.split(" ");
            return searchWords.some((word) =>
                data.assetType.toLowerCase().includes(word)
            );
        });

        setFilter(result);
    }, [search]);


    // useEffect(() => {
    //   const result = banner.filter((data) => {
    //     return (
    //       (data.name && data.name.toLowerCase().includes(search.toLowerCase())) ||
    //       (data.status && data.status.toLowerCase().includes(search.toLowerCase()))
    //     );
    //   });
    //   setFilter(result);
    // }, [search]);

    // Add fuction
    const addbenners = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("assetType", form.assetType)
        for (let i = 0; i < Files.length; i++) {
            dataArray.append("asset", Files[i])
        }
        try {
            const resonse = await addData("asset/addasset", dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setFiles({ asset: "" })
            setform({ assetType: "" })
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Edit fuction
    const editbenners = async e => {
        e.preventDefault()
        const dataArray = new FormData()
        dataArray.append("assetType", form1.assetType)
        dataArray.append("status", form1.status)
        for (let i = 0; i < Files1.length; i++) {
            dataArray.append("asset", Files1[i])
        }
        try {
            const resonse = await updateData("asset/editasset/" + form1._id, dataArray)
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            setFiles1({ asset: "" })
            setmodal_small(false)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }
    // Delete fuction
    const deletebenners = async data => {
        try {
            const resonse = await deletedData(
                "asset/deleteasset/" + data._id,
                {}
            )
            var _data = resonse
            console.log(_data)
            toast.success(_data.data.message)
            getAllbenners()
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message)
            } else {
                toast.error("An error occurred. Please try again.")
            }
        }
    }

    const manageDelete = data => {
        const confirmBox = window.confirm("Do you want to Delete?")
        if (confirmBox === true) {
            deletebenners(data)
        }
    }

    const getpopup = data => {
        setform1(data)
        tog_small()
    }

    const [imgview, setimgview] = useState(false)
    const [imgview1, setimgview1] = useState([])


    function openmodels(data) {
        setimgview(!imgview)
        setimgview1(data)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Belwails" breadcrumbItem="Assets" />

                    {/* {permissioins.banner === true || roles === "admin" ? ( */}

                    <Row>
                        <Col md={4}>
                            <Card>
                                <CardHeader className="bg-white">
                                    <CardTitle>Add Asset</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={e => {
                                            addbenners(e)
                                        }}
                                    >
                                        {/* <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        pattern="^[a-zA-Z\s]*$"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Name"
                        required
                        name="name"
                        value={form.name}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div> */}

                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                Category <span className="text-danger">*</span>
                                            </Label>
                                            <select
                                                required
                                                name="assetType"
                                                value={form.assetType}
                                                onChange={e => {
                                                    handleChange(e)
                                                }}
                                                className="form-select"
                                            >
                                                <option value="">Select</option>
                                                <option value="Sticker">Sticker</option>
                                                <option value="CollageMaker">Collage Maker</option>
                                                <option value="Frame">Frame</option>
                                                <option value="TextBox">Text Box</option>

                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <Label for="basicpill-firstname-input1">
                                                File <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Image"
                                                required
                                                name="asset"
                                                value={Files.asset}
                                                onChange={changeHandler}
                                            />
                                        </div>


                                        <div className="mt-4" style={{ float: "right" }}>
                                            <Button color="success" type="submit">
                                                Submit <i className="fas fa-check-circle"></i>
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={8}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="table-responsive">
                                            <DataTable
                                                className="bordered-table"
                                                columns={coloums}
                                                data={filter}
                                                pagination
                                                paginationDefaultPage={1}
                                                paginationPerPage={5}
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                // selectableRows
                                                // selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                    <div className="row">
                                                        <div className="col-md-6">

                                                            <CardTitle>Assets List</CardTitle>
                                                        </div>
                                                        <div className="col-md-6">

                                                            <div style={{ float: "right" }}>
                                                                <input
                                                                    value={search}
                                                                    onChange={e => {
                                                                        setSearch(e.target.value)
                                                                    }}
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    placeholder="Search..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                                subHeaderAlign="right"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>

                <Modal
                    isOpen={modal_small}
                    toggle={() => {
                        tog_small()
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="mySmallModalLabel">
                            Edit Asset
                        </h5>
                        <button
                            onClick={() => {
                                setmodal_small(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            onSubmit={e => {
                                editbenners(e)
                            }}
                        >
                            <Row>


                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input1">
                                            Category <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                            required
                                            name="assetType"
                                            value={form1.assetType}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                            className="form-select"
                                        >
                                            <option value="">Select</option>
                                            <option value="Sticker">Sticker</option>
                                            <option value="CollageMaker">Collage Maker</option>
                                            <option value="Frame">Frame</option>
                                            <option value="TextBox">Text Box</option>

                                        </select>
                                    </div>
                                </Col>

                                <Col md="12">
                                    <div className="mb-3">
                                        <Row>
                                            <Col> <Label for="basicpill-firstname-input1">Image</Label> </Col>
                                            <Col className="text-end"> <a onClick={() => { openmodels(form1) }} ><i style={{ marginLeft: "10px" }} className="fa fa-eye text-danger" /></a></Col>
                                        </Row>

                                        <Input
                                            type="file"
                                            className="form-control"
                                            placeholder="Enter image"
                                            name="asset"
                                            // value={form1.bannerImage}
                                            onChange={changeHandler1}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="mb-3">
                                        <Label for="basicpill-firstname-input3">
                                            Status <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                            name="status"
                                            value={form1.status}
                                            onChange={e => {
                                                handleChange1(e)
                                            }}
                                            className="form-select"
                                        >
                                            <option value="true">Active</option>
                                            <option value="false">In Active</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    onClick={() => {
                                        setmodal_small(false)
                                    }}
                                    color="danger"
                                    type="button"
                                >
                                    Cancel <i className="fas fa-times-circle"></i>
                                </Button>
                                <Button className="m-1" color="success" type="submit">
                                    Submit <i className="fas fa-check-circle"></i>
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    size="sm"
                    isOpen={imgview}
                    toggle={() => {
                        setimgview()
                    }}
                >
                    <div style={{ borderBottom: "0px" }} className="modal-header">
                        <button
                            onClick={() => {
                                setimgview(false)
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <img src={imgUrl + imgview1.asset} />
                        </Row>
                    </div>
                </Modal>

                <Toaster />
            </div>
        </React.Fragment>
    )
}

export default Banners
